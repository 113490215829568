import type {Feature} from '../hooks/useFeatures'
import {fetchRequestPublic} from './fetch'

export type ClubInfo = {
  avatar?: string
  name: string
  enabled: boolean
  email: string
  id: number
  offerModel: number
  domainName: string
  clubStyle: string
  features: {featureKey: Feature}[]
  filename_sponsor: string
}
const getClubInfo = async (clubId: number): Promise<ClubInfo> => {
  const res = await fetchRequestPublic('/club/info', 'POST', JSON.stringify({club_id: clubId}))
  return res
}
export default {
  getClubInfo,
}
