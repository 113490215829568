import React, {SVGProps} from 'react'

// eslint-disable-next-line react/function-component-definition
export default (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="64"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    height="64"
    viewBox="0 0 64 64"
    className="nav-icon text-primary position-absolute"
    enableBackground="new 0 0 64 64"
    {...props}>
    <g>
      <path
        fill="currentColor"
        d="M3.352,48.296l28.56-28.328l28.58,28.347c0.397,0.394,0.917,0.59,1.436,0.59c0.52,0,1.04-0.196,1.436-0.59   c0.793-0.787,0.793-2.062,0-2.849l-29.98-29.735c-0.2-0.2-0.494-0.375-0.757-0.475c-0.75-0.282-1.597-0.107-2.166,0.456   L0.479,45.447c-0.793,0.787-0.793,2.062,0,2.849C1.273,49.082,2.558,49.082,3.352,48.296z"
      />
    </g>
  </svg>
)
