import {Divider} from '@material-ui/core'
import React, {MouseEvent, useCallback, useEffect, useMemo, useState} from 'react'
import ReactModal from 'react-modal'
import Select from 'react-select'
import {useConfirm} from 'material-ui-confirm'
import CreatableSelect from 'react-select/creatable'
import {companyLoad} from '../../../actions/companyAction'
import {useAppDispatch, useAppSelector} from '../../../reducers/hooks'
import {userActions} from '../../../reducers/userReducer'
import api, {authedApi} from '../../../services/api'
import {API_USER_MODIFY, selectStyle} from '../../../services/constants'
import {logger} from '../../../services/logger'
import {TITLES} from '../../../shared/data/titles'
import {ZIndexLayers} from '../../../theme'
import {SignupInput} from '../../form'
import {ImageEdit} from '../../images'
import ProfileModifySkeleton from './profile-modify-skeleton'
import CancelSvg from '../../../assets/svg/CancelSvg'
import {isEqualShallow} from './isEqualShallow'
import {useApi} from '../../../shared/api/useApi'
import {useFeatures} from '../../../shared/hooks/useFeatures'

const qs = require('querystring')

const modalStyles: ReactModal.Styles = {
  ...ReactModal.defaultStyles,
  content: {
    background: 'transparent',
    position: 'initial',
    paddingTop: '40px',
    paddingBottom: '40px',
    maxHeight: '100%',
  },
  overlay: {
    ...ReactModal.defaultStyles.overlay,
    zIndex: ZIndexLayers.Modal,
  },
}

type ProfileModifyProps = {
  onDismiss?: () => void
  isVisible: boolean
}
const ProfileModify = ({onDismiss, isVisible}: ProfileModifyProps) => {
  const dispatch = useAppDispatch()

  const {hasFeatures} = useFeatures()

  const userSliceData = useAppSelector((state) => state.userR)
  const companySliceData = useAppSelector((state) => state.companyR)
  const config = useAppSelector((state) => state.apiR)
  const {club_color} = useAppSelector((state) => state.clubState)

  const confirm = useConfirm()

  const baseCompanyData = useMemo(
    () => ({
      ...companySliceData,
      logo_image: companySliceData && companySliceData.company_avatar,
      company_name: companySliceData && companySliceData.name,
      company_sector: companySliceData && companySliceData.sector,
      company_linkedin_url: companySliceData && companySliceData.linkedinUrl,
      company_website_url: companySliceData && companySliceData.websiteUrl,
      company_subtitle: companySliceData && companySliceData.subtitle,
    }),
    [companySliceData]
  )
  const baseUserData = useMemo(
    () => ({
      ...userSliceData,
      // @ts-ignore
      avatar: undefined as string,
      avatar_image: userSliceData && userSliceData.avatar,
      first_name: userSliceData && userSliceData.firstName,
      last_name: userSliceData && userSliceData.lastName,
    }),
    [userSliceData]
  )

  const [company, setCompany] = useState(baseCompanyData)
  const [user, setUser] = useState(baseUserData)

  const hasChanges = !isEqualShallow(baseCompanyData, company) || !isEqualShallow(baseUserData, user)

  useEffect(() => {
    if (!isVisible && user && company) {
      setCompany({...baseCompanyData})
      setUser({...baseUserData})
    }
  }, [isVisible, baseUserData, baseCompanyData])

  const [activities, setActivities] = useState<{label: string; value: number}[]>([])
  const positions = TITLES.map((t, index) => ({label: t, value: index}))
  const containsPosition = positions.findIndex(({label}) => label === user.position) >= 0
  if (!containsPosition) positions.push({label: user.position, value: positions.length})

  const companyProfileImage = (file: string, extension: string) => {
    setCompany((prev) => ({...prev, logo: file.split(',')[1], logo_image: file, logo_ext: extension}))
  }

  const userProfileImage = (file: string, extension: string) => {
    setUser((prev) => ({...prev, avatar: file.split(',')[1], avatar_image: file, avatar_ext: extension}))
  }

  const retrieveDataCompany = (data: {libelle: string; id: number} | string, name: string) => {
    setCompany((prev) => ({
      ...prev,
      [name]: data,
    }))
  }

  const retrieveDataUser = (data: string | number, name: string) => {
    const phone_input = document.querySelector('#phone_number')?.parentElement
    if (name === 'phone_number' && phone_input) {
      // @ts-ignore
      if (Number.isNaN(data)) {
        phone_input.classList.add('Mui-error')
        return
      }
      phone_input.classList.remove('Mui-error')
    }
    setUser((prev) => ({
      ...prev,
      [name]: data,
    }))
  }

  const dataWellFilled = (required_list: any, list: any) => {
    let error = false
    for (let i = 0; i < required_list.length; i++) {
      if (list[required_list[i]] === '' || list[required_list[i]] === undefined) {
        const requiredItemParent = document.querySelector(`#${required_list[i]}`)?.parentElement
        if (requiredItemParent) {
          requiredItemParent.classList.add('Mui-error')
        }
        error = true
      }
    }
    return error
  }

  const errorHandle = useCallback(() => {
    const required_user = ['position', 'email', 'phone_number', 'first_name', 'last_name']
    const required_company = ['company_sector', 'company_name']
    const error = [false, false]
    error[0] = dataWellFilled(required_user, user)
    error[1] = dataWellFilled(required_company, company)
    if (error[0] || error[1]) {
      return true
    }
    return false
  }, [user, company])

  const onSubmit = (e: MouseEvent) => {
    e.preventDefault()
    if (errorHandle()) {
      return
    }
    api
      .put(API_USER_MODIFY, qs.stringify({...user, ...company, company_sector: company.company_sector.id}), config)
      .then((res) => {
        dispatch(userActions.userLoad(res.data.user))
        dispatch(companyLoad(res.data.company))

        if (onDismiss) onDismiss()
      })
      .catch((err) => {
        logger.debug('Saving data of user and company ERROR: ', err)
      })
  }

  const onCancel = useCallback(
    (e: React.MouseEvent | React.KeyboardEvent) => {
      e.preventDefault()

      if (!hasChanges) {
        if (onDismiss) onDismiss()
      } else {
        confirm({
          title: 'Vous avez des changements en cours.',
          description: 'Fermer quand même ?',
          cancellationText: 'Non',
          confirmationText: 'Oui',
          dialogProps: {
            style: {zIndex: 2300},
          },
        })
          // oui
          .then(() => {
            if (onDismiss) onDismiss()
          })
      }
    },
    [hasChanges, confirm]
  )

  const getAllSectorsApi = useApi(
    async () => authedApi.get('/api/sector/'),
    (res) => {
      setActivities(
        res.data.map((el: {libelle: string; id: number}) => ({
          label: el.libelle,
          value: el.id,
        }))
      )
    },
    (e) => {
      console.error('Get all sectors of club ERROR: ', e)
    }
  )

  useEffect(() => {
    getAllSectorsApi.call()
  }, [])

  if (!company || !user) {
    if (isVisible) {
      // ! Code U-001
      logger.debug('DEBUG -- Une erreur est survenue... Code U-003')
    }

    return <ProfileModifySkeleton />
  }

  const disabledPartnerEditing = !hasFeatures('partner_modify_for_members')

  return (
    <ReactModal
      isOpen={isVisible}
      style={modalStyles}
      shouldCloseOnOverlayClick
      shouldCloseOnEsc
      onRequestClose={(e) => onCancel(e)}
      // should match react-modal.scss fade timing
      closeTimeoutMS={250}>
      <div className="modal-dialog modal-lg m-auto modal_new_feed modal-dialog-centered" role="document">
        <div className="modal-content" style={{borderRadius: `${0.8}rem`, backgroundColor: 'transparent'}}>
          <div className="img-timeline bg-light">
            <a className="float-right pt-md-2 pr-md-1" title="Fermer" onClick={(e) => onCancel(e)}>
              <CancelSvg height={32} width={32} className="mr-md-1" />
            </a>
          </div>
          <div className="modal-body px-3 pb-3 bg-white">
            <form>
              <div className="profile_company position-relative mb-2">
                <div className="row">
                  <div className="col-md-3 mt-n4 flex-column">
                    <ImageEdit
                      classes="avatar-edit"
                      containerClass="justify-content-center flex-column flex mb-1"
                      containerStyle={{display: 'flex'}}
                      style={{alignSelf: 'center'}}
                      avatar={{alt: company.company_name[0], src: company.logo_image}}
                      disabled={disabledPartnerEditing}
                      setDB={(file: string, extension: string) => companyProfileImage(file, extension)}
                    />
                    <h6 className="text-center">{company.company_name || ''}</h6>
                  </div>
                  <div className="col-md-9 px-4">
                    <p className="text-left mb-1">
                      <small className="text-muted">Secteur d'activité *</small>
                    </p>
                    {company.company_sector ? (
                      <Select
                        styles={selectStyle(club_color)}
                        options={activities}
                        onChange={(value) => value && retrieveDataCompany({libelle: value.label, id: value.value}, 'company_sector')}
                        defaultValue={activities.filter((el) => el.value === company.company_sector.id || el.label === company.company_sector.libelle)}
                        value={activities.filter((el) => el.value === company.company_sector.id || el.label === company.company_sector.libelle)}
                        placeholder="Secteur d'activité..."
                        isDisabled={disabledPartnerEditing}
                      />
                    ) : (
                      // ! Code C-001
                      logger.debug('DEBUG -- Une erreur est survenue... Code C-002')
                    )}
                    <SignupInput
                      label="LinkedIn"
                      id="company_linkedin_url"
                      value={company.company_linkedin_url || ''}
                      retrieve={(data, name) => retrieveDataCompany(data, name)}
                      disabled={disabledPartnerEditing}
                    />
                    <SignupInput
                      label="Site Internet"
                      id="company_website_url"
                      value={company.company_website_url || ''}
                      retrieve={(data, name) => retrieveDataCompany(data, name)}
                      disabled={disabledPartnerEditing}
                    />
                    <SignupInput
                      label="Description"
                      id="company_subtitle"
                      value={company.company_subtitle || ''}
                      params={{multiline: true}}
                      retrieve={(data, name) => retrieveDataCompany(data, name)}
                      disabled={disabledPartnerEditing}
                    />
                  </div>
                </div>
              </div>
              <Divider variant="middle" component="div" />
              <div className="profile_user position-relative pt-3">
                <div className="row">
                  <div className="col-sm-3">
                    <ImageEdit
                      classes="avatar-edit"
                      containerClass="justify-content-center flex-column flex"
                      containerStyle={{display: 'flex'}}
                      style={{alignSelf: 'center'}}
                      avatar={{alt: user.first_name && user.last_name ? user.first_name[0] + user.last_name[0] : '', src: user.avatar_image}}
                      setDB={(file: string, extension: string) => userProfileImage(file, extension)}
                    />
                    <SignupInput
                      label="Prénom"
                      id="first_name"
                      value={user.first_name || ''}
                      params={{required: true}}
                      retrieve={(data, name) => retrieveDataUser(data, name)}
                    />
                    <SignupInput
                      label="Nom"
                      id="last_name"
                      value={user.last_name || ''}
                      params={{required: true}}
                      retrieve={(data, name) => retrieveDataUser(data, name)}
                    />
                  </div>
                  <div className="col-sm-9 px-4">
                    <p className="text-left mb-1">
                      <small className="text-muted">Poste *</small>
                    </p>
                    {positions ? (
                      <CreatableSelect
                        isClearable
                        styles={selectStyle(club_color)}
                        options={positions}
                        onChange={(value) => value && retrieveDataUser(value.label, 'position')}
                        defaultValue={positions.filter((el) => el.label === user.position)}
                        value={positions.filter((el) => el.label === user.position)}
                        placeholder="Poste..."
                        formatCreateLabel={(inputValue) => `Créer "${inputValue}"`}
                      />
                    ) : (
                      // ! Code POS-001
                      logger.debug('DEBUG -- Une erreur est survenue... Code PR-001')
                    )}
                    <SignupInput label="LinkedIn" id="linkedin_url" value={user.linkedin_url || ''} retrieve={(data, name) => retrieveDataUser(data, name)} />
                    <SignupInput
                      label="Email"
                      id="email"
                      value={user.email || ''}
                      params={{required: true}}
                      retrieve={(data, name) => retrieveDataUser(data, name)}
                      modifiable={false}
                    />
                    <SignupInput
                      label="Téléphone"
                      id="phone_number"
                      value={user.phone_number || ''}
                      params={{required: true}}
                      retrieve={(data, name) => retrieveDataUser(data, name)}
                    />
                    <SignupInput
                      label="Informations complémentaires"
                      id="description"
                      value={user.description || ''}
                      params={{multiline: true}}
                      retrieve={(data, name) => retrieveDataUser(data, name)}
                    />
                  </div>
                </div>
              </div>
              <div className="float-right pr-3 mt-2">
                <button type="submit" className="btn btn-outline-warning" onClick={(e) => onCancel(e)}>
                  Annuler
                </button>
                <button type="submit" className="btn btn-outline-primary ml-md-2" onClick={(e) => onSubmit(e)}>
                  Enregistrer
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </ReactModal>
  )
}

export default ProfileModify
