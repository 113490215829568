import {LoginUser} from './loginApi'
import {logger} from '../../services/logger'
import {UserNotAuthenticatedError} from './errors'
import {authedApi} from '../../services/api'

const getUserInfo = async (): Promise<LoginUser & {deviceId: string}> => {
  try {
    const res = await authedApi.get('api/user')
    return res.data
  } catch (e) {
    if (e.response) {
      throw new UserNotAuthenticatedError('User is not authenticated')
    } else if (e.request) {
      logger.warn('could not connect to server.', e)
    } else {
      logger.warn('unknown error', e)
    }
    throw e
  }
}
export default {
  getUserInfo,
}
