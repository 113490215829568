import React, { Component } from 'react';
import GroupData from './group-data';
import GroupMembers from './group-members';
import GroupDataSkeleton from './group-data-skeleton';
import UserDataSkeleton from '../../../component/user/user-details/user-data-skeleton';

class GroupDetails extends Component {
    shouldComponentUpdate() {
        return this.props.onReload;
    }

    render() {
        if (!this.props.data || !this.props.data.users) {
            // ! Code GE-002
            return (
                <>
                    <GroupDataSkeleton />
                    <UserDataSkeleton />
                </>
            );
        }

        return (
            <>
                <GroupData data={this.props.data} />
                <GroupMembers data={this.props.data.users} rank={this.props.data.rank} />
            </>
        );
    }
}

export default GroupDetails;
