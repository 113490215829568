import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'
import TextField from '@material-ui/core/TextField'
import React, {MouseEvent, useEffect, useState} from 'react'
import {clubSliceActions} from '../../../reducers/clubSlice'
import {useAppDispatch} from '../../../reducers/hooks'

type SignupInputProps = {
  id: string
  params?: Record<string, unknown>
  label: string
  type?: string
  value: string
  modifiable?: boolean
  retrieve: (data: string, name: string) => void

  disabled?: boolean
}
const SignupInput = ({id, label, disabled,modifiable, params, retrieve, type, value: valueFromProps}: SignupInputProps) => {
  const dispatch = useAppDispatch()

  const [editable, setEditable] = useState<boolean>(!!modifiable)
  const [variant, setVariant] = useState<'outlined' | 'standard'>(modifiable ? 'outlined' : 'standard')
  const [value, setValue] = useState<string>(valueFromProps)
  // const [tdisabled, setDisabled] = useState<boolean>(false)

  const handleEdit = () => {
    const newVariant = editable ? 'standard' : 'outlined'
    dispatch(clubSliceActions.checkChange(editable ? -1 : 1))
    setEditable(!editable)
    setVariant(newVariant)
  }

  const manageChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    e.preventDefault()
    setValue(e.target.value)
    retrieve(e.target.value, id)
  }

  const focusOnInput = (e: MouseEvent<HTMLDivElement>) => {
    e.preventDefault()
    if (editable) {
      return
    }
    handleEdit()
  }

  useEffect(() => {
    setValue(value)
  }, [valueFromProps])

  const divProps = {value: id}
  return (
    <div {...divProps} onClick={(e) => focusOnInput(e)}>
      <TextField
        {...params}
        id={id}
        disabled={disabled}
        label={label}
        onChange={(e) => manageChange(e)}
        margin="normal"
        variant={variant}
        fullWidth
        type={type}
        value={value}
        autoFocus={editable}
        InputProps={{
          readOnly: variant !== 'outlined',
          endAdornment: (
            <InputAdornment position="end">
              {!editable && (
                <IconButton onClick={(e) => handleEdit()}>
                  <i className="icon-edit bg-primary" />
                </IconButton>
              )}
            </InputAdornment>
          ),
        }}
      />
    </div>
  )
}
export default SignupInput
