import React, { Component } from 'react';
import Like from '../like/like';
import {connect} from 'react-redux';
import api from '../../../services/api';
import UserIdentifier from '../../../component/user/avatar/identifier';
import NotificationButton from '../../../component/notifications/snackbar/button-notification';
import MentionHandler from '../mention/mentions';
import ActionMenu from '../../../component/form/button/post_action_menu';
import { API_COMMENT_DELETE, API_COMMENT_LIKE, API_COMMENT_MODIFY, USER_IMAGE_UPLOAD_LINK } from '../../../services/constants';
import { alreadyLikeFind, imageAppendUrl } from '../../../services/utils';
import {logger} from "../../../services/logger";

class Comment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            likes: props.comment.likes.length,
            alreadyLiked: false,
            deleteConfirmation: false,
            requestLikeSended: false,
            modifComment: false,
        };
        this.liked = this.liked.bind(this);
        this.deleteComment = this.deleteComment.bind(this);
        this.onModify = this.onModify.bind(this);
    }

    deleteComment() {
        var comment_id = this.props.comment.id;
        var post_id = this.props.post_id;
        api.delete(API_COMMENT_DELETE(post_id, comment_id), this.props.config)
        .then(res => {
            this.props.onDelete(comment_id);
        })
        .catch(err => {
            logger.debug("Comment delete ERROR: ", err);
        })
    }

    liked = () => {
        if (this.state.requestLikeSended) {
            return;
        }
        this.setState({requestLikeSended: true,});
        api.post(API_COMMENT_LIKE(this.props.post_id, this.props.comment.id), {}, this.props.config)
        .then(res => {
            this.setState({requestLikeSended: false, alreadyLiked: !this.state.alreadyLiked, });
            this.props.hasLike(this.props.comment.id, res.data);
        })
        .catch(err=> {
            this.setState({requestLikeSended: false,});
            logger.debug('Like a comment ERROR: ', err);
        })
    }

    CommentMenu = (data) => {
        if (data === "erase") {
            this.setState({deleteConfirmation: true, });
        } else if (data === "modify") {
            this.setState({modifComment: true, comment_content: this.props.comment.comment, });
        }
    }

    handleChange = (e) => {
        this.setState({comment_content: e.target.value, });
    }

    onModify = (e) => {
        e.preventDefault();
        api.put(API_COMMENT_MODIFY(this.props.post_id, this.props.comment.id), {content: this.state.comment_content}, this.props.config)
        .then(res => {
            if (res.data && res.data.user) {
                res.data.user.avatar = imageAppendUrl(USER_IMAGE_UPLOAD_LINK, res.data.user.avatar);
            }
            this.props.updateComments(res.data);
            this.setState({modifComment: false, });
        })
        .catch(err => {
            logger.debug("Modify comment ERROR: ", err);
        })
    }

    componentDidMount() {
        this.setState({alreadyLiked: alreadyLikeFind(this.props.comment.likes, this.props.userR.id)})
    }

    componentWillReceiveProps(newProps) {
        this.setState({likes: newProps.comment.likes.length})
    }

    render() {
        return (
            <div className="w-100">
                <NotificationButton data={{
                        notification_message: "Voulez-vous vraiment supprimer ce commentaire ?",
                        button_title: "Supprimer"
                    }}
                    open={this.state.deleteConfirmation}
                    returnAction={this.deleteComment}
                    handleClose={()=>this.setState({deleteConfirmation: false, })}
                />
                <div className="card pb-3 mt-n3 comment-card bg-comment">
                    <div className="card-header pt-3 w-100 bg-transparent">
                        <div className="container">
                            <div className="row">
                                <UserIdentifier user={this.props.comment && this.props.comment.user} />
                                <div className="col-3 d-flex align-items-center justify-content-end">
                                    <ActionMenu post={{id: this.props.post_id}} comment={{id: this.props.comment && this.props.comment.id}} returnAction={(data) => this.CommentMenu(data)} user_id={this.props.comment && this.props.comment.user && this.props.comment.user.id} comment_report={true} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card-body w-100 mt-n3 bg-transparent">
                        {
                            this.state.modifComment &&
                            <div className="d-flex flex-row align-items-center">
                                <textarea style={{height: 'auto'}} id="new-comment-input" placeholder="Écrire un message..." value={this.state.comment_content} type="text" className="form-control" onChange={this.handleChange}></textarea>
                                <button type="button" className="btn btn-primary ml-1" onClick={this.onModify}>Modifier</button>
                            </div>
                        }
                        {
                            !this.state.modifComment &&
                            <p className="card-text my-1 px-2" style={{whiteSpace: 'pre-line'}}>
                                <MentionHandler>
                                    {this.props.comment ? this.props.comment.comment || '' : ""}
                                </MentionHandler>
                            </p>
                        }
                    </div>
                    <div className="card-footer mt-n2 bg-transparent">
                        <div className="ml-2 d-flex align-items-center justify-content-start">
                            <span className="text-primary mr-1">{this.state.likes}</span>
                            <Like returnValue={(status) => this.liked(status)} liked={this.state.alreadyLiked}/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    ...state,
    config: state.apiR,
})

export default connect(mapStateToProps)(Comment);
