import React from 'react'
import {connect} from 'react-redux'
import {HeaderPost} from '../../header'
import api from '../../../services/api'
import Like from '../like/like'
import {postDelete, postLikes} from '../../../actions/postsAction'
import {API_POST_LIKE} from '../../../services/constants'
import {alreadyLikeFind} from '../../../services/utils'
import MentionHandler from '../mention/mentions'
import ModifyPost from './post_modify'
import PostBodySkeleton from './post-body-skeleton'
import {logger} from '../../../services/logger'

class PostBody extends React.Component {
  constructor(props) {
    super()
    this.state = {
      comment_shown: false,
      comment_text: props.showCommentVar ? 'Masquer' : 'Afficher',
      requestAPILike: false,
      alreadyLiked: false,
      modifyPost: false,
    }
    this.comments = this.comments.bind(this)
    this.liked = this.liked.bind(this)
    this.menuAction = this.menuAction.bind(this)
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    return {
      ...prevState,
      alreadyLiked: alreadyLikeFind(nextProps.post.likes, nextProps.user.id),
    }
  }

  menuAction = (action) => {
    if (action === 'erase') {
      this.props.supprConfirm()
    } else if (action === 'modify') {
      // console.log("modify selected");
      this.setState({modifyPost: true})
    }
  }

  comments = (e) => {
    e.preventDefault()
    this.setState({comment_text: this.props.showCommentVar ? 'Afficher' : 'Masquer'})
    this.props.showComment()
  }

  liked() {
    if (this.state.requestAPILike) {
      return
    }
    this.setState({requestAPILike: true})
    api
      .post(API_POST_LIKE(this.props.post.id), {}, this.props.config)
      .then((res) => {
        this.setState({requestAPILike: false})
        const LikesUpdate = {
          post_id: this.props.post && this.props.post.id,
          likesList: res.data,
        }
        this.props.postLikes(LikesUpdate)
        this.setState({alreadyLiked: !this.state.alreadyLiked})
      })
      .catch((err) => {
        logger.debug('Like post ERROR: ', err)
        this.setState({requestAPILike: false})
      })
  }

  componentWillReceiveProps(newProps) {
    this.setState({comment_text: newProps.showCommentVar ? 'Masquer' : 'Afficher'})
  }

  render() {
    if (!this.props.post) {
      // ! Code PB-001
      logger.debug('DEBUG -- Une erreur est survenue... Code PB-001')
      return <PostBodySkeleton />
    }

    return (
      <div className="container">
        <HeaderPost
          className="row"
          post={this.props.post}
          post_id={this.props.post.id}
          date={this.props.post.createDate}
          user={this.props.post.manager ? this.props.post.club : this.props.post.user}
          returnAction={(action) => this.menuAction(action)}
        />
        <div className="cards-content py-3">
          {this.state.modifyPost ? (
            <ModifyPost post={this.props.post} returnValue={(data) => this.setState({modifyPost: data})} />
          ) : (
            <p className="card-message" style={{whiteSpace: 'pre-line'}}>
              <MentionHandler>{this.props.post.content || ''}</MentionHandler>
            </p>
          )}
        </div>
        {!this.props.post.pinned && (
          <div className="cards-footer d-flex justify-content-between align-items-center">
            <div className="likes comments w-100">
              <div className="d-flex align-items-center justify-content-start">
                <span className="text-primary mr-1">{this.props.post.likes && this.props.post.likes.length}</span>
                <Like returnValue={(status) => this.liked(status)} liked={this.state.alreadyLiked} />
              </div>
              <span className="comment-count" style={{cursor: 'pointer'}} onClick={this.comments}>
                <p className="text-muted m-0">
                  {this.state.comment_text} les commentaires ({this.props.comments})
                </p>
              </span>
            </div>
            <div className="replay-btn w-100 text-right">
              <button type="submit" className="btn btn-outline-primary p-0" onClick={() => this.props.returnValue()}>
                Commenter
              </button>
            </div>
          </div>
        )}
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  user: state.userR,
  config: state.apiR,
})

const mapDispatchToProps = (dispatch) => ({
  postDelete: (payload) => dispatch(postDelete(payload)),
  postLikes: (payload) => dispatch(postLikes(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(PostBody)
