import Box from '@material-ui/core/Box'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import React, {useCallback, useEffect, useState} from 'react'
import {clubSliceActions} from '../../../reducers/clubSlice'
import {useAppDispatch, useAppSelector} from '../../../reducers/hooks'
import {userActions} from '../../../reducers/userReducer'
import {logger} from '../../../services/logger'
import {TITLES} from '../../../shared/data/titles'
import {SignupInput} from '../../form'
import {ImageEdit} from '../../images'

const Signup_1 = ({returnValue}: {returnValue: (res: boolean) => void}) => {
  const dispatch = useAppDispatch()
  const userState = useAppSelector((state) => state.userR)
  const clubState = useAppSelector((state) => state.clubState)

  type Data = {avatar: string; password: string; password_confirm: string; position: string}

  const [positions, setPositions] = useState<{label: string}[]>(TITLES.map((t, index) => ({label: t})))
  const [formData, setFormData] = useState<Data>({
    avatar: userState.avatar,
    password: '',
    password_confirm: '',
    position: userState.position,
  })

  const retrieveData = useCallback((d: string, name: 'phone_number' | 'password' | 'password_confirm' | 'position' | string) => {
    const emailClassList = document.getElementById('phone_number')?.parentElement?.classList
    const phoneNumberClassList = document.getElementById('phone_number')?.parentElement?.classList
    const passwordClassList = document.getElementById('password')?.parentElement?.classList
    const passwordConfirmClassList = document.getElementById('password_confirm')?.parentElement?.classList
    if (!phoneNumberClassList) {
      logger.error('The phone number parent field was not found')
      return
    }
    if (!passwordClassList) {
      logger.error('The password parent field was not found')
      return
    }
    if (!passwordConfirmClassList) {
      logger.error('The password confirm parent field was not found')
      return
    }
    if (!emailClassList) {
      logger.error('The email parent field was not found')
      return
    }
    switch (name) {
      case 'phone_number':
        {
          const number = d.split(' ')
          // eslint-disable-next-line no-restricted-syntax
          for (const element in number) {
            if (Number.isNaN(parseInt(number[element], 10))) {
              phoneNumberClassList.remove('Mui-error')
              returnValue(false)
            } else {
              phoneNumberClassList.remove('Mui-error')
              returnValue(true)
            }
          }
        }
        break
      case 'password':
        if (formData.password_confirm !== d) {
          passwordClassList.add('Mui-error')
          passwordConfirmClassList.add('Mui-error')
        } else {
          passwordClassList.remove('Mui-error')
          passwordConfirmClassList.remove('Mui-error')
        }
        break
      case 'password_confirm':
        if (formData && formData.password !== d) {
          passwordClassList.add('Mui-error')
          passwordConfirmClassList.add('Mui-error')
        } else {
          passwordClassList.remove('Mui-error')
          passwordConfirmClassList.remove('Mui-error')
        }
        break
      default:
        break
    }

    setFormData((prev) => ({
      ...prev,
      [name]: d,
    }))
  }, [])

  useEffect(() => {
    dispatch(
      userActions.userData({
        ...userState,
        ...formData,
      })
    )
  }, [formData, userState])

  const newImg = (file: string, extension: string) => {
    dispatch(userActions.userImg({img_data: file.split(',')[1], extension}))
    setFormData((prev) => ({...prev, avatar: file}))
  }

  useEffect(() => {
    dispatch(clubSliceActions.checkChange(1))
  }, [])

  if (!userState) {
    // ! Code SU-001
    logger.debug('DEBUG -- Une erreur est survenue... Code SU-001')
    return <h1 className="text-center">Une erreur est survenue lors du chargement de cette page... Code SU-001</h1>
  }

  return (
    <div className="ml-2 mr-2">
      {/* @ts-ignore */}
      <Box display="flex" justifyContent="center" p={1}>
        <ImageEdit
          classes="avatar-edit"
          avatar={{
            alt: userState.firstName && userState.lastName ? userState.firstName[0] + userState.lastName[0] : '',
            src: formData && formData.avatar,
          }}
          setDB={(file: string, extension: string) => {
            newImg(file, extension)
          }}
        />
      </Box>
      <p className="text-center text-primary mt-n1">
        <strong>{`${userState.firstName} ${userState.lastName}`}</strong>
      </p>
      <form>
        <Autocomplete
          freeSolo
          autoHighlight
          options={positions}
          value={formData.position}
          onInputChange={(e, value) => {
            retrieveData(value, 'position')
            // if (userState.position !== value) {
            //   retrieveData(value, 'position')
            // }
          }}
          onChange={(e, value) => {
            if (!value) {
              return
            }
            if (typeof value === 'string') {
              retrieveData(value, 'position')
            } else {
              retrieveData(value.label, 'position')
            }
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              required={false}
              label="Poste"
              inputProps={{
                ...params.inputProps,
                autoComplete: 'position', // disable autocomplete and autofill
              }}
            />
          )}
        />
        <SignupInput id="linkedin_url" label="Linkedin" value={userState.linkedin_url} retrieve={(data: string, name: string) => retrieveData(data, name)} />
        <SignupInput
          id="email"
          label="Email"
          value={userState.email}
          type="email"
          params={{required: true}}
          retrieve={(data: string, name: string) => retrieveData(data, name)}
        />
        <SignupInput
          id="phone_number"
          label="Téléphone"
          value={userState.phone_number}
          params={{required: true}}
          retrieve={(data: string, name: string) => retrieveData(data, name)}
        />
        <SignupInput
          id="password"
          label="Définir nouveau mot de passe"
          value={userState.password}
          type="password"
          params={{required: true}}
          modifiable
          retrieve={(data: string, name: string) => retrieveData(data, name)}
        />
        <SignupInput
          id="password_confirm"
          label="Confirmer le nouveau mot de passe"
          value={userState.password_confirm}
          type="password"
          params={{required: true}}
          modifiable
          retrieve={(data: string, name: string) => retrieveData(data, name)}
        />
      </form>
    </div>
  )
}

export default Signup_1
