import {fetchRequestCommon} from './fetch'

export type WordTheme = {
  label: string
  prefix_label: string
  undefined_prefix_label: string
  defined_prefix_label: string
  plural_label: string
  group_name: string
  prefix_group_name: string
  undefined_prefix_group_name: string
  defined_prefix_group_name: string
  plural_group_name: string
  partner_name: string
  prefix_partner_name: string
  undefined_prefix_partner_name: string
  defined_prefix_partner_name: string
  plural_partner_name: string
  partner_type: string
  the_partner_type: string
  of_the_partner_type: string
  partner_type_plural: string
  the_partner_type_plural: string
  plural_sponsors_name: string
}

const getWordTheme = async (tokenUser: string, clubId: number) => {
  const res: Error | WordTheme = await fetchRequestCommon<WordTheme>(`/wordtheme/${clubId}`, 'GET', tokenUser)
  if (res instanceof Error) {
    throw res
  }
  return res
}

export default {
  getWordTheme,
}
