import React, { useEffect } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import api from '../../services/api';
import { API_USER_LOGOUT } from '../../services/constants';
import { removeToken } from '../../actions/apiAction';
import { connect } from 'react-redux';
import { cleanPosts } from '../../actions/postsAction';
import { useAppDispatch } from "../../reducers/hooks";
import { connexionStatusActions } from "../../shared/redux/connexionStatus.slice";
import {logger} from "../../services/logger";

const Logout = ({ socket, user_id, config, removeToken, cleanPosts, history }) => {
    const dispatch = useAppDispatch()

  useEffect(() => () => {
    dispatch(connexionStatusActions.onDisconnect())
  }, [])

  api.get(API_USER_LOGOUT, config)
        .then(res => {
          removeToken();
          cleanPosts();
          /*if (socket) {
              socket.emit('disconnectManual', user_id);
          }*/
          localStorage.removeItem('Authorization');
          localStorage.removeItem('User');
          localStorage.removeItem('Device');
          localStorage.removeItem('Company');
          history.push('/login');
        })
        .catch(err => {
          logger.debug("Logout ERROR: ", err);
        });

    return (
        <Redirect to="/login" />
    );
};

const mapDispatchToProps = dispatch => ({
    removeToken: payload => dispatch(removeToken(payload)),
    cleanPosts: () => dispatch(cleanPosts()),
})

const mapStateToProps = state => ({
    socket: state.conversationsR.socket,
    user_id: state.userR.id,
    config: state.apiR
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Logout));
