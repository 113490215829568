export const API_POSTS_LOAD = (pagination, actual_nbr_posts) => ("/api/posts/" + pagination + "/" + actual_nbr_posts);

export const API_POST_LOAD = (post_id) => ("/api/post/" + post_id);

export const API_POSTS_FILTER_LOAD = (pagination, actual_nbr_posts) => ("/api/search/posts_comments/" + pagination + "/" + actual_nbr_posts);

export const API_POST_ADD = "/api/posts";

export const API_DOCUMENT_ADD = "/api/document/add";

export const API_POST_DELETE = (post_id) => ("/api/posts/" + post_id);

export const API_POST_LIKE = (post_id) => ("/api/posts/" + post_id + "/likes");

export const API_POST_MODIFY = (post_id) => ("/api/posts/" + post_id);

export const API_COMPANIES_LOAD = "/api/search/companies";

export const API_COMPANY_LOAD = (company_id) => ("/api/search/company/" + company_id);

export const API_SEARCH_COMPANIES_USERS = (pagination) => ("/api/search/" + pagination);

export const API_USER_LOGOUT = "/api/user/log_out";

export const API_USER_LOGIN = (club_id) => ("/userapi/my_login/login/" + club_id);

export const API_USER_MAIL_LOGIN = "/userapi/my_login/email_login";

export const API_USER_SEARCH = (pagination) => ("/api/search/users/" + pagination);

export const API_USER_LOAD = (user_id) => ("/api/user/read/" + user_id);

export const API_CURRENT_USER = "/api/user";

export const API_USER_MODIFY = "/api/user/update";

export const API_USER_REGISTRATION = "/api/my_login/register/confirmation";

export const API_COMMENT_DELETE = (post_id, comment_id) => ("/api/post/" + post_id + "/comment/" + comment_id);

export const API_COMMENT_LIKE = (post_id, comment_id) => ("/api/post/" + post_id + "/comment/" + comment_id + "/like");

export const API_COMMENT_ADD = (post_id) => ("/api/post/" + post_id + "/comment");

export const API_COMMENT_MODIFY = (post_id, comment_id) => ("/api/post/" + post_id + "/comment/" + comment_id);

export const API_PASSWORD_FORGOT_EMAIL = '/userapi/account/forgot_password';

export const API_PASSWORD_FORGOT_RESET = (email, id_forget_password, token, club_id) => ('/userapi/resetpwd/' + email + '/' + id_forget_password + '/' + token + '/' + club_id);

export const API_NOTIFICATIONS_UNREAD_LOAD = '/api/user/notification_enabled';

export const API_NOTIFICATIONS_ALL_LOAD = '/api/user/notification_all';

export const API_CLUB_INFO = '/publicapi/club/info';

export const API_GET_ALL_SECTORS = '/api/sector/';

export const API_GET_COMPANIES_OF_SECTOR = (sector_id) => `/api/company/sector/${sector_id}`;

export const API_GET_ALL_COMPANIES = '/api/company/';
export const API_GET_GALLERY = '/apimobile/pictures';
export const API_GET_EVENTS = '/apimobile/events/';

export const API_GET_ADVERTS_POSTS = '/api/adverts';

export const API_TEST_IF_RESET_PASSWORD_WORK = (email, id, token, club_id) => `/user_api/forget_password/check_validity?email=${email}&id=${id}&token=${token}&club=${club_id}`;

export const BASE_URL = process.env.REACT_APP_BASE_URL;

export const BASE_MESSAGING_PICTURES_URL = `${process.env.REACT_APP_BASE_URL}/uploads/messaging/`;

export const POST_IMAGE_UPLOAD_LINK = `${process.env.REACT_APP_BASE_URL}/uploads/post/`;

export const USER_IMAGE_UPLOAD_LINK = `${process.env.REACT_APP_BASE_URL}/uploads/user/`;

export const COMPANY_IMAGE_UPLOAD_LINK = `${process.env.REACT_APP_BASE_URL}/uploads/company/`;

export const CLUB_IMAGE_UPLOAD_LINK = `${process.env.REACT_APP_BASE_URL}/uploads/club/`;

export const ADVERT_IMAGE_UPLOAD_LINK = `${process.env.REACT_APP_BASE_URL}/uploads/data/`;

export const REGEX_USER_MENTION = /@\[[A-Za-z].*?\]\([0-9]+\)/g;

export const REGEX_HASHTAGS = /#[A-Za-z0-9]+/g;

export const REGEX_USER_MENTION_HASHTAGS = /@\[[A-Za-z].*?\]\([0-9]+\)|#[A-Za-z0-9]+/g;

export const NEW_CONVERSATION_INSTRUCTIONS = "Pour démarrer une nouvelle conversation, cliquez sur le bouton \"Contacter\" du profil d'un utilisateur.";

export const CONVERSATION_PLACEHOLDER = "Toutes vos prochaines conversations apparaîtront ici."

export const selectStyle = (color) => ({
    control: (styles, { isFocused }) => ({
        ...styles,
        boxShadow: `${color}`,
        borderColor: isFocused ? `${color}` : "#000000",
        ':active': {
            borderColor: `${color}`,
        },
        ':hover': {
            borderColor: `${color}`,
        }
    }),
    option: (styles, { isFocused, isSelected }) => ({
        ...styles,
        backgroundColor: isSelected ? `${color}` : isFocused ? '#00000059' : '#fff',
        ':active': {
            backgroundColor: `${color}50`
        }
    }),
    theme: styles => ({
        ...styles,
        borderRadius: 0
    }),
    input: styles => ({...styles}),
    menu: styles => ({...styles, zIndex: 99})
});
