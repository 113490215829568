import {authedApi} from '../../services/api'
import {logger} from '../../services/logger'
import type {EntityFor} from './api.types'

export type ApiGroupConversation = {
  id: number
  name: string
  lastMessageAccountId: number
  lastMessageDate: string | number
  lastMessageRead: boolean
  picture?: string
  messages: EntityFor['messaging']['Message'][]
  accounts: EntityFor['messaging']['ConversationGroupAccount'][]
  isGroup: boolean
}
export type ConversationsApiRes = {
  timestamp: number
  conversations?: ApiGroupConversation[]
}
const getAllConversations = async (): Promise<ConversationsApiRes> => {
  try {
    return (await authedApi.get(`/apicommon/conversations`)).data
  } catch (e) {
    logger.error(`could not get all the user conversations`, e)
    throw e
  }
}

const getConversationsFrom = async (timestamp: number): Promise<ConversationsApiRes> => {
  try {
    return (await authedApi.get(`/apicommon/conversations?timestamp=${timestamp}`)).data
  } catch (e) {
    logger.error(`could not get user conversations since timestamp: ${timestamp}`, e)
    throw e
  }
}

export default {
  getAllConversations,
  getConversationsFrom,
}
