import {Conv} from '../api/api.types'

export const sortByLatestMessageDesc = (a: Conv, b: Conv) => {
  if (a.lastMessageDate === null && b.lastMessageDate === null) {
    return 0
  }
  if (a.lastMessageDate === null && b.lastMessageDate !== null) {
    return 1
  }
  if (a.lastMessageDate !== null && b.lastMessageDate === null) {
    return -1
  }

  const aDate = typeof a.lastMessageDate === 'string' ? parseInt(a.lastMessageDate, 10) : a.lastMessageDate
  const bDate = typeof b.lastMessageDate === 'string' ? parseInt(b.lastMessageDate, 10) : b.lastMessageDate

  return bDate - aDate
}
