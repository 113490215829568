import React from 'react';
import { Avatar } from '../../../component/user';

const PostBodySkeleton = () => {
    return (
        <div className="container">
            <div className="col-9 p-0 d-flex align-items-center">
                <div className="avatar-edit mr-3">
                    <Avatar size="sm" />
                </div>
                <div className="user-profile-name">
                    <div className="mt-1" style={{backgroundColor: '#00000030', width: 80, height: 15}}></div>
                    <div className="mt-1" style={{backgroundColor: '#00000030', width: 120, height: 15}}></div>
                    <div className="mt-1" style={{backgroundColor: '#00000030', width: 60, height: 15}}></div>
                </div>
            </div>
            <div className="cards-content py-3">
                <div className="mt-1" style={{backgroundColor: '#00000030', width: 260, height: 15}}></div>
                <div className="mt-1" style={{backgroundColor: '#00000030', width: 180, height: 15}}></div>
                <div className="mt-1" style={{backgroundColor: '#00000030', width: 230, height: 15}}></div>
            </div>
            <div className="cards-footer d-flex justify-content-between align-items-center">
                 <div className="likes comments w-100">
                    <div className="d-flex align-items-center justify-content-start">
                        <div className="mt-1" style={{backgroundColor: '#00000030', width: 40, height: 15}}></div>
                    </div>
                    <span className="comment-count">
                        <div className="mt-1" style={{backgroundColor: '#00000030', width: 80, height: 15}}></div>
                    </span>
                </div>
                <div className="replay-btn w-100">
                    <div className="mt-1 ml-auto" style={{backgroundColor: '#00000030', width: 50, height: 15}}></div>
                </div>
            </div>
        </div>
    );
};

export default PostBodySkeleton;
