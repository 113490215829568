import React, {MouseEvent, useCallback, useState} from 'react'
import {RouteComponentProps, withRouter} from 'react-router-dom'
import {Avatar} from '../../user'
import {postDetails} from '../../../actions/postsAction'
import {userDetailsLoad, userDetailsShow} from '../../../actions/userDetailsAction'
import api, {authedApi} from '../../../services/api'
import {API_POST_LOAD, API_USER_LOAD, CLUB_IMAGE_UPLOAD_LINK, USER_IMAGE_UPLOAD_LINK} from '../../../services/constants'
import {dateFormaterPost, imageAppendUrl} from '../../../services/utils'
import {logger} from '../../../services/logger'
import {useAppDispatch, useAppSelector} from '../../../reducers/hooks'
import {useApi} from '../../../shared/api/useApi'

const NotificationSkeleton = ({errCode}: {errCode: string}) => {
  return (
    <div className="card-body">
      <div className="d-flex align-items-center flex-row">
        <Avatar size="sm" />
        <div className="ml-2 mb-0" style={{backgroundColor: '#00000030', width: 80, height: 15}} />
        <div className="ml-1" style={{backgroundColor: '#00000030', width: 160, height: 15}} />
      </div>
      <div style={{backgroundColor: '#00000030', width: 60, height: 15}} />
      <small className="text-muted">Erreur {errCode}</small>
    </div>
  )
}

type NotificationElementProps = RouteComponentProps & {
  onNotifChanged: (notif: {id: number; enabled: boolean}) => void
  notif: {
    id: number
    enabled: boolean
    notificationType: {libelle: string}
    date?: string
    event?: {id: number}
    sender?: {id?: number; avatar?: string; firstName?: string; lastName?: string}
    post?: {id: number}
    document?: {id: number}
    picture?: {id: number}
    gallery?: {id: number}
  }
}

const NotificationElement = ({history, notif, onNotifChanged}: NotificationElementProps) => {
  const {id, enabled, date, event, notificationType, post, document, picture, gallery, sender} = notif
  const config = useAppSelector((state) => state.apiR)
  const dispatch = useAppDispatch()

  const notifCreatedDate = dateFormaterPost(date)

  const markReadApi = useApi((notificationId: number) => authedApi.post(`/api/user/notification/${notificationId}/read`, {}))
  const markRead = useCallback(async () => {
    if (enabled) {
      onNotifChanged({id: notif.id, enabled: false})
      await markReadApi.call(id)
    }
  }, [id, enabled])

  const loadPost = useCallback(async () => {
    if (!post?.id) {
      // ! Code PL-100
      logger.debug('DEBUG -- Une erreur est survenue... Code PL-100')
      await markRead()
      return
    }

    try {
      const res = await api.get(API_POST_LOAD(post.id), config)
      if (!res?.data?.post?.club) {
        // ! Code PL-200
        logger.debug('DEBUG -- Une erreur est survenue... Code PL-200')
        return
      }
      const postPayload = {
        ...res.data.post,
        club: res.data.club,
      }
      if (postPayload.manager === null) {
        postPayload.user.avatar = imageAppendUrl(USER_IMAGE_UPLOAD_LINK, postPayload.user.avatar)
      } else {
        postPayload.club.avatar = imageAppendUrl(CLUB_IMAGE_UPLOAD_LINK, postPayload.club.avatar)
      }
      await markRead()
      dispatch(postDetails(postPayload))
      if (window.location.pathname !== '/') {
        history.push('/')
      }
    } catch (err) {
      await markRead()
      logger.debug('Load post details ERROR: ', err)
    }
  }, [post, markRead, enabled])

  const userShow = useCallback(
    async (e: MouseEvent<HTMLAnchorElement>, userId?: number) => {
      e.preventDefault()
      e.stopPropagation()

      if (!userId) {
        // ! Code UL-100
        logger.debug('DEBUG -- Une erreur est survenue... Code UL-100')
        await markRead()
        return
      }

      api
        .get(API_USER_LOAD(userId), config)
        .then(async (res) => {
          dispatch(
            userDetailsLoad({
              ...res.data,
              avatar: imageAppendUrl(USER_IMAGE_UPLOAD_LINK, res.data.avatar),
            })
          )
          await markRead()
          dispatch(userDetailsShow(true))
          if (window.location.pathname !== '/') {
            history.push('/')
          }
        })
        .catch((err) => {
          logger.debug('User show ERROR: ', err)
        })
    },
    [markRead, enabled]
  )

  const goToCalendar = useCallback(async () => {
    if (!event) return

    await markRead()
    history.push(`/calendar?event_id=${event.id}`)
  }, [event, markRead, enabled])

  const CommentLike = useCallback(() => {
    if (!sender) {
      // ! Code NCL-001
      logger.debug('DEBUG -- Une erreur est survenue... Code NCL-001')
      return <NotificationSkeleton errCode="NCL-001" />
    }

    return (
      <div className="card-body" onClick={() => loadPost()}>
        <div className="d-flex align-items-center flex-row">
          <Avatar img={sender.avatar} alt={sender.firstName && sender.lastName ? sender.firstName[0] + sender.lastName[0] : ''} size="sm" />
          <p className={`pl-2 ${enabled ? 'font-weight-bold' : ''}`}>
            <a className="text-primary" onClick={(e) => userShow(e, sender.id)}>
              {sender.firstName} {sender.lastName}
            </a>{' '}
            a aimé votre commentaire
          </p>
        </div>
        {notifCreatedDate && (
          <p className="text-muted text-right p-0 my-n1" style={{fontSize: 12}}>
            {notifCreatedDate}
          </p>
        )}
      </div>
    )
  }, [notifCreatedDate, sender, markRead, enabled])

  const PostLike = useCallback(() => {
    if (!sender) {
      // ! Code NL-001
      logger.debug('DEBUG -- Une erreur est survenue... Code NL-001')
      return <NotificationSkeleton errCode="NL-001" />
    }

    return (
      <div className="card-body" onClick={() => loadPost()}>
        <div className="d-flex align-items-center flex-row">
          <Avatar img={sender.avatar} alt={sender.firstName && sender.lastName ? sender.firstName[0] + sender.lastName[0] : ''} size="sm" />
          <p className={`pl-2 ${enabled ? 'font-weight-bold' : ''}`}>
            <a className="text-primary" onClick={(e) => userShow(e, sender.id)}>
              {sender.firstName} {sender.lastName}
            </a>{' '}
            a aimé votre publication
          </p>
        </div>
        {notifCreatedDate && (
          <p className="text-muted text-right p-0 my-n1" style={{fontSize: 12}}>
            {notifCreatedDate}
          </p>
        )}
      </div>
    )
  }, [notifCreatedDate, loadPost, sender, userShow, markRead, enabled])

  const NewComment = useCallback(() => {
    if (!sender) {
      // ! Code NC-001
      logger.debug('DEBUG -- Une erreur est survenue... Code NC-001')
      return <NotificationSkeleton errCode="NC-001" />
    }

    return (
      <div className="card-body" onClick={() => loadPost()}>
        <div className="d-flex align-items-center flex-row">
          <Avatar img={sender.avatar} alt={sender.firstName && sender.lastName ? sender.firstName[0] + sender.lastName[0] : ''} size="sm" />
          <p className={`pl-2 ${enabled ? 'font-weight-bold' : ''}`}>
            <a className="text-primary" onClick={(e) => userShow(e, sender.id)}>
              {sender.firstName} {sender.lastName}
            </a>{' '}
            a commenté votre publication
          </p>
        </div>
        {notifCreatedDate && (
          <p className="text-muted text-right p-0 my-n1" style={{fontSize: 12}}>
            {notifCreatedDate}
          </p>
        )}
      </div>
    )
  }, [notifCreatedDate, loadPost, userShow, sender, markRead, enabled])

  const Mentioned = useCallback(() => {
    if (!sender) {
      // ! Code NM-001
      logger.debug('DEBUG -- Une erreur est survenue... Code NM-001')
      return <NotificationSkeleton errCode="NM-001" />
    }

    return (
      <div className="card-body" onClick={() => loadPost()}>
        <div className="d-flex align-items-center flex-row">
          <Avatar img={sender.avatar} alt={sender.firstName && sender.lastName ? sender.firstName[0] + sender.lastName[0] : ''} size="sm" />
          <p className={`pl-2 ${enabled ? 'font-weight-bold' : ''}`}>
            <a className="text-primary" onClick={(e) => userShow(e, sender.id)}>
              {sender.firstName} {sender.lastName}
            </a>{' '}
            vous a mentionné dans une publication
          </p>
        </div>
        {notifCreatedDate && (
          <p className="text-muted text-right p-0 my-n1" style={{fontSize: 12}}>
            {notifCreatedDate}
          </p>
        )}
      </div>
    )
  }, [notifCreatedDate, sender, loadPost, userShow, markRead, enabled])

  const NewEvent = useCallback(() => {
    return (
      <div className="card-body" onClick={() => goToCalendar()}>
        <p className={enabled ? 'font-weight-bold' : ''}>Vous êtes invités à un nouvel événement</p>
        {notifCreatedDate && (
          <p className="text-muted text-right p-0 my-n1" style={{fontSize: 12}}>
            {notifCreatedDate}
          </p>
        )}
      </div>
    )
  }, [notifCreatedDate, goToCalendar, markRead, enabled])

  const NewPost = useCallback(() => {
    return (
      <div className="card-body" onClick={() => loadPost()}>
        <p className={enabled ? 'font-weight-bold' : ''}>Un nouveau post a été partagé</p>
        {notifCreatedDate && (
          <p className="text-muted text-right p-0 my-n1" style={{fontSize: 12}}>
            {notifCreatedDate}
          </p>
        )}
      </div>
    )
  }, [notifCreatedDate, loadPost, markRead, enabled])

  const NewDocument = useCallback(() => {
    return (
      <div
        className="card-body"
        onClick={async () => {
          await markRead()
          history.push(`/bibliotheque?id=${document?.id}`)
        }}>
        <p className={enabled ? 'font-weight-bold' : ''}>Un nouveau document a été partagé</p>
        {notifCreatedDate && (
          <p className="text-muted text-right p-0 my-n1" style={{fontSize: 12}}>
            {notifCreatedDate}
          </p>
        )}
      </div>
    )
  }, [notifCreatedDate, markRead, enabled])

  const NewPicture = useCallback(() => {
    return (
      <div
        className="card-body"
        onClick={async () => {
          await markRead()
          history.push(`/gallery?pictureId=${picture?.id}`)
        }}>
        <p className={enabled ? 'font-weight-bold' : ''}>Une nouvelle photo a été partagée</p>
        {notifCreatedDate && (
          <p className="text-muted text-right p-0 my-n1" style={{fontSize: 12}}>
            {notifCreatedDate}
          </p>
        )}
      </div>
    )
  }, [notifCreatedDate, markRead, enabled])

  const NewPictures = useCallback(() => {
    return (
      <div
        className="card-body"
        onClick={async () => {
          await markRead()
          history.push(`/gallery?galleryId=${gallery?.id || -1}`)
        }}>
        <p className={enabled ? 'font-weight-bold' : ''}>De nouvelles photos ont été partagées</p>
        {notifCreatedDate && (
          <p className="text-muted text-right p-0 my-n1" style={{fontSize: 12}}>
            {notifCreatedDate}
          </p>
        )}
      </div>
    )
  }, [notifCreatedDate, markRead, enabled])

  const notifRenderComponentMap: Record<string, React.FC | undefined> = {
    comment_liked: CommentLike,
    post_liked: PostLike,
    new_comment: NewComment,
    mentioned: Mentioned,
    // eslint-disable-next-line react/no-unstable-nested-components
    new_message: () => (
      <div className="card-body">
        <p>Vous avez un nouveau message</p>
      </div>
    ),
    new_event: NewEvent,
    new_post: NewPost,
    new_document: NewDocument,
    new_picture: NewPicture,
    new_pictures: NewPictures,
  }

  const Content = notifRenderComponentMap[notificationType.libelle]

  if (!Content) {
    // ! Code NE-002
    logger.debug('DEBUG -- Une erreur est survenue... Code NE-002')
    return <NotificationSkeleton errCode="NE-002" />
  }

  return (
    <div className="card m-1" style={{cursor: 'pointer'}}>
      <Content />
    </div>
  )
}

export default withRouter(NotificationElement)
