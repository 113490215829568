import storage from 'redux-persist/lib/storage'
import ImmutablePersistenceTransform from '../services/immutablePersistenceTransform'

export const persistConfig = {
  // @ts-ignore
  storeConfig: {
    key: 'root',
    storage,
    transforms: [ImmutablePersistenceTransform],
    debug: true,
    // persistStore
    whitelist: ['connexionStatusRedux', 'wordTheme'],
    // not persist
    // blacklist: ['modalInformationRedux', 'spinnerRedux', 'nodeRedux'],
    timeout: undefined,
  },
}
