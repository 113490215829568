import {AnyAction, createSlice, Draft, PayloadAction, ThunkAction, ThunkDispatch} from '@reduxjs/toolkit'
import {Conv, ConvAccount, EntityFor, Message} from '../api/api.types'
import type {AppDispatch, AppThunk, RootState} from '../../redux/store'
import {logger} from '../../services/logger'
import {translateAndMergeIntoLocal} from './messaging.translate'
import conversationsApi, {ApiGroupConversation, ConversationsApiRes} from '../api/conversationsApi'
import {applyObjectVal, mutateObject} from '../utils/objects'
import {UserFacingError} from '../api/errors'
import {showLocalNotification} from '../../bridge/showLocalNotification'
import type {NotifDataMessaging} from './notification.types'
import {selectConnectedState} from './connexionStatus.slice'
import {authedApi} from '../../bridge/api'
import {ensureAccount} from '../../bridge/ensureAccount.thunk.bridge'
import {LoginResAccount} from '../api/loginApi'

export type MessagingState = {
  isEstablishingConnection: boolean
  isDisconnecting: boolean
  isConnected: boolean
  convs: Record<number, Conv>
  accounts: Record<number, ConvAccount>
  timestamp: number
  activeConvId?: number
  shouldUpdateConvs: boolean
  lastMessageTimestamp: number
  redirectToConvId?: number
  haveNewMessages: number
  isLoadingConversations: boolean
}

const initialState: MessagingState = {
  isEstablishingConnection: false,
  isDisconnecting: false,
  isConnected: false,
  convs: {},
  accounts: {},
  timestamp: 0,
  activeConvId: undefined,
  shouldUpdateConvs: false,
  lastMessageTimestamp: 0,
  redirectToConvId: undefined,
  haveNewMessages: 0,
  isLoadingConversations: false,
}

let nextNotificationId = 0

const messagingSlice = createSlice({
  name: 'messaging',
  initialState,
  reducers: {
    // used in messagingSocketMiddleware
    startConnecting: () => {},
    // used in messagingSocketMiddleware
    startDisconnecting: () => {},
    // used in messagingSocketMiddleware
    disconnected: (state) => {
      state.isConnected = false
    },
    // used in messagingSocketMiddleware
    connectionEstablished: (state) => {
      state.isConnected = true
      state.shouldUpdateConvs = true
    },
    initAndMerge: (
      state,
      action: PayloadAction<{
        currentAccountId: number
        conversations: ApiGroupConversation[]
        timestamp: number
      }>
    ) => {
      const res = translateAndMergeIntoLocal(action.payload.currentAccountId, state.convs, action.payload.conversations)
      // extract Accounts
      Object.values(res.convs).forEach((c) => {
        c.accounts.forEach((a: ConvAccount) => {
          const existingAccount = state.accounts[a.id]
          if (existingAccount) {
            logger.debug(`found local account (${a.id}) with connected state: `, existingAccount.connected)
            state.accounts[a.id] = {
              ...a,
              connected: existingAccount.connected,
            }
          } else {
            state.accounts[a.id] = a
          }
        })
      })
      state.convs = res.convs
      state.timestamp = action.payload.timestamp
      state.haveNewMessages = res.haveNewMessages
      state.lastMessageTimestamp = new Date().getTime()
      state.shouldUpdateConvs = false
      state.redirectToConvId = undefined
    },
    setLoadingConversations: (state, action: PayloadAction<boolean>) => {
      state.isLoadingConversations = action.payload
    },
    onDisconnect: () => initialState,
    setActiveConv: (state, action: PayloadAction<number | undefined>) => {
      state.activeConvId = action.payload
    },
    setNeedUpdate: (state, action: PayloadAction<undefined | {g_convId?: number; convId?: number}>) => {
      state.shouldUpdateConvs = true
      state.redirectToConvId = action.payload?.g_convId
    },
    receiveMessage: (state: Draft<MessagingState>, {payload: {message, fromMe = true}}: PayloadAction<{message: Message; fromMe?: boolean}>) => {
      const hasActiveConv = state.activeConvId === message.convId
      logger.debug('received message', message)
      logger.debug('hasActiveConv', hasActiveConv)
      let isNewMessage = false
      const updated = mutateObject<Conv>(state.convs, message.convId, (c) => {
        const existingIndex = c.messages.findIndex((localMessage) => localMessage.uuid === message.uuid)
        logger.debug('existingIndex', existingIndex)
        isNewMessage = existingIndex < 0
        if (isNewMessage) {
          logger.debug('NEW MESSAGE', message.message)
          logger.debug('c.messages.length', c.messages.length)
          c.messages.unshift(message)
          logger.debug('c.messages.length', c.messages.length)
        } else {
          logger.debug('MESSAGE EXISTS', message.message)
          logger.debug('c.messages.length', c.messages.length)
          c.messages.splice(existingIndex, 1, message)
          logger.debug('c.messages.length', c.messages.length)
        }
        // eslint-disable-next-line no-param-reassign
        c.lastMessageAccountId = message.source
        // eslint-disable-next-line no-param-reassign
        c.lastMessageDate = message.serverTimestamp || message.timestamp
        // eslint-disable-next-line no-param-reassign
        c.lastMessageRead = fromMe
        return c
      })
      state.shouldUpdateConvs = updated === undefined
      // logger.debug('did update', !state.shouldUpdateConvs)

      if (updated && !fromMe) {
        logger.debug('receiveMessage (haveNewMessages)')
        state.haveNewMessages += 1

        if (!hasActiveConv && isNewMessage) {
          const buildTitle = (user?: EntityFor['messaging']['User']) => {
            if (!user) {
              return 'Nouveau Message'
            }

            let {firstName, lastName} = user
            firstName = firstName && firstName.trim()
            lastName = lastName && lastName.trim()

            const names: string[] = []
            if (firstName && firstName.trim().length > 0) {
              names.push(firstName)
            }
            if (lastName && lastName.trim().length > 0) {
              names.push(lastName)
            }
            return names.join(' ')
          }
          const user = updated.accounts[message.source]?.user

          logger.debug('FORWARDING NOTIF')

          const notificationId = `${nextNotificationId}`
          nextNotificationId += 1
          // eslint-disable-next-line no-param-reassign
          message.notificationId = notificationId
          const notifData: NotifDataMessaging = {
            isLocalNotification: true,
            type: 'messaging',
            data: {
              ...message,
              convId: message.convId,
            },
          }
          showLocalNotification(
            {
              title: buildTitle(user),
              message: message.message ? message.message : '[picture]',
            },
            notifData,
            `conv-${message.convId}`
          )
        }
      }
      state.lastMessageTimestamp = new Date().getTime()
    },
    updateAccountConnectedState: (
      state,
      {
        payload: {connectionState, accountId},
      }: PayloadAction<{
        accountId: number
        connectionState: 'connected' | 'not connected'
      }>
    ) => {
      logger.debug('UPDATE CONV CONNECTED STATE', {
        accountId,
        connectionState,
      })
      let account = state.accounts[accountId]
      if (!account) {
        // Attention ici. En principe de laisse un account avec juste l'id ne devrait pas poser de problèmes.
        // On n'utilise ces données que pour du read du statut de connexion (tant qu'on a pas récup les conversations).
        // @ts-ignore
        account = {
          id: accountId,
        }
        state.accounts[accountId] = account
      }
      account.connected = connectionState === 'connected'
      state.lastMessageTimestamp = new Date().getTime()
    },
    markAllAccountsOffline: (state) => {
      // mark all users as disconnected
      Object.values(state.accounts).forEach((a: ConvAccount) => {
        // eslint-disable-next-line no-param-reassign
        a.connected = false
      })
    },
    markConversationRead: (
      state,
      action: PayloadAction<{
        convId: number
        currentAccountId: number
      }>
    ) => {
      const {convId, currentAccountId} = action.payload
      applyObjectVal<Conv>(state.convs, convId, 'lastMessageRead', true)
      state.haveNewMessages = Object.values(state.convs).filter((c) => c.lastMessageAccountId !== currentAccountId && !c.lastMessageRead).length
      logger.debug('state.haveNewMessages', state.haveNewMessages)
    },
    setNewMessageCount: (state, action: PayloadAction<number>) => {
      state.haveNewMessages = action.payload
    },
    submitMessage: () => {},
  },
  extraReducers: {},
})

export const messagingActions = messagingSlice.actions

export const selectMessaging = (state: RootState): MessagingState => state.messagingR

export const selectAccount =
  (accountId?: number) =>
  (state: RootState): ConvAccount | undefined =>
    accountId ? state.messagingR.accounts[accountId] : undefined

export const selectConv =
  (convId: number) =>
  (state: RootState): Conv | undefined =>
    state.messagingR.convs[convId]

export const selectConvForAccountId =
  (accountId: number) =>
  (state: RootState): Conv | undefined => {
    return Object.values(state.messagingR.convs).find((conv: Conv) => !conv.isGroup && conv.otherAccountId === accountId)
  }

export const fetchConversations =
  (redirectConvId?: number): AppThunk =>
  async (dispatch: ThunkDispatch<RootState, unknown, AnyAction>, getState: () => RootState) => {
    const {timestamp, convs: localConvs, isLoadingConversations} = selectMessaging(getState())
    if (isLoadingConversations) return

    dispatch(messagingActions.setLoadingConversations(true))

    try {
      const account: LoginResAccount = await dispatch(ensureAccount())

      let data: ConversationsApiRes
      if (Object.keys(localConvs).length === 0) {
        data = await conversationsApi.getAllConversations()
      } else {
        data = await conversationsApi.getConversationsFrom(timestamp - 100000)
      }
      if (data.conversations) {
        dispatch(messagingActions.initAndMerge({currentAccountId: account.id, conversations: data.conversations, timestamp: data.timestamp}))
      }
      if (redirectConvId) {
        dispatch(messagingActions.setActiveConv(redirectConvId))
      }
    } catch (e) {
      logger.error('erreur lors de la récupération des conversations', e)
      throw new UserFacingError('Oups !', 'Une erreur est survenue lors de la récupération des conversations. Veuillez réessayer plus tard.')
    } finally {
      dispatch(messagingActions.setLoadingConversations(false))
    }
  }

export const markConversationRead =
  (convId: number): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch: ThunkDispatch<RootState, unknown, AnyAction>, getState: () => RootState) => {
    const {account} = selectConnectedState(getState())
    dispatch(
      messagingActions.markConversationRead({
        convId,
        currentAccountId: account.id,
      })
    )

    await authedApi.post(`/apicommon/conversations/${convId}/read`)
  }

export default messagingSlice.reducer
