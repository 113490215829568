import React from 'react';
import Avatar from '../avatar/Avatar';

const UserDataSkeleton = () => {
    return (
        <div className="card pt-2 mb-2">
            <div className="card-header d-flex align-items-center justify-content-center flex-column w-100">
                <div className="d-flex align-items-center justify-content-center flex-column">
                    <Avatar size="md"/>
                    <div className="mt-1 mx-auto" style={{backgroundColor: '#00000030', width: 100, height: 15}}></div>
                    <div className="mt-1 mx-auto" style={{backgroundColor: '#00000030', width: 80, height: 15}}></div>
                </div>
                <div className="mt-1 mx-auto" style={{backgroundColor: '#00000030', width: 20, height: 15}}></div>
            </div>
            <div className="card-body d-flex justify-content-center align-items-center mt-n2">
                <div className="card-text">
                    <div className="d-flex flex-row align-items-center justify-content-center">
                        <div className="mt-1 mx-auto" style={{backgroundColor: '#00000030', width: 20, height: 15}}></div>
                        <div className="mt-1 ml-1 mx-auto" style={{backgroundColor: '#00000030', width: 60, height: 15}}></div>
                    </div>
                    <p className="d-flex flex-row align-items-center justify-content-center">
                        <div className="mt-1 mx-auto" style={{backgroundColor: '#00000030', width: 20, height: 15}}></div>
                        <div className="mt-1 ml-1 mx-auto" style={{backgroundColor: '#00000030', width: 50, height: 15}}></div>
                    </p>
                    <p className="text-center" style={{whiteSpace: 'pre-line'}}>
                        <div className="mt-1 mx-auto" style={{backgroundColor: '#00000030', width: 120, height: 15}}></div>
                    </p>
                </div>
            </div>
        </div>
    );
};

export default UserDataSkeleton;
