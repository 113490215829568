import {baseUrl, baseUrlAuth, baseUrlCommon, baseUrlNotAuth, baseUrlPublic} from '../../services/url'
import {BodyInitType} from '../../services/types'
import {logger} from '../../services/logger'

const onError = (url: string) => (e: Error) => {
  logger.error(`${url}`, e)
  return e
}

// check status on request
export function checkStatus(response: Response) {
  // logger.trace(response.url, { status: response.status, statusText: response.statusText })
  if (response.status >= 200 && response.status < 300) {
    return response.json()
  }
  logger.error(response.url, JSON.stringify(response))
  return {status: 500, response}
}

// parseJson
export function parseJSON<R = any>(response: Response) {
  if (response.status === 204 || response.status === 205) {
    return {status: 500}
  }
  return response as unknown as R
}

// any request
export function fetchRequest(url: string, method: string, params?: BodyInitType) {
  const headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  }

  // console.log('BASE URL', baseUrl)

  return fetch(baseUrl + url, {
    method,
    headers,
    mode: 'cors',
    // @ts-ignore
    cache: 'default',
    body: method === 'GET' ? null : params,
  })
    .then(async (r) => {
      // console.log('R', r)
      return checkStatus(r)
    })
    .then((response) => {
      return parseJSON(response)
    })
    .catch((error) => {
      logger.error(`${baseUrl + url}`, error)
      // console.log('ERROR', JSON.stringify(error))
      return error
    })
}

// not auth request
export function fetchRequestNotAuth(url: string, method: string, params?: BodyInitType) {
  const headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  }

  return fetch(baseUrlNotAuth + url, {
    method,
    headers,
    mode: 'cors',
    // @ts-ignore
    cache: 'default',
    body: method === 'GET' ? null : params,
  })
    .then(async (r) => {
      return checkStatus(r)
    })
    .then((response) => {
      return parseJSON(response)
    })
    .catch((error) => {
      logger.error('actual error', error)

      return error
    })
}

// not auth request
export function fetchRequestPublic(url: string, method: string, params?: BodyInitType) {
  const headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  }

  return fetch(baseUrlPublic + url, {
    method,
    headers,
    mode: 'cors',
    // @ts-ignore
    cache: 'default',
    body: method === 'GET' ? null : params,
  })
    .then(async (r) => {
      return checkStatus(r)
    })
    .then((response) => {
      return parseJSON(response)
    })
    .catch((error) => {
      logger.error('actual error', error)

      return error
    })
}

// auth request
export function fetchRequestAuth<R>(url: string, method: string, bearer: string, params?: BodyInitType) {
  const bearerHeader = `Bearer ${bearer}`
  const headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization: bearerHeader,
  }

  return fetch(baseUrlAuth + url, {
    method,
    headers,
    mode: 'cors',
    // @ts-ignore
    cache: 'default',
    body: method === 'GET' ? null : params,
  })
    .then(async (r) => checkStatus(r))
    .then((response) => {
      return parseJSON<R>(response) as R
    })
    .catch((error) => {
      return error
    })
}

export function fetchRequestCommon<R>(url: string, method: string, bearer: string, params?: BodyInitType, header: Record<string, unknown> = {}) {
  // console.log('#fetchRequestCommon.url', url)
  // console.log('#fetchRequestCommon.tokenUser', bearer)
  const bearerHeader = `Bearer ${bearer}`
  const headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization: bearerHeader,
    ...header,
  }
  return fetch(baseUrlCommon + url, {
    method,
    headers,
    mode: 'cors',
    // @ts-ignore
    // cache: 'default',
    body: method === 'GET' ? null : params,
  })
    .then(async (r) => checkStatus(r))
    .then((response) => {
      return parseJSON<R>(response) as R
    })
    .catch(onError(url))
}

export function fetchRequestCommonMessaging(url: string, method: string, bearer: string, params?: BodyInitType, header: Record<string, unknown> = {}) {
  const bearerHeader = `Bearer ${bearer}`
  const headers = {
    Accept: 'application/json',
    Authorization: bearerHeader,
    ...header,
  }
  return fetch(baseUrlCommon + url, {
    method,
    headers,
    mode: 'cors',
    // @ts-ignore
    cache: 'default',
    body: method === 'GET' ? null : params,
  })
    .then(async (r) => checkStatus(r))
    .then((response) => {
      return parseJSON(response)
    })
    .catch((error) => {
      return error
    })
}

// swr fetch get
export function fetchSwrRequestAuth(url: string, method: string, bearer: string, params?: BodyInitType) {
  const bearerHeader = `Bearer ${bearer}`
  const headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization: bearerHeader,
  }

  return fetch(baseUrlAuth + url, {
    method,
    headers,
    mode: 'cors',
    // @ts-ignore
    cache: 'default',
    body: method === 'GET' ? null : params,
  }).then((response) => {
    return response.json()
  })
}
