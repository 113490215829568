import { COMPANY_IMAGE_UPLOAD_LINK } from '../services/constants'
import { imageAppendUrl } from '../services/utils';

const company = {
    name: "",
    sector: "",
    linkedinUrl: "",
    websiteUrl: "",
    subtitle: "",
    company_avatar: "",
    company_avatar_image: "",
    company_avatar_extension: "",
    company_avatar_image_extension: "",
    background_image: '',
    company_background_image: '',
    background_image_extension: "",
    company_background_image_extension: "",
    rank: -1,
}

export default (state = company, action) => {
    var image;
    switch (action.type) {
        case 'companyName':
            return {
                ...state,
                name: action.payload,
            };
        case 'companyImg':
            if (action.payload.logo) {
                image = imageAppendUrl(COMPANY_IMAGE_UPLOAD_LINK, action.payload.logo)
            } else {
                image = action.payload.img_data
            }
            return {
                ...state,
                company_avatar_image: image,
                company_avatar_image_extension: action.payload.extension,
                company_avatar: image,
                company_avatar_extension: action.payload.extension,
            };
        case 'backImg':
            if (action.payload.backgroundImage) {
                image = imageAppendUrl(COMPANY_IMAGE_UPLOAD_LINK, action.payload.backgroundImage)
            } else {
                image = action.payload.img_data
            }
            return {
                ...state,
                background_image: image,
                background_image_extension: action.payload.extension,
                company_background_image: image,
                company_background_image_extension: action.payload.extension,
            };
        case 'companyData':
            return {
                ...state,
                name: action.payload.name,
                sector: action.payload.sector,
                linkedinUrl: action.payload.linkedinUrl,
                websiteUrl: action.payload.websiteUrl,
                subtitle: action.payload.subtitle,
                rank: action.payload.rank,
            };
        case 'companyLoad':
            return {
                ...state,
                name: action.payload.name,
                sector: action.payload.sector,
                rank: action.payload.rank,
                linkedinUrl: action.payload.linkedinUrl,
                websiteUrl: action.payload.websiteUrl,
                subtitle: action.payload.subtitle,
                background_image: imageAppendUrl(COMPANY_IMAGE_UPLOAD_LINK, action.payload.backgroundImage),
                company_avatar: imageAppendUrl(COMPANY_IMAGE_UPLOAD_LINK, action.payload.logo),
            }
        default:
            return state;
    }
}
