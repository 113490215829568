import {combineReducers} from 'redux'
import conversationsR from './messagesReducer'
import websocketR from './websocketReducer'
import userDetailsR from './userDetailsReducer'
import annuaireR from './annuaireReducer'
import companyR from './companyReducer'
import searchR from './searchReducer'
import postsR from './postsReducer'
import accountRegistrationSlice from './accountRegistrationSlice'
import userR from './userReducer'
import clubSlice from './clubSlice'
import apiR from './apiReducer'
import messagingSlice from '../shared/redux/messaging.slice'
import connexionStatusRedux from '../shared/redux/connexionStatus.slice'
import wordThemeSlice from '../shared/redux/wordThemeSlice'
import featureSlice from '../shared/redux/feature.slice'

export default combineReducers({
  messagingR: messagingSlice,
  connexionStatusRedux,
  wordTheme: wordThemeSlice,
  features: featureSlice,
  clubState: clubSlice,
  websocketR,
  conversationsR,
  userDetailsR,
  annuaireR,
  accountRegistrationSlice,
  companyR,
  searchR,
  postsR,
  userR,
  apiR,
})
