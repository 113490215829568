/* eslint-disable react/destructuring-assignment */
import queryString from 'query-string'
import React, {Component, MouseEventHandler} from 'react'
import {connect, ConnectedProps} from 'react-redux'
import {Redirect, RouteComponentProps, withRouter} from 'react-router-dom'
import {Dispatch} from 'redux'
import {getToken} from '../../actions/apiAction'
import {companyLoad} from '../../actions/companyAction'
import clubConfigApi, {ClubConfig} from '../../api/clubConfigApi'
import EmailSend from '../../component/login/password-reset/email-send'
import {Avatar} from '../../component/user'
import {userActions} from '../../reducers/userReducer'
import {RootState} from '../../redux/store'
import {logger} from '../../services/logger'
import loginApi from '../../shared/api/loginApi'
import {Feature} from '../../shared/hooks/useFeatures'
import {ConnexionState, connexionStatusActions} from '../../shared/redux/connexionStatus.slice'
import {featureSliceActions} from '../../shared/redux/feature.slice'
import {syncWordTheme} from '../../shared/redux/wordThemeSlice'
import {hostname} from '../../services/url'
import {HttpError, InvalidCredentialsError} from '../../shared/api/errors'

const mapStateToProps = (state: RootState) => ({
  isSignIn: state.connexionStatusRedux.isSignIn,
  club: state.clubState,
  config: state.apiR,
  wordTheme: state.wordTheme,
})

const mapDisptachToProps = (dispatch: Dispatch) => ({
  onConnexion: (connexionInfo: Omit<ConnexionState, 'isSignIn' | 'auths'>) => dispatch(connexionStatusActions.onConnexion(connexionInfo)),
  getToken: (payload: string) => dispatch(getToken(payload)),
  userLoad: (payload: Parameters<typeof userActions.userLoad>[0]) => dispatch(userActions.userLoad(payload)),
  // @ts-ignore
  syncWordTheme: () => dispatch(syncWordTheme()),
  companyLoad: (payload: unknown) => dispatch(companyLoad(payload)),
  setClubFeatures: (payload: Feature[]) => dispatch(featureSliceActions.setClubFeatures(payload)),
})

const connector = connect(mapStateToProps, mapDisptachToProps)

type LoginProps = ConnectedProps<typeof connector> & RouteComponentProps
type LoginState = {
  username: string
  password: string
  deviceType: 'web'
  password_forget: boolean
  cant_connect: boolean
  error_api: string
}
class Login extends Component<LoginProps, Partial<LoginState>> {
  constructor(props: LoginProps) {
    super(props)

    this.state = {
      username: process.env.REACT_APP_DEFAULT_LOGIN || '',
      password: process.env.REACT_APP_DEFAULT_PW || '',
      deviceType: 'web',
      password_forget: false,
      cant_connect: false,
      error_api: '',
    }

    this.handleChange = this.handleChange.bind(this)
    this.sendLogin = this.sendLogin.bind(this)
  }

  sendLogin: MouseEventHandler<HTMLButtonElement> = (ev) => {
    ev.preventDefault()
    if (this.state.username === '') {
      document.querySelector('#form_username')?.classList.add('is-invalid')
      if (this.state.password === '') {
        document.querySelector('#form_password')?.classList.add('is-invalid')
        return
      }
      return
    }
    if (this.state.password === '') {
      document.querySelector('#form_password')?.classList.add('is-invalid')
      return
    }

    ;(async () => {
      try {
        const res = await loginApi.login({
          username: this.state.username!,
          password: this.state.password!,
          deviceType: 'web',
        })

        const clubConfig = await clubConfigApi.getClubConfigs()
        const clubInfo = Object.values(clubConfig).find((c: ClubConfig) => c.subdomain_url === hostname)

        if (!clubInfo) {
          this.setState({error_api: "Ce nom de domaine n'est pas reconnu."})
          return
        }

        const currentDomainAuth = res.auth.find(({club}) => club.id === clubInfo.club_id)
        if (!currentDomainAuth) {
          this.setState({error_api: "Votre Organisation n'a pas été trouvé"})
          return
        }

        const {club, user, token, deviceId, account, company} = currentDomainAuth

        if (!club.enabled) {
          this.setState({error_api: "Votre Organisation MyTeamLink n'est pas encore activée."})
          return
        }

        if (!company.enabled) {
          this.setState({error_api: "Votre partenaire n'est pas activé."})
          return
        }

        if (!user.enabled) {
          this.setState({error_api: 'Veuillez confirmer votre enregistrement à MyTeamLink.'})
          return
        }

        if (!account.enabled) {
          this.setState({error_api: "Votre compte n'a pas encore été activé."})
          return
        }

        this.props.onConnexion({
          idUser: user.id,
          tokenUser: token,
          deviceId,
          user,
          club,
          account,
        })
        this.props.setClubFeatures(club.features.map(({featureKey}) => featureKey))

        this.props.syncWordTheme()
        this.props.getToken(token)

        this.props.userLoad({
          ...user,
          deviceId,
        })
        this.props.companyLoad(user.company)
        localStorage.removeItem('Authorization')
        localStorage.removeItem('User')
        localStorage.removeItem('Company')
        localStorage.setItem('Authorization', JSON.stringify(token))
        localStorage.setItem('User', JSON.stringify(user))
        localStorage.setItem('Device', JSON.stringify(deviceId))
        localStorage.setItem('Company', JSON.stringify(user.company))
        localStorage.setItem('Club', JSON.stringify(club))
        // console.log('Here on login');
        setTimeout(() => {
          this.props.history.push('/')
        }, 200)
        this.setState({username: '', password: ''})
      } catch (e) {
        logger.debug('Login data send ERROR: ', e)
        if (e instanceof InvalidCredentialsError) {
          this.setState({error_api: 'Vos identifiants sont incorrects, veuillez essayer de nouveau'})
          document.querySelector('#form_username')?.classList.add('is-invalid')
          document.querySelector('#form_password')?.classList.add('is-invalid')
        } else if (e instanceof HttpError && e.code === 400) {
          this.setState({error_api: "Votre compte n'est associé à aucun club."})
          document.querySelector('#form_username')?.classList.add('is-invalid')
          document.querySelector('#form_password')?.classList.add('is-invalid')
        } else {
          this.setState({error_api: 'Oups, un problème à eu lieu, veuillez réessayer plus tard ou contacter votre administrateur de site.'})
        }
      }
    })()
  }

  handleChange = (name: 'username' | 'password', data: string) => {
    this.setState((prev) => {
      return {
        ...prev,
        [name]: data,
      }
    })
    document.querySelector('#form_username')?.classList.remove('is-invalid')
    document.querySelector('#form_password')?.classList.remove('is-invalid')
  }

  componentDidMount() {
    // if (this.props.config.headers.Authorization && this.props.config.headers.Authorization !== "") {
    //     this.props.history.push('/');
    // }
    // @ts-ignore
    this.setState({username: queryString.parse(this.props.location.search).username || process.env.REACT_APP_DEFAULT_LOGIN || ''})
  }

  render() {
    // @ts-ignore
    return (
      <div className="header-wrapper login-header d-flex vh-100 w-100 align-items-center justify-content-center bg-welcome">
        <div className="color-overlay" />
        {this.state.password_forget && (
          <EmailSend
            onReturn={() => {
              this.setState({password_forget: false})
            }}
          />
        )}
        {!this.state.password_forget && (
          <div className="container">
            <div className="row m-h-100 ">
              <div className="col-md-4 m-auto">
                <div className="card shadow-lg login-card p-2">
                  <div className="card-body p-0 m-0 text-center">
                    <div className="position-absolute" style={{top: -40, left: 'calc(50% - 40px)'}}>
                      <Avatar img={this.props.club.club_image} alt={this.props.club.club_name && this.props.club.club_name[0]} size="md" />
                    </div>
                    <div className="text-left p-2 mt-2">
                      {this.state.cant_connect && (
                        <div>
                          <p className="text-center">Vous ne pouvez pas vous connecter tant que vous ne vous êtes pas inscrit.</p>
                          <p className="text-center">Pour vous inscrire, utilisez le lien que vous avez reçu par email.</p>
                        </div>
                      )}
                      {this.state.error_api && <p className="text-danger text-center mt-1">{this.state.error_api}</p>}
                      <form>
                        <div className="form-group floating-label">
                          <label htmlFor="form_username" className="required">
                            Identifiant
                          </label>
                          <input
                            value={this.state.username}
                            type="email"
                            id="form_username"
                            name="form[username]"
                            required
                            placeholder="Identifiant"
                            className="form-control"
                            onChange={(e) => this.handleChange('username', e.target.value)}
                          />
                        </div>
                        <div className="form-group floating-label">
                          <label htmlFor="form_password" className="required">
                            Mot de passe
                          </label>
                          <input
                            value={this.state.password}
                            type="password"
                            id="form_password"
                            name="form[password]"
                            required
                            placeholder="Mot de passe"
                            className="form-control"
                            onChange={(e) => this.handleChange('password', e.target.value)}
                          />
                        </div>
                        <div className="form-group">
                          <button type="submit" className="btn btn-primary btn-block btn-lg" onClick={(e) => this.sendLogin(e)}>
                            Se connecter
                          </button>
                        </div>
                        <div className="text-right p-t-10">
                          <p className="text-underline" style={{cursor: 'pointer'}} onClick={() => this.setState({password_forget: true})}>
                            Mot de passe oublié ?
                          </p>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    )
  }
}

// @ts-ignore
export default withRouter(connector(Login))
