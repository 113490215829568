const annuaire = {
    active_selector: {},
    companies: []
}

export default (state = annuaire, action) => {
    switch (action.type) {
        case 'loadCompanies':
            return {
                ...state,
                companies: action.payload,
            }
        case 'setActiveSelector':
            return {
                ...state,
                active_selector: action.payload
            }
        default:
            return state;
    }
}