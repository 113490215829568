import React from 'react'
import {Redirect, Route} from 'react-router-dom'
import {Feature, useFeatures} from '../shared/hooks/useFeatures'

type PrivateRouteProps = {
  authorized: number
  component: React.FC<any> | React.ComponentClass<any>
  rank: number
  path: string
  exact: boolean
  features?: Feature[]
}
const PrivateRoute = ({component: Component, features = [], ...rest}: PrivateRouteProps) => {
  const {hasFeatures} = useFeatures()
  if (rest.authorized === -1) {
    return <div />
  }
  if (!hasFeatures(...features)) {
    return <div />
  }
  if (rest.authorized >= rest.rank) {
    return <Route {...rest} render={(props) => <Component {...props} />} />
  }
  if (rest.authorized === 1) {
    return <Route {...rest} render={() => <Redirect to="/annuaire" />} />
  }
  return <Route {...rest} render={() => <Redirect to="/login" />} />
}

export default PrivateRoute
