import {AxiosRequestConfig} from 'axios'
import React, {ChangeEventHandler, useCallback, useState} from 'react'
import {useSelector} from 'react-redux'
import {useAppSelector} from '../../../reducers/hooks'
import api from '../../../services/api'
import {API_PASSWORD_FORGOT_EMAIL} from '../../../services/constants'
import {logger} from '../../../services/logger'

const qs = require('querystring')

type EmailSendProps = {
  onReturn: () => void
}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
type EmailSendState = {
  email: string
  email_sent: boolean
}
const ResetPasswordForm: React.FC<EmailSendProps> = ({onReturn}) => {
  const club = useAppSelector((state) => state.clubState)
  const config = useSelector((state: {apiR: AxiosRequestConfig | undefined}) => state.apiR)
  const [email, setEmail] = useState<string>('')
  const [sent, setSent] = useState(false)

  const onEmailChange: ChangeEventHandler<HTMLInputElement> = useCallback((e) => {
    document.getElementById('form_email')?.classList.remove('is-invalid')
    setEmail(e.target.value)
  }, [])

  const sendEmail = useCallback(() => {
    if (email === '') {
      document.getElementById('form_email')?.classList.add('is-invalid')
      return
    }
    setSent(true)
    api.post(API_PASSWORD_FORGOT_EMAIL, qs.stringify({email, club_id: club.club_id}), config).catch((err) => {
      logger.debug('Forgot password email send ERROR: ', err)
    })
  }, [email])

  return (
    <div className="container">
      <div className="row m-h-100 ">
        <div className="col-md-4 m-auto">
          <div className="card shadow-lg ">
            <div className="card-body ">
              <h4 className="text-center py-2">Réinitialisation du mot de passe</h4>
              <form name="form" method="post">
                {!sent ? (
                  <div className="form-group">
                    {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                    <label>Adresse mail</label>
                    <div className="input-group">
                      <input
                        type="email"
                        id="form_email"
                        value={email}
                        name="mail"
                        required
                        placeholder="Votre adresse mail"
                        className="form-control form-control-prepended"
                        onChange={onEmailChange}
                      />
                    </div>
                    <p className="small pt-1">Un e-mail vous sera envoyé pour réinitialiser votre mot de passe.</p>
                    <div className="form-group">
                      <button type="submit" className="btn btn-primary btn-block btn-lg" onClick={sendEmail}>
                        Envoyer
                      </button>
                      <button type="button" className="btn btn-secondary btn-block btn-lg" onClick={onReturn}>
                        Retour
                      </button>
                    </div>
                  </div>
                ) : (
                  <p className="text-center">Si vous possédez un compte, un e-mail vous a été envoyé pour réinitialiser votre mot de passe !</p>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
//
// class EmailSend extends Component<EmailSendProps, Partial<EmailSendState>> {
//     constructor(props: EmailSendProps) {
//         super(props);
//         this.state = {
//             email: "",
//             email_sent: false,
//         }
//         this.handleChange = this.handleChange.bind(this);
//         this.sendMail = this.sendMail.bind(this);
//     }
//
//     handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
//         document.getElementById('form_email')?.classList.remove('is-invalid');
//         this.setState({email: e.target.value});
//     }
//
//     sendMail: MouseEventHandler<HTMLButtonElement> = (e) => {
//         e.preventDefault();
//         if (this.state.email === "") {
//             document.getElementById('form_email')?.classList.add('is-invalid');
//             return;
//         }
//         api.post(API_PASSWORD_FORGOT_EMAIL, qs.stringify({email: this.state.email, club_id: this.props.club.club_id}), this.props.config)
//         .catch(err => {
//             logger.debug("Forgot password email send ERROR: ", err);
//         })
//         this.setState({email_sent: true});
//     }
//
//     onReturn: MouseEventHandler<HTMLButtonElement> = (e) => {
//         e.preventDefault();
//         const {onReturn} = this.props;
//         onReturn();
//     }
//
//     render() {
//         return (
//             <div className="container">
//                 <div className="row m-h-100 ">
//                     <div className="col-md-4 m-auto">
//                         <div className="card shadow-lg ">
//                             <div className="card-body ">
//                                 <h4 className="text-center py-2">Réinitialisation du mot de passe</h4>
//                                 <form name="form" method="post">
//                                     {
//                                         !this.state.email_sent ?
//                                         <div className="form-group">
//                                             <label>Adresse mail</label>
//                                             <div className="input-group">
//                                                 <input type="email" id="form_email" value={this.state.email} name="mail" required placeholder="Votre adresse mail" className="form-control form-control-prepended" onChange={this.handleChange}/>
//                                             </div>
//                                             <p className="small pt-1">Un e-mail vous sera envoyé pour réinitialiser votre mot de passe.</p>
//                                             <div className="form-group">
//                                                 <button type="submit" className="btn btn-primary btn-block btn-lg" onClick={this.sendMail}>Envoyer</button>
//                                                 <button type="button" className="btn btn-secondary btn-block btn-lg" onClick={this.onReturn}>Retour</button>
//                                             </div>
//                                         </div>
//                                         :
//                                         <p className="text-center">Si vous possédez un compte, un e-mail vous a été envoyé pour réinitialiser votre mot de passe !</p>
//                                     }
//                                 </form>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         );
//     }
// }

export default ResetPasswordForm
