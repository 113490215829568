export const searchResults = payload => ({
    type: 'searchResults',
    payload
})

export const searchFilter = payload => ({
    type: 'searchFilter',
    payload
})

export const showDrawerSearch = payload => ({
    type: 'showDrawerSearch',
    payload
})