import React, { Component } from 'react';
import { Avatar } from '../../../component/user';
import { getLinks } from '../../../services/utils';
import GroupDataSkeleton from './group-data-skeleton';
import {logger} from "../../../services/logger";

class GroupData extends Component {
    render() {
        if (!this.props.data) {
            // ! Code GD-001
            logger.debug('DEBUG -- Une erreur est survenue... GD-001');
            return <GroupDataSkeleton />
        }

        return (
            <div className="card">
                <div className="img-timeline" style={{backgroundImage: `url(${this.props.data.background_image})`}}></div>
                <div className="d-flex align-items-center justify-content-center text-center flex-column w-100 mt-n4">
                    <Avatar alt={this.props.data.name[0]} img={this.props.data.image} size="md"/>
                    <h4 className="text-uppercase mt-1 text-center">{this.props.data.name}</h4>
                    <p className="card-subtitle text-muted text-center">{this.props.data.sector && this.props.data.sector.libelle}</p>
                    {
                        this.props.data.websiteUrl &&
                        <a href={this.props.data.websiteUrl} target="_blank" rel="noopener noreferrer" className="text-center">
                            <p className="text-muted">{this.props.data.websiteUrl.replace('https://', '').replace('http://', '')}</p>
                        </a>
                    }
                    {
                        this.props.data.linkedinUrl && this.props.data.linkedinUrl.match(/[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/) &&
                        <a href={getLinks(this.props.data.linkedinUrl)} target="_blank" rel="noopener noreferrer" className="mt-n1">
                            <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="linkedin-in" className="icon text-primary icon-hover" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"></path></svg>
                        </a>
                    }
                </div>
                <div className="card-body">
                    {
                        this.props.data.subtitle &&
                        <p className="card-text text-center" style={{whiteSpace: 'pre-line'}}>
                            {this.props.data.subtitle}
                        </p>
                    }
                </div>
            </div>
        );
    }
}

export default GroupData;
