/**
 * Split an array based on condition
 * @param {A[]} array the array to split
 * @param {(o: A) => boolean} cond the condition for the split
 * @returns {[any[], any[]]} a tuple of [truthy, falsy] arrays
 */
export const partition = <A>(array: A[], cond: (o: A) => boolean) => {
  const truthy = []
  const falsy = []
  for (let i = 0; i < array.length; i++) {
    const o = array[i]
    if (cond(o)) truthy.push(o)
    else falsy.push(o)
  }
  return [truthy, falsy]
}

type KeysOfType<T, U> = {
  [P in keyof T]: T[P] extends U ? P : never
}[keyof T]

export const arrayToObject = <T, KT extends number | string, K extends KeysOfType<T, KT>>(arr: T[], prop: K) => {
  return arr.reduce((acc, v: T) => {
    const propVal = v[prop]
    // ignoring type warning cause we should actually be secure in our prop value type
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    acc[propVal] = v
    return acc
  }, {} as Record<KT, T>)
}

export const onlyUnique =
  <T>(isEqual: (a: T, b: T) => boolean) =>
  (a: T, index: number, self: Array<T>) =>
    self.findIndex((b) => isEqual(a, b)) === index

/**
 * Remove duplicate items from an array, using the isEqual function to compare items
 */
export const unique = <T>(array: T[], isEqual: (a: T, b: T) => boolean): T[] => array.filter(onlyUnique(isEqual))
