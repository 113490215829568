import React, { Component } from 'react';
import UserData from './user-data';
import GroupData from '../../../component/groups/group-details/group-data';
import { connect } from 'react-redux';
import UserDataSkeleton from './user-data-skeleton';
import GroupDataSkeleton from '../../../component/groups/group-details/group-data-skeleton';
import {logger} from "../../../services/logger";

class UserDetails extends Component {
    shouldComponentUpdate() {
        return this.props.onReload;
    }

    render() {
        if (!this.props.user || !this.props.user.company) {
            // Code US-003
            logger.debug('DEBUG -- Une erreur est survenue... Code US-003');
            return (
                <>
                    <div className="card pt-2 mb-2">
                        <UserDataSkeleton />
                    </div>
                    <GroupDataSkeleton />
                </>
            )
        }
        return (
            <>
                <div className="card pt-2 mb-2">
                    <UserData data={this.props.user} rank={this.props.user.company.rank} where="profile" />
                </div>
                <GroupData data={this.props.user.company}/>
            </>
        );
    }
}

const mapStateToProps = state => ({
    user: state.userDetailsR,
})

export default connect(mapStateToProps)(UserDetails);
