import React from 'react';
import UserIdentifier from '../../../component/user/avatar/identifier';
import ActionMenu from '../../../component/form/button/post_action_menu';
import ClubIdentifier from '../../../component/user/avatar/club-identifier';

function HeaderPost (props) {
    return (
        <div className="cards_author d-flex align-items-center w-100">
            {
                props.user.firstName ?
                <UserIdentifier user={props.user} size="md" date={props.date}/>
                :
                <ClubIdentifier user={props.user} size="md" date={props.date}/>
            }
            {props.post.pinned
                ?
                <><div className="ml-auto badge badge-primary p-1">
                    Information
                </div><div>
                        <ActionMenu post_report={true} post={{ id: props.post_id }} returnAction={(data) => props.returnAction(data)} user_id={props.user.firstName ? props.user.id : -1} />
                    </div></>
                :
                <div className="col-3 p-0 text-right text-primary d-flex justify-content-end align-items-center">
                    <ActionMenu post_report={true} post={{id: props.post_id}} returnAction={(data) => props.returnAction(data)} user_id={props.user.firstName ? props.user.id : -1}/>
                </div>
            }
        </div>
    );
}

export default (HeaderPost);
