export const getToken = (payload: string) => {
  return {
    type: 'token',
    payload,
  }
}

export const removeToken = () => ({
  type: 'removeToken',
})
