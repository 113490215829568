import React, { Component } from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import Box from '@material-ui/core/Box';
import { Avatar } from '../../../component/user';
import {logger} from "../../../services/logger";

class Signup_3 extends Component {
    render() {
        if (!this.props.userR) {
            // ! Code SU-003
            logger.debug('DEBUG -- Une erreur est survenue... Code SU-003');
            return <h1>Une erreur est survenue lors du chargement de cette page... Code SU-003</h1>
        }

        return (
            <div className="justify-content-center">
                <Box display="flex" justifyContent="center" p={1}>
                    <Avatar img={this.props.userR.avatar} alt={((this.props.userR.firstName && this.props.userR.lastName) ? this.props.userR.firstName[0] + this.props.userR.lastName[0] : '')} size="md" />
                </Box>
                <div className="text-center py-2">
                    <p className="text-center text-primary"><strong>{this.props.userR.firstName + ' ' + this.props.userR.lastName}</strong></p>
                    <p className="text-muted" style={{width:200, margin: "auto"}}>{this.props.userR.position}</p>
                    <p className="mt-2"><a href={"https://" + this.props.companyR.websiteUrl}>{this.props.companyR.websiteUrl}</a></p>
                    {this.props.canLogin === true &&
                        <Link to="/"><button type="button" className="btn btn-outline-primary mt-2 py-1">Je me connecte</button></Link>
                    }
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    ...state
});

export default connect(mapStateToProps)(Signup_3);
