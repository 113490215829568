import React, {useMemo} from 'react'
import {useAppSelector} from '../../../shared/redux/reduxHooks'
import {selectMessaging} from '../../../shared/redux/messaging.slice'
import {Conv} from '../../../shared/api/api.types'
import {ConvCard} from '../index'
import {CONVERSATION_PLACEHOLDER, NEW_CONVERSATION_INSTRUCTIONS} from '../../../services/constants'
import {sortByLatestMessageDesc} from '../../../shared/utils/sortByLatestMessageDesc'

export const ConvList = ({maxHeight}: {maxHeight: number}) => {
  const {convs, timestamp} = useAppSelector(selectMessaging)

  const conversations = useMemo(() => {
    const conversationsData: Conv[] = Object.values(convs)

    if (conversationsData.length > 0) {
      const sorted = conversationsData.sort(sortByLatestMessageDesc)
      return {
        data: sorted,
        timestamp,
      }
    }
    return {
      data: [] as Conv[],
      timestamp: 0 as number,
    }
  }, [convs])

  return (
    <div className="tab-content" style={{height: 'auto', maxHeight, overflowX: 'hidden'}}>
      <div role="tabpanel" id="list-all" className="tab-pane fade active show mt-n2" aria-labelledby="conversation-list">
        {conversations.data.map((conv) => (
          <ConvCard convId={conv.id} conv={conv} key={`c-${conv.id}`} />
        ))}
        {conversations.data.length === 0 && (
          <div className="container d-flex align-items-center flex-column pt-2">
            <p className="text-center text-muted">{CONVERSATION_PLACEHOLDER}</p>
            <p className="text-center text-muted">{NEW_CONVERSATION_INSTRUCTIONS}</p>
          </div>
        )}
      </div>
    </div>
  )
}
