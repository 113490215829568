import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import type {RootState} from '../redux/store'

export type AccountRegistrationState = {
  token?: string
}

const initialState: AccountRegistrationState = {
  token: undefined,
}

const accountRegistrationSlice = createSlice({
  name: 'accountRegistration',
  initialState,
  reducers: {
    setToken: (state, action: PayloadAction<string | undefined>) => {
      state.token = action.payload
    },
  },
})

export const accountRegistrationActions = accountRegistrationSlice.actions

export const selectRegistrationAuthToken = (state: RootState) => state.accountRegistrationSlice.token
export default accountRegistrationSlice.reducer
