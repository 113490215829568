import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import type {AccountAuth, LoginClubRes, LoginUser} from '../api/loginApi'
import {LoginResAccount} from '../api/loginApi'
import type {RootState} from '../../bridge/reduxStore'
import {ClubInfo} from '../api/clubApi'

export type ConnexionState = {
  isSignIn: boolean
  idUser?: LoginUser['id']
  tokenUser: null | string
  deviceId?: number
  user?: Omit<LoginUser, 'accounts'>
  account?: LoginResAccount
  club?: LoginClubRes
  auths: AccountAuth[]
  filename_sponsor?: string
}
const initialState: ConnexionState = {
  isSignIn: false,
  idUser: undefined,
  tokenUser: null,
  deviceId: undefined,
  user: undefined,
  account: undefined,
  club: undefined,
  auths: [],
  filename_sponsor: undefined,
}

const connexionSlice = createSlice({
  name: 'connexion',
  initialState,
  reducers: {
    onConnexion: (state, {payload}: PayloadAction<Omit<ConnexionState, 'isSignIn' | 'auths'>>) => {
      return {
        auths: [],
        ...payload,
        isSignIn: true,
      }
    },
    updateAuthToken: (state, {payload}: PayloadAction<string>) => {
      state.tokenUser = payload
    },
    onDisconnect: () => {
      return {
        ...initialState,
        isSignIn: false,
      }
    },
    updateUserClub: (state, {payload}: PayloadAction<ClubInfo>) => {
      // @ts-ignore
      state.club = {
        ...state.club,
        ...payload,
      }
    },
    saveAuths: (state, {payload}: PayloadAction<AccountAuth[]>) => {
      state.auths = payload
    },
  },
})

export const connexionStatusActions = connexionSlice.actions

export const selectConnexionStatus = (state: RootState) => state.connexionStatusRedux
export const selectConnectedState = (state: RootState) => state.connexionStatusRedux as Required<ConnexionState>

export default connexionSlice.reducer
