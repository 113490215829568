import React, { Component } from 'react';
import { IconButton } from '@material-ui/core';
import UserData from '../../../component/user/user-details/user-data';

class GroupMembers extends Component {
    constructor() {
        super();
        this.state = {
            member: 0,
        };
        this.changeMember = this.changeMember.bind(this);
    }

    changeMember = (action) => {
        this.setState({member: this.state.member + action})
    }

    render() {
        return (
            <div className="card mt-2">
                <div className="container">
                    <div className="pt-1"></div>
                    <div className="row d-flex align-items-center">
                        <div className="col-2 d-flex align-items-center justify-content-center">
                            {
                                this.state.member > 0 &&
                                <IconButton size="small" onClick={()=> this.changeMember(-1)} style={{zIndex: 1}}>
                                    <svg version="1.1" className="icon text-primary m-1" style={{width: 35, height: 35}} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 129 129" xlink="http://www.w3.org/1999/xlink" enableBackground="new 0 0 129 129"><g><path fill="currentColor" d="m88.6,121.3c0.8,0.8 1.8,1.2 2.9,1.2s2.1-0.4 2.9-1.2c1.6-1.6 1.6-4.2 0-5.8l-51-51 51-51c1.6-1.6 1.6-4.2 0-5.8s-4.2-1.6-5.8,0l-54,53.9c-1.6,1.6-1.6,4.2 0,5.8l54,53.9z"/></g></svg>
                                </IconButton>
                            }
                        </div>
                        <div className="col-8 p-0">
                            <UserData data={this.props.data[this.state.member]} where={this.props.data[this.state.member]} rank={this.props.rank} />
                        </div>
                        <div className="col-2 d-flex align-items-center justify-content-center">
                            {
                                this.state.member < this.props.data.length - 1 &&
                                <IconButton size="small" edge="end" onClick={() => this.changeMember(1)}>
                                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 129 129"  className="icon text-primary m-1" style={{width: 35, height: 35}} xlink="http://www.w3.org/1999/xlink" enableBackground="new 0 0 129 129"><g><path fill="currentColor" d="m40.4,121.3c-0.8,0.8-1.8,1.2-2.9,1.2s-2.1-0.4-2.9-1.2c-1.6-1.6-1.6-4.2 0-5.8l51-51-51-51c-1.6-1.6-1.6-4.2 0-5.8 1.6-1.6 4.2-1.6 5.8,0l53.9,53.9c1.6,1.6 1.6,4.2 0,5.8l-53.9,53.9z"/></g></svg>
                                </IconButton>
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default GroupMembers;
