import React from 'react';
import { Avatar } from '..';
import { Divider } from '@material-ui/core';

const ProfileModifySkeleton = () => {
    return (
        <div id="modalUserModify" className="modal fade" tabIndex="-1" role="dialog" aria-labelledby="modal_edit_profile" aria-hidden="true">
                <div className="modal-dialog modal-lg m-auto modal_new_feed modal-dialog-centered" role="document">
                    <div className="modal-content rounded" style={{borderRadius: 0.8 + "rem", backgroundColor: "transparent"}}>
                        <div style={{height: 100, backgroundColor: '#a3a3a3'}}></div>
                        <div className="modal-body px-3 pb-3 bg-white">
                            <form>
                                <div className="profile_company position-relative mb-2">
                                    <div className="row">
                                        <div className="col-md-3 mt-n4">
                                            <Avatar size="md" />
                                            <div className="mx-auto mt-2" style={{backgroundColor: '#00000030', width: 60, height: 15}}></div>
                                        </div>
                                        <div className="col-md-9 px-4">
                                            <div className="w-100 mt-2" style={{backgroundColor: '#00000030', height: 25}}></div>
                                            <div className="w-100 mt-2" style={{backgroundColor: '#00000030', height: 25}}></div>
                                            <div className="w-100 mt-2" style={{backgroundColor: '#00000030', height: 25}}></div>
                                            <div className="w-100 mt-2" style={{backgroundColor: '#00000030', height: 25}}></div>
                                        </div>
                                    </div>
                                </div>
                                <Divider variant="middle" component="div"/>
                                <div className="profile_user position-relative pt-3">
                                    <div className="row">
                                        <div className="col-sm-3">
                                            <Avatar size="md" />
                                            <div className="mx-auto mt-2" style={{backgroundColor: '#00000030', width: 60, height: 15}}></div>
                                            <div className="mx-auto mt-2" style={{backgroundColor: '#00000030', width: 80, height: 15}}></div>
                                        </div>
                                        <div className="col-sm-9 px-4">
                                            <div className="w-100 mt-2" style={{backgroundColor: '#00000030', height: 25}}></div>
                                            <div className="w-100 mt-2" style={{backgroundColor: '#00000030', height: 25}}></div>
                                            <div className="w-100 mt-2" style={{backgroundColor: '#00000030', height: 25}}></div>
                                            <div className="w-100 mt-2" style={{backgroundColor: '#00000030', height: 25}}></div>
                                            <div className="w-100 mt-2" style={{backgroundColor: '#00000030', height: 25}}></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="text-right pr-3 mt-3">
                                    <div className="ml-auto" style={{backgroundColor: '#00000030', width: 60, height: 35}}></div>
                                </div>
                                <small className="text-muted">Erreur U-001</small>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
    );
};

export default ProfileModifySkeleton;