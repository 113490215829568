/* eslint-disable no-underscore-dangle */
import {logger} from '../../services/logger'
import {socketUrl} from '../../services/url'
import SharedAsyncTask from '../utils/SharedAsyncTask'
import {RetryHandler} from '../utils/RetryHandler'
import MessagingEvent from './messagingEvent'

const noop = () => {}

const BACKOFF_EXPANSION_LIMIT = 15 * 1000 // retries exponentially until it reaches this interval limit

export type SocketMessage = {
  type: MessagingEvent
  data: unknown
}

export type ChatSocketListeners<M> = {
  onConnected: () => void
  onMessage: (eventMessage: M) => void
  onError: (e: Error | undefined) => void
  onConnectionInterrupt: () => void
  onClosed: () => void
}

let instanceNumber = 0

export class ChatSocket<M> {
  private _socket: WebSocket | undefined

  private isDisconnected = false

  private accountId?: number

  private token?: string

  private deviceId?: number

  private connectionTask = new SharedAsyncTask()

  private instanceNumber = 0

  private listeners: ChatSocketListeners<M> = {
    onConnected: noop,
    onMessage: noop,
    onError: noop,
    onConnectionInterrupt: noop,
    onClosed: noop,
  }

  private retryHandler = new RetryHandler((retries) => Math.min(BACKOFF_EXPANSION_LIMIT, 2 ** (retries / 2) * 1000))

  constructor() {
    this.instanceNumber = instanceNumber++
  }

  private isConnecting() {
    this.connectionTask.isRunning()
  }

  // Called whenever the state changes in order to use latest user info for connecting.
  updateState(accountId: number, deviceId: number, token: string, listeners: Partial<ChatSocketListeners<M>>) {
    this.accountId = accountId
    this.deviceId = deviceId
    this.token = token
    this.listeners = {
      ...this.listeners,
      ...listeners,
    }
  }

  public get isConnected() {
    return this._socket !== undefined
  }

  public get socket() {
    return this._socket
  }

  async connect() {
    logger.debug(`ChatSocket#connect()`)

    // if already connected, ignore
    if (this.isConnected) {
      if (this._socket) {
        logger.debug(`ChatSocket#socket already connected`)
        return Promise.resolve()
      }
      if (!this.isConnecting) {
        logger.debug(`ChatSocket#connect inconsistent states between ChatSocket.isConnected, ChatSocket.socket and ChatSocket.isConnecting`)
      }
    }

    return this.connectionTask.shared(() => {
      logger.debug(`ChatSocket#connecting ${socketUrl}?login=${this.accountId}.${this.deviceId}&password=${this.token}`)

      this.isDisconnected = false
      const sock = new WebSocket(`${socketUrl}?login=${this.accountId}.${this.deviceId}&password=${this.token}`)

      let resolve: (r: PromiseLike<void> | void) => void
      let reject: (reason?: any) => void
      const p = new Promise<void>((resolver, rejector) => {
        resolve = resolver
        reject = rejector
      })
      sock.onopen = () => {
        this.retryHandler.clear()
        if (this.isDisconnected) {
          sock.close()
          logger.debug(`ChatSocket#${this.instanceNumber}: user requested disconnecting before completely opened.`)
          resolve()
          return
        }
        logger.debug(`ChatSocket#${this.instanceNumber}: connexion opened`)

        this._socket = sock
        if (resolve) resolve()

        this.listeners.onConnected()
      }
      sock.onclose = () => {
        this._socket = undefined
        resolve()

        // if user did not trigger a disconnect
        if (!this.isDisconnected) {
          this.listeners.onConnectionInterrupt()
          const retryIn = this.retryHandler.doRetry(() => this.connect())
          logger.debug(`ChatSocket${this.instanceNumber}: Unexpected disconnexion, retrying in ${retryIn} ms`)
          return
        }

        this.listeners.onClosed()
      }
      sock.onerror = () => {
        logger.debug(`ChatSocket#${this.instanceNumber}: error from websockets`)

        this.listeners.onError(new Error('Unknown error from websockets'))
      }
      sock.onmessage = (ev) => {
        console.log('on socket ev', ev)
        const message = JSON.parse(ev.data)
        this.listeners.onMessage(message)
      }
      return p
    })
  }

  disconnect() {
    this.isDisconnected = true
    this.retryHandler.clear()
    if (this.isConnected) {
      logger.debug(`ChatSocket#${this.instanceNumber}: connexion closed`)
      this._socket?.close()
      this._socket = undefined
    }
  }
}
