
export const fileReaderToBase64 = (
  files: File[],
  cb: (asset: {image: string | ArrayBuffer | null; image_ext: string; image_name: string}) => void
) => {
  const reader = new FileReader()
  const file = files[0]
  let extension = file.name.split('.')[1]
  if (!extension) {
    extension = ''
  }
  reader.readAsDataURL(file)
  reader.onload = () => {
    cb({image: reader.result, image_ext: extension, image_name: file.name})
  }
}
