import React from 'react';
import { Menu, MenuItem, IconButton } from '@material-ui/core';
import {connect} from 'react-redux';
import {logger} from "../../../services/logger";

function ActionMenu(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);

    function handleClick(event) {
        setAnchorEl(event.currentTarget);
    }

    function handleClose() {
        setAnchorEl(null);
    }

    return (
        <div className="flex-column">
            <IconButton size="medium" onClick={handleClick}>
                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="ellipsis-v" className="icon text-primary" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 512"><path fill="currentColor" d="M96 184c39.8 0 72 32.2 72 72s-32.2 72-72 72-72-32.2-72-72 32.2-72 72-72zM24 80c0 39.8 32.2 72 72 72s72-32.2 72-72S135.8 8 96 8 24 40.2 24 80zm0 352c0 39.8 32.2 72 72 72s72-32.2 72-72-32.2-72-72-72-72 32.2-72 72z"></path></svg>
            </IconButton>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
                {
                    props.user_general && props.user_id === props.user_general.id &&
                    <div>
                        <MenuItem onClick={() => {
                            props.returnAction("modify");
                            handleClose();
                        }}>Modifier</MenuItem>
                        <MenuItem onClick={() => {
                            props.returnAction("erase");
                            handleClose();
                        }}>Supprimer</MenuItem>
                    </div>
                }
                {
                    props.user_general && props.club.club_contact_email ?
                    <a href={"mailto:" + props.club.club_contact_email + "?body=L'utilisateur " + props.user_general.firstName + " " + props.user_general.lastName + " a signalé le " + (props.post_report ? ("post nº" + props.post.id) : ("commentaire nº" + props.comment.id + " du post nº" + props.post.id)) + "."} target="_blank" rel="noopener noreferrer">
                        <MenuItem onClick={handleClose}>Signaler</MenuItem>
                    </a>
                    :
                    props.club.club_contact_email ?
                    <a href={'mailto:' + props.club.club_contact_email} target='_blank' rel="noopener noreferrer">
                        <MenuItem onClick={handleClose}>Signaler</MenuItem>
                    </a>
                    :
                    // ! Code CbE-001
                    <p className="mx-2 mb-0" onLoad={logger.debug('DEBUG -- Une erreur est survenue... Code CbE-001')}>Erreur CbE-001</p>
                }
            </Menu>
        </div>
    );
}

const mapStateToProps = state => ({
    user_general: state.userR,
    club: state.clubState,
});

export default connect(mapStateToProps)(ActionMenu);
