import React, {useEffect, useMemo, useRef, useState} from 'react'
import {logger} from '../../../services/logger'
import {markConversationRead, messagingActions, selectConv, selectMessaging} from '../../../shared/redux/messaging.slice'
import {Avatar} from '../../user'
import Message from './Message'
import {useAppDispatch, useAppSelector} from '../../../reducers/hooks'
import {selectConnectedState} from '../../../shared/redux/connexionStatus.slice'
import {extractConversationMetadata} from '../../../shared/utils/extractConversationMetadata'
import {urlImageUser} from '../../../services/url'

const Conversation = ({maxHeight}: {maxHeight: number}) => {
  const dispatch = useAppDispatch()
  const messagesEndRef = useRef<HTMLDivElement | null | undefined>()

  const {account} = useAppSelector(selectConnectedState)
  const {accounts} = useAppSelector(selectMessaging)

  const messaging = useAppSelector(selectMessaging)
  const {activeConvId: convId} = messaging

  const [height, setHeight] = useState(maxHeight)
  const conversation = convId ? useAppSelector(selectConv(convId)) : undefined

  useEffect(() => {
    setHeight(maxHeight)
  }, [maxHeight])

  useEffect(() => {
    if (convId) {
      messagesEndRef.current?.scrollIntoView({behavior: 'smooth', block: 'end'})
    }
  }, [convId, conversation?.messages.length])

  useEffect(() => {
    if (convId) {
      dispatch(markConversationRead(convId))
    }
  }, [convId, conversation?.messages.length])

  if (!conversation) {
    // ! Code MC-001
    logger.debug('DEBUG -- Une erreur est survenue... MC-001')
    return (
      <h4 className="text-center">
        Une erreur est survenue lors du chargement de la conversation... <small className="text-muted">Code MC-001</small>
      </h4>
    )
  }

  let isConnected: undefined | boolean
  if (!conversation.isGroup) {
    const otherAccount = conversation.otherAccountId ? accounts[conversation.otherAccountId] : undefined
    isConnected = otherAccount?.connected === true
  }

  const {name, avatar} = extractConversationMetadata(account.id, conversation, accounts)
  const messages = useMemo(() => {
    if (conversation) {
      return [...conversation.messages].reverse()
    }
    return []
  }, [conversation?.messages.length, conversation?.messages.slice(0, 10).map((m) => m.status)])

  return (
    <div className="message message-recieved d-flex flex-column h-100">
      <div className="d-flex justify-content-between">
        <a className="text-primary" onClick={() => dispatch(messagingActions.setActiveConv())}>
          Retour
        </a>
        <div className="d-flex align-content-center justify-content-center">
          <Avatar img={avatar} alt={name[0].toUpperCase()} size="xs" isConnected={isConnected} />
          <p className="ml-1">
            <strong>{name}</strong>
          </p>
        </div>
      </div>
      <div className="messages-card card pt-1" style={{height: height - 325, overflowX: 'hidden'}}>
        <div className="mb-1 w-100">
          <ul>
            {messages &&
              messages.map((message) => {
                const isCurrentAccount = message.source === account.id

                let mName: string | undefined
                let mAvatar: string | undefined

                const a = accounts[message.source]
                if (a) {
                  mName = `${a.user.firstName} ${a.user.lastName}`
                  mAvatar = urlImageUser + a.user.avatar
                }

                return (
                  <li key={`${message.id || message.uuid}`}>
                    <Message
                      name={mName}
                      avatar={mAvatar}
                      content={message.message}
                      media={message.content}
                      date={new Date(message.timestamp).toISOString()}
                      user={isCurrentAccount ? 1 : 2}
                      status={message.status}
                    />
                  </li>
                )
              })}
          </ul>
          <div
            ref={(el) => {
              messagesEndRef.current = el
            }}
          />
        </div>
      </div>
    </div>
  )
}

// @ts-ignore
export default Conversation
