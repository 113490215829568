import React from 'react'
import {useLocation} from 'react-router-dom'
import BasePageStructure from '../BasePageStructure'
import GalleryPictures from './GalleryPictures'

const PicturesPage = () => {
  const {search} = useLocation()
  let selectedPictureId: number | undefined
  let selectedGalleryId: number | undefined
  try {
    const pictureId = new URLSearchParams(search).get('pictureId')
    const galleryId = new URLSearchParams(search).get('galleryId')
    if (pictureId) {
      selectedPictureId = parseInt(pictureId, 10)
    }
    if (galleryId) {
      selectedGalleryId = parseInt(galleryId, 10)
    }
  } catch (e) {
    // do nothing
  }

  return (
    <BasePageStructure>
      <div className="d-flex flex-column flex-sm-row justify-content-between align-items-center">
        <h3 className="text-light m-0">Galerie photos</h3>
      </div>
      <GalleryPictures selectedPictureId={selectedPictureId} selectedGalleryId={selectedGalleryId} />
    </BasePageStructure>
  )
}

export default PicturesPage
