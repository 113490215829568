const search = {
    users: [],
    companies: [],
    filter: "",
    showDrawer: false,
}

export default (state = search, action) => {
    switch (action.type) {
        case 'searchResults':
            return {
                ...state,
                users: action.payload.users,
                companies: action.payload.companies,
            };
        case 'searchFilter':
            return {
                ...state,
                filter: action.payload,
            };
        case 'showDrawerSearch':
            return {
                ...state,
                showDrawer: action.payload,
            }
        default:
            return state;
    }
}