import React from "react";

export default (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xlink="http://www.w3.org/1999/xlink"
        style={props.style}
        className={props.className}
        version="1.1"
        x="0px"
        y="0px"
        viewBox="0 0 389 336">
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="gallery" fill="currentColor" fillRule="nonzero">
          <path
              d="M201.173,281.042 C189.882,280.868 178.996,276.809 170.348,269.548 L113.401,219.393 C104.44,211.02 90.737,210.357 81.009,217.826 L0.03,277.384 L0.03,316.045 C-0.552,326.416 7.385,335.295 17.756,335.877 C18.29,335.906348 18.826,335.914 19.361,335.898 L306.185,335.898 C317.679,335.898 329.173,327.539 329.173,316.045 L329.173,214.691 L226.25,274.25 C218.684,278.798 210.001,281.15 201.173,281.042 Z"
              id="Path"></path>
          <circle id="Oval" cx="196.993" cy="156.699" r="22.988"></circle>
          <path
              d="M383.508,41.238 C380.173,36.694 375.021,33.832 369.402,33.401 L84.667,0.487 C79.116,0.022 73.576,1.499 68.994,4.667 C64.936,8.191 62.177,12.974 61.157,18.251 L56.455,59.002 L306.186,59.002 C329.995,59.542 349.247,78.564 350.072,102.365 L350.072,286.789 C350.072,285.744 354.774,284.699 356.864,282.609 C361.19,279.212 363.698,274.003 363.656,268.503 L388.21,56.389 C388.753,50.91 387.057,45.445 383.508,41.238 Z"
              id="Path"></path>
          <path
              d="M306.185,79.899 L19.361,79.899 C7.867,79.899 0.03,90.87 0.03,102.364 L0.03,251.262 L68.993,201.107 C86.499,188.121 110.717,189.212 126.985,203.719 L184.454,253.874 C193.12,261.231 205.498,262.28 215.278,256.486 L329.172,190.135 L329.172,102.364 C328.382,90.099 318.465,80.408 306.185,79.899 Z M196.993,200.584 C172.756,200.584 153.107,180.936 153.107,156.698 C153.107,132.461 172.755,112.812 196.993,112.812 C221.23,112.812 240.879,132.46 240.879,156.698 C240.879,180.936 221.231,200.584 196.993,200.584 Z"
              id="Shape"></path>
        </g>
      </g>
    </svg>
)
