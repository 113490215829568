import React, { Component } from 'react';
import api from '../../../services/api';
import {connect} from 'react-redux';
import UserIdentifier from '../../../component/user/avatar/identifier';
import { API_COMMENT_ADD, REGEX_USER_MENTION } from '../../../services/constants';
import { MessagePost } from '../../../component/form';
import {logger} from "../../../services/logger";

class NewComment extends Component {
    constructor() {
        super();
        this.state = {
            comment_content: "",
        }
        this.onSubmit = this.onSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    onSubmit = async (e) => {
        if (this.state.comment_content === undefined || this.state.comment_content === "") {
            document.getElementById("new-comment-input").innerHTML = "Le message ne peut pas être vide";
            return;
        } else {
           document.getElementById("new-comment-input").innerHTML = "";
        }

        var comment = {
            comment: this.state.comment_content,
            post_id: this.props.post.id,
            likes: [],
            user: {
                ...this.props.user,
            }
        };

        let mentioned_users = this.state.comment_content.match(REGEX_USER_MENTION)
        if (mentioned_users) {
            mentioned_users = await mentioned_users.map(element => {
                var id = element.substring(element.indexOf('(') + 1, element.lastIndexOf(')'))
                return id;
            });
            await this.setState({users: mentioned_users});
        }

        var formData = new FormData();
        formData.append('comment', this.state.comment_content);
        formData.append('post_id', this.props.post.id);
        formData.append('likes', JSON.stringify([]));
        formData.append('user', {
            ...this.props.user,
        })

        console.log('formdata', formData)
        formData.append('mentioned_users_id', JSON.stringify(mentioned_users));

        api.post(API_COMMENT_ADD(this.props.post.id), formData, this.props.config)
        .then(res => {
            comment.id = res.data.id;
            this.props.confirmReturn(comment);
        })
        .catch(err => {
            if (err.response.status !== 200) {
                alert("Vous ne pouvez plus commenter ce post car il a été supprimé");
            }
            logger.debug('Add new comment error: ', err);
        })
    }

    handleChange = (name, value) => {
        this.setState({[name]: value});
    }

    render() {
        return (
            <div className="card mt-n3 pt-3 comment-card">
                <div className="card-header pt-2 bg-transparent w-100">
                    <UserIdentifier user={{...this.props.user, avatar: this.props.user && this.props.user.avatar}} />
                </div>
                <div className="card-body w-100 bg-transparent mt-n1 px-3">
                    <MessagePost height={70} id="new-comment-input" returnLists={(users) => this.setState({users: users,})} defaultValue={this.state.comment_content} returnValue={(value) => this.handleChange("comment_content", value)}/>
                    <p className="card-body w-100 bg-transparent" style={{color: "red", padding: 0, margin: 0, fontSize: '1.2rem'}} id="new-comment-input"></p>
                </div>
                <div className="card-footer bg-transparent text-right">
                    <button type="button" className="btn btn-primary mt-n3 mb-2" onClick={this.onSubmit}>Publier</button>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    user: state.userR,
    config: state.apiR,
})

export default connect(mapStateToProps)(NewComment);
