import React, {PropsWithChildren} from 'react'
import NavBar from '../component/header/navbar/NavBar'
import {PersonalDescription} from '../component/user'
import MobileFooter from '../component/footer/MobileFooter'

const BasePageStructure = ({children}: PropsWithChildren<any>) => {
  return (
    <div className="bg-welcome w-100 h-100" style={{position: 'absolute'}}>
      <NavBar />
      <div className="page">
        <div className="d-none d-sm-block col-md-4 col-lg-3" style={{zIndex: 99}}>
          <div className="col-md-4 col-lg-3" />
        </div>
        <div className="container-fluid px-md-4">
          <div className="row">
            <div id="side-message" className="d-none d-md-block col-md-4 d-lg-block col-lg-3 w-100">
              <PersonalDescription />
            </div>
            <div id="timeline" className="col-12 col-md-8 col-lg-9 w-100 mt-3" style={{zIndex: 99}}>
              {children}
            </div>
          </div>
        </div>
      </div>
      <MobileFooter />
    </div>
  )
}

export default BasePageStructure
