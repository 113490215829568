import React from 'react';
import { Switch } from '@material-ui/core';

const AnnuaireTableContentFilter = ({ onFilter, searchByUsers }) => {
    const [inputShowed, setInputShowed] = React.useState(false);
    const [searchText, setSearchText] = React.useState('')

    const onSearch = (value) => {
        setSearchText(value);
        onFilter(value);
    }

    return (
        <div className="w-sm-25">
            <form onSubmit={e => e.preventDefault()} id="group_form" className="group group-hide form-inline position-relative text-right my-2 my-lg-0 w-100">
                <input id="search_group" value={searchText} className={"form-control search_group w-100 hide" + (inputShowed ? " show" : "")} type="text" placeholder="Rechercher" aria-label="Search" onChange={(e) => onSearch(e.target.value)}/>
                <div className="search_group_icon text-right position-absolute w-auto">
                    <div onClick={() => setInputShowed(!inputShowed)}>
                        <svg id="icon_search" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="search" className="icon text-primary" style={{width: 45, height: 45}} role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"></path></svg>
                    </div>
                </div>
            </form>
            <div className="d-flex justify-content-end align-items-center mt-2 bg-light rounded pl-sm-3 pl-md-0">
                <span className="mr-1 px-2 text-center">Rechercher par membre:</span>
                <Switch color="primary" onChange={searchByUsers} />
            </div>
        </div>
    );
};

export default AnnuaireTableContentFilter;