import React from 'react';
import Avatar from '../avatar/Avatar';

const UserDescSkeleton = () => {
    return (
        <div className="card card-home-profile my-3 d-flex text-center bg-transparent justify-content-center align-items-center">
            <div className="home-profile-inner d-flex align-items-center flex-column">
                <div className="avatar avatar-lg position-relative avatar-company" data-toggle="modal" data-target="#modalUserModify">
                    <div className="user-description">
                        <Avatar size="lg" />
                    </div>
                    <div className="company-img">
                        <Avatar size="xs" color="primary"/>
                    </div>
                </div>
                <div className="card-body bg-transparent py-2">
                    <div className="user-profile text-white">
                        <div className="profile-name">
                            <div className="d-flex">
                                <div style={{backgroundColor: '#ffffff50', width: 60, height: 15}}></div>
                                <div className="ml-1" style={{backgroundColor: '#ffffff50', width: 60, height: 15}}></div>
                            </div>
                            <div className="company mt-1 mx-auto" style={{backgroundColor: '#ffffff50', width: 40, height: 15}}></div>
                        </div>
                        <div className="profile-contact-info">
                            <div className="mx-auto mt-1" style={{backgroundColor: '#ffffff50', width: 110, height: 15}}></div>
                            <div className="mx-auto mt-1" style={{backgroundColor: '#ffffff50', width: 20, height: 15}}></div>
                            <small className="text-muted">Erreur UD-001</small>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UserDescSkeleton;
