import { USER_IMAGE_UPLOAD_LINK, COMPANY_IMAGE_UPLOAD_LINK } from "../services/constants";
import { imageAppendUrl } from "../services/utils";

const userDetailsJson = {
    id: -1,
    firstName: "",
    lastName: "",
    image: "",
    linkedin_url: "",
    email: "",
    phone_number: "",
    position: "",
    company: {
        name: "",
        sector: "",
        linkedinUrl: "",
        websiteUrl: "",
        image: "",
        back_image: ""
    },
    show: false,
}

export default (state = userDetailsJson, action) => {
    switch (action.type) {
        case 'userDetailsLoad':
            return {
                ...state,
                ...action.payload,
                image: imageAppendUrl(USER_IMAGE_UPLOAD_LINK, action.payload.avatar),
                company: {
                    ...action.payload.company,
                    image: imageAppendUrl(COMPANY_IMAGE_UPLOAD_LINK, action.payload.company.logo),
                    background_image: imageAppendUrl(COMPANY_IMAGE_UPLOAD_LINK, action.payload.company.backgroundImage),
                }
            }
        case 'userDetailsShow':
            return {
                ...state,
                show: action.payload
            }
        default:
            return state;
    }
}