

export class RetryHandler {
  private retryCount = 0

  private retryTimeout?: number

  // eslint-disable-next-line no-useless-constructor
  constructor(private retryIntervalFn = (retries: number) => retries * 1000) {}

  public clear() {
    this.retryCount = 0
    if (this.retryTimeout) {
      clearTimeout(this.retryTimeout)
      this.retryTimeout = undefined
    }
  }

  public doRetry(retryFn: () => void): number {
    if (this.retryTimeout) {
      return 0
    }
    this.retryCount += 1
    const retryIn = this.retryIntervalFn(this.retryCount)

    this.retryTimeout = setTimeout(() => {
      this.retryTimeout = undefined
      retryFn()
    }, retryIn) as unknown as number
    return retryIn
  }
}
