/**
 * Modify a conversation within a record of conversations
 */
export const mutateObject = <T extends Record<string, unknown>>(mutableObject: Record<number, T>, id: number, fn: (conv: T) => T): T | undefined => {
  const val = mutableObject[id]
  if (val) {
    // eslint-disable-next-line no-param-reassign
    mutableObject[id] = fn(val)
    return mutableObject[id]
  }
  return undefined
}
export const applyObjectVal = <T extends Record<string, unknown>>(mutableObject: Record<number, T>, id: number, p: keyof T, v: T[keyof T]): T | undefined => {
  return mutateObject(mutableObject, id, (c) => ({...c, [p]: v}))
}
