import React, {useMemo} from 'react'
import {Avatar} from '../../user'
import {messagingActions, selectMessaging} from '../../../shared/redux/messaging.slice'
import {logger} from '../../../services/logger'
import {useAppDispatch, useAppSelector} from '../../../shared/redux/reduxHooks'
import {Conv} from '../../../shared/api/api.types'
import {selectConnectedState} from '../../../shared/redux/connexionStatus.slice'
import {extractConversationMetadata} from '../../../shared/utils/extractConversationMetadata'

export const ConvCard = ({convId, conv}: {convId: number; conv: Conv}) => {
  const dispatch = useAppDispatch()

  const {account} = useAppSelector(selectConnectedState)
  const {accounts} = useAppSelector(selectMessaging)
  const {name, avatar, haveSeeConv} = extractConversationMetadata(account.id, conv, accounts)

  let isConnected = false
  if (!conv.isGroup) {
    const otherAccount = conv.otherAccountId ? accounts[conv.otherAccountId] : undefined
    isConnected = otherAccount?.connected === true
  }

  const renderLastMessage = () => {
    if (conv.messages.length === 0) {
      return 'Démarrer la conversation !'
    }
    return conv.messages[0].message
  }

  if (!conv) {
    // ! Code MU-002
    logger.debug('DEBUG -- Une erreur est survenue... Code MU-002')
    return null
  }

  const initials = useMemo(() => {
    if (conv.isGroup) return name[0].toUpperCase()

    return name
      .split(' ')
      .map((s) => s[0])
      .join('')
      .toUpperCase()
  }, [name])

  return (
    <div>
      <div
        className="card my-2"
        onClick={() => {
          dispatch(messagingActions.setActiveConv(convId))
        }}
        style={{cursor: 'pointer'}}>
        <div className="card-body d-flex justify-content-between align-items-center">
          <div className="sender d-flex align-items-center">
            <div className="mr-2">
              {conv.isGroup ? <Avatar img={avatar} alt={initials} size="sm" /> : <Avatar img={avatar} alt={initials} size="sm" isConnected={isConnected} />}
            </div>
            <div className="name message">
              <p className="text-primary m-0 p-0">
                <strong>{name}</strong>
              </p>
              <p className="">{!haveSeeConv ? <strong>{renderLastMessage()}</strong> : renderLastMessage()}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ConvCard
