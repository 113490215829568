const noop = () => {}

const logLevel = process.env.REACT_APP_LOG_LEVEL || 'all'
const buildConsole = (): typeof console => {
  if (logLevel.toLowerCase() === 'all') {
    return console
  }
  return {
    ...console,
    debug: noop,
  }
}

// pollyfill to match mobile
export const logger = buildConsole()

export default logger
