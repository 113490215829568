import React from 'react'
import { API_USER_LOAD, REGEX_USER_MENTION_HASHTAGS } from "../../../services/constants";
import { connect } from 'react-redux';
import { userDetailsLoad, userDetailsShow } from '../../../actions/userDetailsAction';
import api from '../../../services/api';
import { showDrawerSearch } from '../../../actions/searchAction';
import { postFilter } from '../../../actions/postsAction';
import { logger } from "../../../services/logger";
import {LinkItUrl} from "react-linkify-it";
import style from "../../style.css"

const showMentionDetails = (id, props) => {
    api.get(API_USER_LOAD(id), props.config)
    .then(res => {
        props.userDetailsLoad(res.data);
        props.userDetailsShow(true);
        if (window.innerWidth <= 846) {
            props.showDrawerSearch(true);
        }
    })
    .catch(err => {
        logger.debug("Get user data ERROR: ", err);
    })
}

const MentionHandler = (props) => {
    let matches = props.children.match(REGEX_USER_MENTION_HASHTAGS);
    var parts = props.children.split(REGEX_USER_MENTION_HASHTAGS);

    for (var i = 0; i < parts.length; i++) {
        if (i !== parts.length - 1) {
            let match = matches[i];
            while (parts[i + 1] === '') {
                match += matches[++i] === undefined ? "" : matches[++i];
            }

            if (match[0] === '@') {
                let user = match.substring(2, match.lastIndexOf(']'));
                let id = match.substring(match.indexOf('(') + 1, match.lastIndexOf(')'))
                parts[i] = (
                    <React.Fragment key={i}>
                        {parts[i]}<a onClick={() => showMentionDetails(id, props)} className="text-primary text-capitalize">{user}</a>
                    </React.Fragment>
                )
            } else if (match[0] === '#') {
                parts[i] = (
                    <React.Fragment key={i}>
                        {parts[i]}<a className="text-primary" onClick={() => props.postFilter(match)} style={{textDecoration: 'underline'}}>{match}</a>
                    </React.Fragment>
                )
            }
        }
    }

  return <LinkItUrl className="blue"><span>{parts}</span></LinkItUrl>
}

const mapStateToProps = state => ({
    config: state.apiR,
})

const mapDisptachToProps = dispatch => ({
    userDetailsLoad: (payload) => dispatch(userDetailsLoad(payload)),
    userDetailsShow: (payload) => dispatch(userDetailsShow(payload)),
    showDrawerSearch: (payload) => dispatch(showDrawerSearch(payload)),
    postFilter: (payload) => dispatch(postFilter(payload)),
})

export default connect(mapStateToProps, mapDisptachToProps)(MentionHandler);
