import React, { Component } from 'react';
import api from '../../../services/api';
import {connect} from 'react-redux';
import { userDetailsLoad, userDetailsShow } from '../../../actions/userDetailsAction';
import Avatar from './Avatar';
import { API_USER_LOAD } from '../../../services/constants';
import { showDrawerSearch } from '../../../actions/searchAction';
import { dateFormaterPost } from '../../../services/utils';
import {logger} from "../../../services/logger";

class UserIdentifier extends Component {
    constructor() {
        super();
        this.showDetails = this.showDetails.bind(this);
    }

    showDetails = () => {
        api.get(API_USER_LOAD(this.props.user.id), this.props.config)
        .then(res => {
            this.props.userDetails(res.data);
            this.props.showUserDetails(true);
            if (window.innerWidth <= 990) {
                this.props.showDrawerSearch(true);
            }
        })
        .catch(err => {
            logger.debug('User details for ' + this.props.user.id + ' ERROR: ', err);
        })
    }

    render() {
        if (!this.props.user) {
            // Code UI-001
            logger.debug('DEBUG -- Une erreur est survenue... Code UI-001');
            return null;
        }

        return (
            <div className="col-9 p-0 d-flex align-items-center">
                <div className="avatar-edit mr-3">
                    <Avatar alt={(this.props.user.firstName && this.props.user.lastName) ? this.props.user.firstName[0]+this.props.user.lastName[0] : ''} img={this.props.user.avatar} size="sm" />
                </div>
                <div className="user-profile-name" style={{cursor: "pointer"}} onClick={this.showDetails}>
                    <p className="text-primary-darker m-0"><strong>{this.props.user.firstName} <strong className="text-uppercase">{this.props.user.lastName}</strong></strong></p>
                    <p className="card-subtitle m-0 text-muted">{this.props.user.position}</p>
                    {
                        this.props.date &&
                        <p className="text-primary mb-n1" style={{fontSize: 11}}>{dateFormaterPost(this.props.date)}</p>
                    }
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    config: state.apiR,
})

const mapDispatchToProps = dispatch => ({
    userDetails: (payload) => dispatch(userDetailsLoad(payload)),
    showUserDetails: (payload) => dispatch(userDetailsShow(payload)),
    showDrawerSearch: (payload) => dispatch(showDrawerSearch(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(UserIdentifier);
