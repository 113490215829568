import {Drawer} from '@material-ui/core'
import React, {useEffect, useState} from 'react'
import {showDrawerSearch} from '../../actions/searchAction'
import {SearchButton} from '../form'
import {Groups} from '../groups'
import ChatContainer from '../messages/block/ChatContainer'
import {useAppDispatch, useAppSelector} from '../../reducers/hooks'
import {messagesRActions} from '../../reducers/messagesReducer'
import {selectMessaging} from '../../shared/redux/messaging.slice'

const MobileFooter = () => {
  const dispatch = useAppDispatch()
  const drawerShowSearch = useAppSelector((state) => state.searchR.showDrawer)
  const drawerShowMessages = useAppSelector((state) => state.conversationsR.showDrawerMessages)
  const messaging = useAppSelector(selectMessaging)
  const conversations = useAppSelector((state) => state.conversationsR)

  const [open, setOpen] = useState<{left: boolean; right: boolean}>({left: drawerShowMessages, right: drawerShowSearch})

  const onPressHome = () => {
    setOpen({
      left: false,
      right: false,
    })
    dispatch(showDrawerSearch(false))
    dispatch(messagesRActions.showDrawerMessages(false))
  }

  useEffect(() => {
    // console.log('open', open)
  }, [open])

  const toggleDrawer = (who: 'left' | 'right', other: 'left' | 'right') => {
    // console.log(`toggle drawer: who:${who}, other:${other}`)
    setOpen((prev) => ({
      ...prev,
      [who]: !prev[who],
      [other]: false,
    }))
    if (who !== 'left') {
      dispatch(showDrawerSearch(!drawerShowSearch))
      dispatch(messagesRActions.showDrawerMessages(false))
    } else {
      dispatch(showDrawerSearch(false))
      dispatch(messagesRActions.showDrawerMessages(!drawerShowMessages))
    }
  }

  return (
    <div>
      <Drawer open={open.left} onClose={() => toggleDrawer('left', 'right')}>
        <div className="bg-welcome" style={{minWidth: window.innerWidth - 50}}>
          <div className="container p-2" style={{marginBottom: 30}}>
            <div className="card w-100 bg-light shadow-lg pb-1">
              <header
                className="card-header message-header rounded message-profile bg-transparent mb-2"
                style={{cursor: 'pointer'}}
                onClick={() => toggleDrawer('left', 'right')}>
                <div className="text-center pt-1">
                  <h5>Messages</h5>
                </div>
              </header>
              <ChatContainer />
            </div>
          </div>
        </div>
      </Drawer>
      <Drawer anchor="right" open={open.right} onClose={() => toggleDrawer('right', 'left')}>
        <div className="bg-welcome">
          <div className="container p-2" style={{marginBottom: 30, minWidth: 300}}>
            <SearchButton showed />
            <Groups />
          </div>
        </div>
      </Drawer>
      <footer className="d-sm-block d-md-none d-lg-none">
        <div className="container">
          <div className="row px-3">
            <div className="footer-container d-flex align-items-center w-100">
              <div className="col-3 footer-nav active">
                <div className="footer-nav-item d-flex align-items-center justify-content-center">
                  <div className="open-message text-primary" onClick={() => toggleDrawer('left', 'right')}>
                    {conversations && (
                      <span className="position-absolute badge badge-pill badge-danger" style={{top: -5, right: 15}}>
                        {messaging.haveNewMessages || ''}
                      </span>
                    )}
                    <svg
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="far"
                      data-icon="envelope"
                      className="nav-icon"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512">
                      <path
                        fill="currentColor"
                        d="M464 64H48C21.49 64 0 85.49 0 112v288c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49 48-48V112c0-26.51-21.49-48-48-48zm0 48v40.805c-22.422 18.259-58.168 46.651-134.587 106.49-16.841 13.247-50.201 45.072-73.413 44.701-23.208.375-56.579-31.459-73.413-44.701C106.18 199.465 70.425 171.067 48 152.805V112h416zM48 400V214.398c22.914 18.251 55.409 43.862 104.938 82.646 21.857 17.205 60.134 55.186 103.062 54.955 42.717.231 80.509-37.199 103.053-54.947 49.528-38.783 82.032-64.401 104.947-82.653V400H48z"
                      />
                    </svg>
                  </div>
                </div>
              </div>
              <div className="col-3 footer-nav">
                <a
                  className="footer-nav-item d-flex align-items-center justify-content-center to-home text-primary"
                  data-target="#modalFeed"
                  data-dismiss="modal"
                  onClick={() => onPressHome()}>
                  <svg
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fas"
                    data-icon="home"
                    className="nav-icon"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 576 512">
                    <path
                      fill="currentColor"
                      d="M280.37 148.26L96 300.11V464a16 16 0 0 0 16 16l112.06-.29a16 16 0 0 0 15.92-16V368a16 16 0 0 1 16-16h64a16 16 0 0 1 16 16v95.64a16 16 0 0 0 16 16.05L464 480a16 16 0 0 0 16-16V300L295.67 148.26a12.19 12.19 0 0 0-15.3 0zM571.6 251.47L488 182.56V44.05a12 12 0 0 0-12-12h-56a12 12 0 0 0-12 12v72.61L318.47 43a48 48 0 0 0-61 0L4.34 251.47a12 12 0 0 0-1.6 16.9l25.5 31A12 12 0 0 0 45.15 301l235.22-193.74a12.19 12.19 0 0 1 15.3 0L530.9 301a12 12 0 0 0 16.9-1.6l25.5-31a12 12 0 0 0-1.7-16.93z"
                    />
                  </svg>
                </a>
              </div>
              <div className="col-3 footer-nav ">
                <div className="footer-nav-item d-flex align-items-center justify-content-center">
                  <div className="open-search text-primary" onClick={() => toggleDrawer('right', 'left')}>
                    <svg
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fas"
                      data-icon="search"
                      className="nav-icon"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512">
                      <path
                        fill="currentColor"
                        d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"
                      />
                    </svg>
                  </div>
                </div>
              </div>
              <div className="col-3 footer-nav ">
                <div className="footer-nav-item d-flex align-items-center justify-content-center" onClick={() => onPressHome()}>
                  <div data-toggle="modal" data-target="#modalFeed" className="text-primary py-2">
                    <svg
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="far"
                      data-icon="edit"
                      className="nav-icon"
                      style={{bottom: 1}}
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 576 512">
                      <path
                        fill="currentColor"
                        d="M402.3 344.9l32-32c5-5 13.7-1.5 13.7 5.7V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V112c0-26.5 21.5-48 48-48h273.5c7.1 0 10.7 8.6 5.7 13.7l-32 32c-1.5 1.5-3.5 2.3-5.7 2.3H48v352h352V350.5c0-2.1.8-4.1 2.3-5.6zm156.6-201.8L296.3 405.7l-90.4 10c-26.2 2.9-48.5-19.2-45.6-45.6l10-90.4L432.9 17.1c22.9-22.9 59.9-22.9 82.7 0l43.2 43.2c22.9 22.9 22.9 60 .1 82.8zM460.1 174L402 115.9 216.2 301.8l-7.3 65.3 65.3-7.3L460.1 174zm64.8-79.7l-43.2-43.2c-4.1-4.1-10.8-4.1-14.8 0L436 82l58.1 58.1 30.9-30.9c4-4.2 4-10.8-.1-14.9z"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  )
}
export default MobileFooter
