import React, {useState} from 'react'
import {useAppSelector} from '../../../reducers/hooks'
import {logger} from '../../../services/logger'
import {getLinks} from '../../../services/utils'
import Avatar from '../avatar/Avatar'
import ProfileModify from '../profile-modify/profile-modify'
import UserDescSkeleton from './user-desc-skeleton'

const PersonalDescription = () => {
  const userData = useAppSelector((state) => state.userR)
  const companyData = useAppSelector((state) => state.companyR)

  const [isProfileModifyVisible, setIsProfileModifyVisible] = useState(false)

  if (!userData || !companyData) {
    // ! Code UD-001
    logger.debug('DEBUG -- Une erreur est survenue... Code UD-001')

    return <UserDescSkeleton />
  }

  return (
    <div className="card card-home-profile my-3 d-flex text-center bg-transparent justify-content-center align-items-center">
      <ProfileModify
        isVisible={isProfileModifyVisible}
        onDismiss={() => {
          console.log('ON DISMISS')
          setIsProfileModifyVisible(false)
        }}
      />
      <div className="home-profile-inner d-flex align-items-center flex-column">
        <div className="avatar avatar-lg position-relative avatar-company" onClick={() => setIsProfileModifyVisible(true)}>
          <div className="user-description">
            <Avatar img={userData.avatar} alt={userData.firstName && userData.lastName ? userData.firstName[0] + userData.lastName[0] : ''} size="lg" />
            <div className="overlay">
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="pencil-alt"
                className="text-light"
                style={{width: 24}}
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512">
                <path
                  fill="currentColor"
                  d="M497.9 142.1l-46.1 46.1c-4.7 4.7-12.3 4.7-17 0l-111-111c-4.7-4.7-4.7-12.3 0-17l46.1-46.1c18.7-18.7 49.1-18.7 67.9 0l60.1 60.1c18.8 18.7 18.8 49.1 0 67.9zM284.2 99.8L21.6 362.4.4 483.9c-2.9 16.4 11.4 30.6 27.8 27.8l121.5-21.3 262.6-262.6c4.7-4.7 4.7-12.3 0-17l-111-111c-4.8-4.7-12.4-4.7-17.1 0zM124.1 339.9c-5.5-5.5-5.5-14.3 0-19.8l154-154c5.5-5.5 14.3-5.5 19.8 0s5.5 14.3 0 19.8l-154 154c-5.5 5.5-14.3 5.5-19.8 0zM88 424h48v36.3l-64.5 11.3-31.1-31.1L51.7 376H88v48z"
                />
              </svg>
            </div>
          </div>
          <div className="company-img">
            <Avatar img={companyData.company_avatar} alt={companyData.name[0]} size="xs" color="primary" />
          </div>
        </div>
        <div className="card-body bg-transparent py-2">
          <div className="user-profile text-white">
            <div className="profile-name">
              <h5>
                {userData.firstName} <span className="text-uppercase">{userData.lastName}</span>
              </h5>
              <p className="profile-text-light company p-0 m-auto w-75 ">{userData.position}</p>
            </div>
            <div className="profile-contact-info d-flex flex-column">
              {companyData.websiteUrl && (
                <a target="_blank" rel="noopener noreferrer" href={companyData.websiteUrl} className="m-0 p-0 text-light">
                  {companyData.websiteUrl.replace('https://', '').replace('http://', '')}
                </a>
              )}
              {userData.linkedin_url && userData.linkedin_url.match(/[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/) && (
                <a target="_blank" rel="noopener noreferrer" href={getLinks(userData.linkedin_url)}>
                  <svg
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fab"
                    data-icon="linkedin-in"
                    className="icon text-light icon-hover"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512">
                    <path
                      fill="currentColor"
                      d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"
                    />
                  </svg>
                </a>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PersonalDescription
