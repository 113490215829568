import React from 'react'
import {withRouter} from 'react-router-dom'
import AnnuaireTable from '../../component/annuaire/annuaire-table/annuaire-table'
import SectorList from '../../component/annuaire/sector-list/sector-list'
import BasePageStructure from '../BasePageStructure'

const AnnuairePage = () => {
  return (
    <BasePageStructure>
      <AnnuaireTable />
      <SectorList />
    </BasePageStructure>
  )
}

export default withRouter(AnnuairePage)
