export type ApiConfState = {
  headers: Record<string, string>
}

const config: ApiConfState = {
  headers: {},
}

// eslint-disable-next-line default-param-last
export default (state = config, action: {type: 'token' | 'removeToken'; payload: string}) => {
  switch (action.type) {
    case 'token':
      localStorage.setItem('Authorization', JSON.stringify(action.payload))
      return {
        headers: {
          Authorization: `Bearer ${action.payload}`,
        },
      }
    case 'removeToken':
      localStorage.removeItem('Authorization')
      return {
        headers: {},
      }
    default:
      return state
  }
}
