import React from 'react'
import {LinkItEmail, LinkItUrl} from 'react-linkify-it'
import {Avatar} from '../../user'
import {dateFormaterPost} from '../../../services/utils'
import {BASE_MESSAGING_PICTURES_URL} from '../../../services/constants'
import SyncProblemSvg from '../../../assets/svg/SyncProblemSvg'
import styles from './Message.module.css'

type MyMessageProps = {
  content?: string
  media?: string
  name?: string
  avatar?: string
  date: string
  status?: 'received' | 'pending' | 'error' | 'sent'
}

const MediaPart = ({media}: Pick<MyMessageProps, 'media'>) => {
  if (!media) return null

  let uri = media

  // media may be a base64 image, if not, prepend backend url
  if (!uri.startsWith('data:')) uri = BASE_MESSAGING_PICTURES_URL + media

  return (
    <a href={uri} target="_blank" rel="noreferrer">
      <img src={uri} className="w-100 mb-1" />
    </a>
  )
}
const ContentPart = ({content}: Pick<MyMessageProps, 'content'>) => (
  <p className="m-0 p-0">
    <LinkItUrl>
      <LinkItEmail>{content}</LinkItEmail>
    </LinkItUrl>
  </p>
)

const LoadingTick = () => (
  <div className={`${styles.status} lds-ripple-tiny`}>
    <div />
    <div />
  </div>
)
const ErrorTick = () => (
  <div className={styles.status}>
    <SyncProblemSvg height={15} width={15} />
  </div>
)
const Status = ({status}: Pick<MyMessageProps, 'status'>) => {
  switch (status) {
    case 'pending':
      return <LoadingTick />
    case 'error':
      return <ErrorTick />
    case 'sent':
    case 'received':
    default:
      return null
  }
}

const MyMessage = ({content, media, name, avatar, date, status}: MyMessageProps) => (
  <div className="row d-flex align-items-center" style={{flexWrap: 'nowrap'}}>
    <div className="message-wrapper col-sm-10 p-0">
      <div className="message-content px-3 py-2">
        <div className="from-name mb-1">
          <span className="font-weight-medium text-primary">{name}</span>
        </div>
        <div className="from-message">
          <MediaPart media={media} />
          <ContentPart content={content} />
        </div>
        <Status status={status} />
      </div>
      <div className="message-date">
        <span className="text-muted text-small-light">{dateFormaterPost(date)}</span>
      </div>
    </div>
    <div className="avatar-edit col-sm-2 p-0 text-right w-25">
      <Avatar img={avatar} alt={name && name[0]} size="xs" />
    </div>
  </div>
)

const OtherMessage = ({content, media, name, avatar, date}: Omit<MyMessageProps, 'status'>) => (
  <div className="row d-flex align-items-center">
    <div className="avatar-edit col-sm-2 p-0 ">
      <Avatar img={avatar} alt={name && name[0]} size="xs" />
    </div>
    <div className="message-wrapper rounded col-sm-10 p-0">
      <div className="message-content px-3 py-2">
        <div className="from-name mb-1">
          <span className="text-muted font-weight-medium">{name}</span>
        </div>
        <div className="from-message">
          <MediaPart media={media} />
          <ContentPart content={content} />
        </div>
      </div>
      <div className="message-date">
        <span className="text-muted text-small-light">{dateFormaterPost(date)}</span>
      </div>
    </div>
  </div>
)

type MessageProps = MyMessageProps & {
  user: number
}
const Message = (props: MessageProps) => {
  const {user} = props
  return (
    <div className={`message message-recieved d-flex mb-2 ${user === 1 ? 'ml' : 'mr'}-auto`}>
      <div className="container px-4">
        {user === 2 && <OtherMessage {...props} />}
        {user === 1 && <MyMessage {...props} />}
      </div>
    </div>
  )
}

export default Message
