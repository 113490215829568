import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {USER_IMAGE_UPLOAD_LINK} from '../services/constants'
import {imageAppendUrl} from '../services/utils'
import {LoginResAccount, LoginUser} from '../shared/api/loginApi'

export type UserState = {
  id: number
  deviceId?: number
  accounts: LoginResAccount[]
  description: string
  firstName: string
  lastName: string
  position: string
  linkedin_url: string
  email: string
  phone_number: string
  password: string
  avatar: string
  avatar_extension: string
  user_avatar_image: string
  user_avatar_image_extension: string
  password_confirm: string
}
const initialState: UserState = {
  id: 0,
  deviceId: undefined,
  accounts: [],
  firstName: '',
  lastName: '',
  description: '',
  position: '',
  linkedin_url: '',
  email: '',
  phone_number: '',
  password: '',
  avatar: '',
  avatar_extension: '',
  user_avatar_image: '',
  user_avatar_image_extension: '',
  password_confirm: '',
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    userData: (
      state,
      action: PayloadAction<
        Pick<
          UserState,
          'id' | 'deviceId' | 'accounts' | 'position' | 'linkedin_url' | 'email' | 'phone_number' | 'password' | 'password_confirm' | 'description'
        >
      >
    ) => {
      state.id = action.payload.id
      state.deviceId = action.payload.deviceId
      state.accounts = action.payload.accounts
      state.position = action.payload.position
      state.linkedin_url = action.payload.linkedin_url
      state.email = action.payload.email
      state.phone_number = action.payload.phone_number
      state.password = action.payload.password
      state.description = action.payload.description
      state.password_confirm = action.payload.password_confirm
    },
    userName: (state, action: PayloadAction<Pick<UserState, 'firstName' | 'lastName'>>) => {
      state.firstName = action.payload.firstName
      state.lastName = action.payload.lastName
    },
    userImg: (state, action: PayloadAction<{img?: string; img_data?: string; extension: string}>) => {
      let image
      if (action.payload.img) {
        image = imageAppendUrl(USER_IMAGE_UPLOAD_LINK, action.payload.img)
      } else {
        image = action.payload.img_data
      }
      state.avatar = image
      state.user_avatar_image = image
      state.avatar_extension = action.payload.extension
      state.user_avatar_image_extension = action.payload.extension
    },
    userLoad: (state, action: PayloadAction<LoginUser & {deviceId: number}>) => {
      state.id = action.payload.id
      state.deviceId = action.payload.deviceId
      state.accounts = action.payload.accounts
      state.firstName = action.payload.firstName
      state.lastName = action.payload.lastName
      state.position = action.payload.position
      state.linkedin_url = action.payload.linkedin_url
      state.email = action.payload.email
      state.phone_number = action.payload.phone_number
      state.description = action.payload.description
      state.avatar = imageAppendUrl(USER_IMAGE_UPLOAD_LINK, action.payload.avatar)
    },
    reset: () => {
      return initialState
    },
  },
})

export const userActions = userSlice.actions

export default userSlice.reducer
