import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {CLUB_IMAGE_UPLOAD_LINK} from '../services/constants'
import {imageAppendUrl} from '../services/utils'
import {Feature} from '../shared/hooks/useFeatures'

export type ClubState = {
  club_image: string
  club_name: string
  club_contact_email: string
  features: Feature[]
  allChecked: number
  club_id: number
  club_color: string
}

const initialState: ClubState = {
  club_image: '',
  club_name: '',
  club_contact_email: '',
  features: [],
  allChecked: 0,
  club_id: 0,
  club_color: '#151140',
}

const clubSlice = createSlice({
  name: 'clubState',
  initialState,
  reducers: {
    checkChange: (state, action: PayloadAction<number>) => {
      state.allChecked += action.payload
    },
    clubId: (state, action: PayloadAction<number>) => {
      state.club_id = action.payload
    },
    clubLoad: (
      state,
      action: PayloadAction<{avatar: string; name: string; email: string; id: number; primaryColor: string; features: {featureKey: string}[]}>
    ) => {
      state.club_image = imageAppendUrl(CLUB_IMAGE_UPLOAD_LINK, action.payload.avatar)
      state.club_name = action.payload.name
      state.club_contact_email = action.payload.email
      state.club_id = action.payload.id
      state.club_color = action.payload.primaryColor
      state.features = action.payload.features.map(({featureKey}) => featureKey) as Feature[]
    },
  },
})

export const clubSliceActions = clubSlice.actions

export default clubSlice.reducer
