import React, {useEffect, useState} from 'react'
// @ts-ignore
import Gallery from 'react-grid-gallery'
import DocumentsSvg from '../../assets/svg/DocumentsSvg'
import {useAppSelector} from '../../reducers/hooks'
import {BASE_URL} from '../../services/constants'
import galleriesApi from '../../shared/api/galleriesApi'
import {Folder, PictureItem, PicturesApiRes} from '../../shared/api/picturesApi'
import {useApi} from '../../shared/api/useApi'
import {selectConnexionStatus} from '../../shared/redux/connexionStatus.slice'

type Picture = PictureItem & {
  src: string
  alt: string
  thumbnail: string
  caption: string
  filename: string
  thumbnailWidth: number
  thumbnailHeight: number
}
type Tab = Folder & {pictures: Picture[]}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const sortByDateDesc = (a: {date: string | null}, b: {date: string | null}) => {
  const aTimestamp = a.date ? new Date(a.date).getTime() : 0
  const bTimestamp = b.date ? new Date(b.date).getTime() : 0
  return bTimestamp - aTimestamp
}

const sortByIdAsc = (a: {id: number}, b: {id: number}) => a.id - b.id

const tabsFromPictureApiRes = (pictureApiRes: PicturesApiRes) => {
  // console.log("la res", res);
  const existingFolderIds: number[] = []
  const tabs: Tab[] = []

  pictureApiRes.forEach((item) => {
    let tabIndex = 0
    if (item.gallery) {
      const folderIndex = existingFolderIds.indexOf(item.gallery.id)
      if (folderIndex !== -1) {
        tabIndex = folderIndex
      } else {
        tabs.push({
          ...item.gallery,
          pictures: [],
        })
        existingFolderIds.push(item.gallery.id)
        tabIndex = existingFolderIds.length - 1
      }
    } else {
      const folderIndex = existingFolderIds.indexOf(0)
      if (folderIndex !== -1) {
        tabIndex = folderIndex
      } else {
        tabs.push({
          id: -1,
          title: 'Photos non classées',
          date: null,
          pictures: [],
        })
        existingFolderIds.push(0)
        tabIndex = existingFolderIds.length - 1
      }
    }
    tabs[tabIndex].pictures.push({
      ...item,
      src: `${BASE_URL}/uploads/pictures/${item.filename}`,
      alt: item.title,
      thumbnail: `${BASE_URL}/uploads/pictures/${item.filename}`,
      caption: item.title,
      // width: 2,
      // height: 1
      thumbnailWidth: 320,
      thumbnailHeight: 212,
    })
  })
  if (tabs.length) {
    tabs.sort(sortByIdAsc)
  }
  return tabs
}

const GalleryPictures = ({selectedPictureId, selectedGalleryId}: {selectedPictureId?: number; selectedGalleryId?: number}) => {
  const {tokenUser, club} = useAppSelector(selectConnexionStatus)
  const [photos, setPhotos] = useState<Picture[]>([])
  const [tabs, setTabs] = useState<Tab[]>([])
  const [selectedFolder, setSelectedFolder] = useState<number | null>(null)

  useEffect(() => {
    if (tabs.length > 0) {
      if (selectedPictureId) {
        const tabIndex = tabs.findIndex((t) => t.pictures.findIndex((p) => p.id === selectedPictureId) >= 0)
        if (tabIndex >= 0) {
          setSelectedFolder(tabIndex)
        }
      } else if (selectedGalleryId) {
        const tabIndex = tabs.findIndex((t) => t.id === selectedGalleryId)
        if (tabIndex >= 0) {
          setSelectedFolder(tabIndex)
        }
      }
    }
  }, [selectedPictureId, selectedGalleryId, tabs])

  const getPicturesApi = useApi(
    galleriesApi.getPictures,
    (res) => {
      setTabs(tabsFromPictureApiRes(res))
      setSelectedFolder(0)
    },
    (e) => {
      // ! Code An-200
      console.error('Get all the photos of the club ERROR: Code An-200', JSON.stringify(e, undefined, 2))
    }
  )

  useEffect(() => {
    if (tokenUser && club) {
      getPicturesApi.call(tokenUser, club.id)
    }
  }, [])

  useEffect(() => {
    if (selectedFolder !== null && tabs.length > 0) {
      setPhotos(tabs[selectedFolder].pictures)
    }
  }, [selectedFolder, tabs])

  if (getPicturesApi.isLoading) {
    return (
      <div className="card mt-3">
        <div className="card-body rounded min-vh-100 d-flex justify-content-center align-items-top">
          <div className="lds-ripple">
            <div />
            <div />
          </div>
        </div>
      </div>
    )
  }
  if (getPicturesApi.error) {
    return (
      <div className="card mt-3">
        <div className="card-body rounded min-vh-100">Nous n'avons pu récupérer les images de la gallerie...</div>
      </div>
    )
  }

  if (tabs.length === 0) {
    return (
      <div className="card mt-3">
        <div className="card-body rounded min-vh-100">La gallerie est vide</div>
      </div>
    )
  }

  return (
    <>
      <div className="card mt-3 d-flex flex-wrap flex-row justify-content-start align-items-center">
        {tabs.map((tab, index) => {
          return (
            <button
              key={`b${tab.id}`}
              type="button"
              className={`btn px-3 mr-4  d-flex flex-row align-items-center ${index === selectedFolder ? 'btn-light' : 'btn-primary-darker text-white'}`}
              onClick={() => setSelectedFolder(index)}>
              {tab.id >= 0 && <DocumentsSvg width={20} height={20} className="mr-1" />}
              {tab.title}
            </button>
          )
        })}
      </div>
      <div className="card mt-3">
        <div className="card-body rounded min-vh-100">
          <Gallery
            enableImageSelection={false}
            images={photos.map((p) => ({...p, isSelected: p.id === selectedPictureId}))}
            backdropClosesModal
            imageCountSeparator=" sur "
          />
        </div>
      </div>
    </>
  )
}

export default GalleryPictures
