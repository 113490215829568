import {Middleware} from '@reduxjs/toolkit'
import {messagingActions} from '../redux/messaging.slice'
import {connexionStatusActions} from '../redux/connexionStatus.slice'
import type {AppDispatch, RootState} from '../../bridge/reduxStore'
import {ChatSocket} from './ChatSocket'
import {Message} from '../api/api.types'
import {logger} from '../../services/logger'

type ConvEvent = {type: string}
type ConvEventNewMessage = ConvEvent & {
  type: 'message'
  data: Message
}
type ConvEventUserIn = ConvEvent & {
  type: 'userin'
  data: {
    // conversation id
    id: number
    // account id of the user who went online
    accountId: number
  }
}
type ConvEventUserOut = ConvEvent & {
  type: 'userout'
  data: {
    // conversation id
    id: number
    // account id of the user who went offline
    accountId: number
  }
}
type ConvEvents = ConvEventNewMessage | ConvEventUserIn | ConvEventUserOut

const messagingSocketMiddleware: Middleware = ({dispatch, getState}: {dispatch: AppDispatch; getState: () => RootState}) => {
  const chatSocket = new ChatSocket<ConvEvents>()

  return (next) => (action) => {
    const {account, deviceId} = getState().connexionStatusRedux

    if (!account || !deviceId) {
      return next(action)
    }

    chatSocket.updateState(account.id, deviceId, account.authkey, {
      onConnected: () => dispatch(messagingActions.connectionEstablished()),
      onClosed: () => dispatch(messagingActions.disconnected()),
      onConnectionInterrupt: () => dispatch(messagingActions.markAllAccountsOffline()),
      onMessage: (evt) => {
        switch (evt.type) {
          case 'message':
            dispatch(messagingActions.receiveMessage({message: evt.data, fromMe: evt.data.source === account.id}))
            break
          case 'userin':
            dispatch(messagingActions.updateAccountConnectedState({accountId: evt.data.accountId, connectionState: 'connected'}))
            break
          case 'userout':
            dispatch(messagingActions.updateAccountConnectedState({accountId: evt.data.accountId, connectionState: 'not connected'}))
            break
          default:
            break
        }
      },
    })

    if (messagingActions.startConnecting.match(action)) {
      chatSocket.connect()
    } else if (messagingActions.startDisconnecting.match(action) || connexionStatusActions.onDisconnect.match(action)) {
      chatSocket.disconnect()
    }

    return next(action)
  }
}

export default messagingSocketMiddleware
