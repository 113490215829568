import React from 'react';
import { Avatar } from '../../../component/user';
import GroupSkeleton from './group-skeleton';
import {logger} from "../../../services/logger";
import PageNoResult from "../page-no-result";

class Group extends React.Component {

    state = {
        users: []
    };

    constructor(props) {
        super(props);

    }

    componentDidMount() {
        const finalUsers = [];
        this.props.data.users.map(element => {
            if (element.firstName !== "anonymous" && element.lastName !== "anonymous") {
                finalUsers.push(element);
            }
        });
        this.setState({users: finalUsers});
    }

    shouldComponentUpdate() {
        return this.props.onReload;
    }


    render() {
        if (!this.props.data) {
            // ! Code G-001
            logger.debug('DEBUG -- Une erreur est survenue... G-001');
            return <PageNoResult />
        }

         return (
           <div className="company_group py-2 clickable" onClick={() => this.props.seeDetails(this.props.data.id)}>
             <div className="container d-flex position-relative align-items-center w-100">
               <div className="mr-3">
                 <Avatar alt={this.props.data.name && this.props.data.name[0]} img={this.props.data.image} size="sm"/>
               </div>
               <div className="group_name w-100">
                 <p className="m-0 p-0 text-uppercase"><strong>{this.props.data.name}</strong></p>
                 <p className="card-subtitle m-0 p-0 text-muted">{this.props.data.sector && this.props.data.sector.libelle}</p>
                 <div className="group_members w-100">
                   <div className="members my-1">
                     <div className="avatar-edit d-flex flex-row">
                       {
                         this.state.users.slice(0, 3).map((element, i) => (
                           <div key={element.id} className={"border " + (i > 0 ? "ml-n2" : "")}>
                             <Avatar alt={element.firstName + " " + element.lastName} initials={(element.firstName?element.firstName[0]:'') + (element.lastName?element.lastName[0]:'')} key={element.id} img={element.image} size="xs" />
                           </div>
                         ))
                       }
                     </div>
                   </div>
                   <a href="#!" className="member_count">{this.props.data.users.length} membre{this.props.data.users.length > 1 ? 's' : ''}</a>
                 </div>
               </div>
             </div>
           </div>
         );
    }
}
export default Group;
