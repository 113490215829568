import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import wordThemeApi, {WordTheme} from '../api/wordThemeApi'
import type {AppDispatch, RootState} from '../../bridge/reduxStore'
import {logger} from '../../services/logger'

type WordThemeState = {
  theme: Required<WordTheme>
}

const initialState: WordThemeState = {
  theme: {
    label: 'Club',
    prefix_label: 'le club',
    undefined_prefix_label: 'un club',
    defined_prefix_label: 'du club',
    plural_label: 'Clubs',
    group_name: 'Groupe',
    prefix_group_name: 'le groupe',
    undefined_prefix_group_name: 'un groupe',
    defined_prefix_group_name: 'du groupe',
    plural_group_name: 'Groupes',
    partner_name: 'Partenaire',
    prefix_partner_name: 'le partenaire',
    undefined_prefix_partner_name: 'un partenaire',
    defined_prefix_partner_name: 'du partenaire',
    plural_partner_name: 'Partenaires',
    partner_type: 'Société',
    the_partner_type: 'la société',
    of_the_partner_type: 'de la société',
    partner_type_plural: 'Sociétés',
    the_partner_type_plural: 'les sociétés',
    plural_sponsors_name: 'Sponsors',
  },
}

const expectedKeys = Object.keys(initialState.theme) as (keyof WordTheme)[]

const wordThemeSlice = createSlice({
  name: 'wordTheme',
  initialState,
  reducers: {
    set: (state, action: PayloadAction<WordTheme>) => {
      state.theme = action.payload
    },
  },
})

export const wordThemeActions = wordThemeSlice.actions

export const selectWordTheme = (state: RootState) => state.wordTheme.theme

export const syncWordTheme = () => async (dispatch: AppDispatch, getState: () => RootState) => {
  const {tokenUser, club} = getState().connexionStatusRedux
  if (!tokenUser || !club) return

  const clubWordTheme = await wordThemeApi.getWordTheme(tokenUser, club.id)

  // verify all keys are available
  const newKeys = Object.keys(clubWordTheme)
  const missingKeys = expectedKeys.filter((k) => !newKeys.includes(k))

  if (missingKeys.length > 0) {
    logger.warn('Word Theme received from server is missing some keys.', missingKeys)
    missingKeys.forEach((k) => {
      clubWordTheme[k] = initialState.theme[k]
    })
  }
  dispatch(wordThemeActions.set(clubWordTheme))
}

export default wordThemeSlice.reducer
