import type {ApiGroupConversation} from './conversationsApi'
import type {Feature} from '../hooks/useFeatures'

export enum MessageType {
  MESSAGE = 1,
  PICTURE = 2,
}

export type Message = EntityFor['messaging']['Message'] & {notificationId?: string}
export type Account = {
  id: number
  authkey: string
  user: {
    firstName: string
    lastName: string
    avatar: unknown
  }
}
type AllMessageProps = {
  id?: number
  // uuid of the MongoDB conversation
  uuid: string
  // Text Message = 1, Picture Message = 2
  type: MessageType | string
  // ???
  timestamp: number
  // ???
  serverTimestamp?: number
  // accountId of the sender of the message
  source: number
  // deviceId of the sender of the message
  sourceDevice: number
  // group conversation id
  convId: number
  // the message string
  message?: string
  // ???
  content?: string
  status?: 'pending' | 'sent' | 'received' | 'error'
}
type AllClubFeatureProps<X extends keyof EntityFor> = {
  club: EntityFor[X]['Club']
  featureKey: Feature
}
type AllEventProps<X extends keyof EntityFor> = {
  id: number
  title: string
  beginDate: string | Date
  endDate: string | Date
  picture: string
  content: string
  club: EntityFor[X]['Club']
}

type AllClubProps<X extends keyof EntityFor> = {
  id: number
  enabled: boolean
  name: string
  avatar: string
  email: string
  offerModel: number
  domainName: string
  primaryColor: string
  secondaryColor: string
  adFrequence?: number
  features: EntityFor[X]['ClubFeature'][]
  filename_sponsor?: string
}
type AllConversationGroupProps<X extends keyof EntityFor> = {
  id: number
  name: string
  lastMessageAccountId?: number
  lastMessageDate?: string
  lastMessageRead: boolean
  picture?: string
  club?: EntityFor[X]['Club']
  accounts: EntityFor[X]['ConversationGroupAccount'][]
  isGroup: boolean
}
type AllConversationGroupAccountProps<X extends keyof EntityFor> = {
  id: number
  lastMessageRead: boolean
  account: EntityFor[X]['Account']
  conversationGroup: EntityFor[X]['ConversationGroup']
}
type AllUserProps<X extends keyof EntityFor> = {
  id: number
  enabled: boolean
  firstName: string
  lastName: string
  email: string
  company: EntityFor[X]['Company']
  position: string
  avatar: string
  phone_number?: string
  linkedin_url?: string
  description?: string
  websiteUrl?: string
  accounts: EntityFor[X]['Account'][]
  deviceType: 'web' | 'ios' | 'android'
  deviceToken: string
}
type AllCompanyProps<X extends keyof EntityFor> = {
  id: number
  logo: string
  subtitle?: string
  name: string
  sector: {
    libelle: string
  }
  phone: string
  websiteUrl: string
  linkedin_url: string
  email: string
  address: string
  city: string
  users: EntityFor[X]['User'][]
  clubs: EntityFor[X]['Club'][]
}
type AllAccountProps<X extends keyof EntityFor> = {
  id: number
  enabled: boolean
  connected?: boolean
  authkey: string
  user: EntityFor[X]['User']
}

type AllPostCommentLikeProps<X extends keyof EntityFor> = {
  id: number
  createDate: string
  postComment: EntityFor[X]['PostComment']
  user: EntityFor[X]['User']
}

type AllPostCommentProps<X extends keyof EntityFor> = {
  id: number
  date: string
  comment: string
  user: EntityFor[X]['User']
  post: EntityFor[X]['Post']
  likes: EntityFor[X]['PostCommentLike'][]
  tags: unknown
  mentionedUsers: unknown
}

type AllPostProps<X extends keyof EntityFor> = {
  id: number
  createDate: string
  content: string
  attachment: string
  club: EntityFor[X]['Club']
  manager: {
    id: number
  }
  user: EntityFor[X]['User']
  postType: {
    id: number
    libelle: string
  }
  mentionedUsers: EntityFor[X]['User'][]
  comments: EntityFor[X]['PostComment'][]
  likes: [
    {
      id: number
      createDate: string
      user: {
        id: number
        firstName: string
        lastName: string
        position: string
        avatar: string
        accounts: [
          {
            id: number
            enabled: true
          }
        ]
      }
    }
  ]
}

type AllNotificationProps<X extends keyof EntityFor> = {
  id: number
  enabled: boolean
  date: Date
  user: EntityFor[X]['User']
  notificationType: {id: number; libelle: string}
  post?: EntityFor[X]['Post']
  postComment?: EntityFor[X]['PostComment']
  event?: EntityFor[X]['OneEvent']

  document?: {id: number}
  picture?: {id: number}
  gallery?: {id: number}
  sender: EntityFor[X]['User']
  pushed: boolean
}
type AllOneEventProps<X extends keyof EntityFor> = {
  id: number
  title: string
  beginDate: unknown
  endDate: unknown
  picture: string
  content: string
  club: EntityFor[X]['Club']
}

export type EntityFor = {
  messaging: {
    User: Pick<AllUserProps<'messaging'>, 'id' | 'enabled' | 'lastName' | 'firstName' | 'avatar' | 'company' | 'position' | 'phone_number' | 'accounts'>
    Company: Pick<AllCompanyProps<'messaging'>, 'id' | 'logo' | 'subtitle' | 'name'>
    Account: Pick<AllAccountProps<'messaging'>, 'id' | 'user' | 'connected'>
    ConversationGroup: AllConversationGroupProps<'messaging'>
    ConversationGroupAccount: Pick<AllConversationGroupAccountProps<'messaging'>, 'account' | 'lastMessageRead'>
    Message: AllMessageProps
    Club: Pick<AllClubProps<'messaging'>, 'id' | 'name' | 'email' | 'offerModel' | 'adFrequence'>
    ClubFeature: never
    Event: never
    Notification: never
    Post: never
    PostComment: never
    PostCommentLike: never
    OneEvent: never
  }
  notifications: {
    User: Pick<AllUserProps<'notifications'>, 'id' | 'enabled' | 'lastName' | 'firstName' | 'company' | 'avatar'>
    Account: AllAccountProps<'notifications'>
    GroupAccount: never
    Company: Pick<AllCompanyProps<'notifications'>, 'id' | 'name' | 'clubs' | 'logo'>
    OneEvent: AllOneEventProps<'notifications'>
    Club: Pick<AllClubProps<'notifications'>, 'id' | 'name' | 'email' | 'primaryColor' | 'secondaryColor' | 'avatar'>
    ClubFeature: never
    Notification: AllNotificationProps<'notifications'>
    Post: AllPostProps<'notifications'>
    PostComment: never
    PostCommentLike: never
    Event: never
    ConversationGroup: never
    ConversationGroupAccount: never
  }
  api_list: {
    Event: Pick<AllEventProps<'api_list'>, 'id' | 'beginDate' | 'endDate' | 'content' | 'title' | 'picture'>
    User: never
    Company: never
    Account: Pick<AllAccountProps<'api_list'>, 'id' | 'enabled'>
    Club: Pick<AllClubProps<'api_list'>, 'id' | 'name' | 'email' | 'offerModel' | 'adFrequence' | 'avatar'>
    ClubFeature: never
    Notification: never
    Post: never
    PostComment: never
    PostCommentLike: never
    OneEvent: never
    ConversationGroup: never
    ConversationGroupAccount: Pick<AllConversationGroupAccountProps<'api_list'>, 'account'>
  }
  single_company: {
    Company: Omit<AllCompanyProps<'single_company'>, 'siret' | 'style' | 'enabled'>
    User: Omit<AllUserProps<'single_company'>, 'company'>
    Club: Pick<AllClubProps<'single_company'>, 'name' | 'email' | 'offerModel' | 'adFrequence'>
    Account: Pick<AllAccountProps<'single_company'>, 'id' | 'enabled'>
    ClubFeature: never
    Notification: never
    Post: never
    PostComment: never
    PostCommentLike: never
    OneEvent: never
    ConversationGroup: never
    ConversationGroupAccount: never
  }
}

// export type Conversation = Omit<ApiConversation, 'user'> & {
//   user: Record<number, EntityFor['messaging']['Account']>
// }
export type Conv = ApiGroupConversation & {
  // array of id: account
  // can use accounts[account.id] to get the object
  accounts: ConvAccount[]
  otherAccountId?: number
}

export type ConvAccount = EntityFor['messaging']['Account']
export type GroupConversations = Record<number, Conv>

export const isGroupConversation = (c: Conv): c is Conv => {
  return c && c.isGroup
}
