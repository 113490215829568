import {AnyAction, createSlice, PayloadAction, ThunkAction} from '@reduxjs/toolkit'
import type {Feature} from '../hooks/useFeatures'
import type {AppDispatch, RootState} from '../../bridge/reduxStore'
import clubApi from '../api/clubApi'
import {logger} from '../../services/logger'

export type FeatureState = {
  club: Feature[]
}

const initialState: FeatureState = {
  club: [],
}

const featureSlice = createSlice({
  name: 'features',
  initialState,
  reducers: {
    setClubFeatures: (state, action: PayloadAction<Feature[]>) => {
      state.club = action.payload
    },
  },
})

export const featureSliceActions = featureSlice.actions

export const selectClubFeatures = (state: RootState) => state.features.club

export const syncFeatures =
  (clubId?: number): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    let id = clubId
    if (!id) {
      const {club} = getState().connexionStatusRedux
      id = club?.id
    }
    if (!id) {
      logger.info('cannot get club features, disabling.')
      dispatch(featureSliceActions.setClubFeatures([]))
      return
    }
    try {
      const res = await clubApi.getClubInfo(id)
      dispatch(featureSliceActions.setClubFeatures(res.features.map(({featureKey}) => featureKey)))
    } catch (e) {
      logger.error('An error occured getting club features.', e)
    }
  }

export default featureSlice.reducer
