import React, {memo, useCallback, useEffect, useState} from 'react'
import {useAppDispatch, useAppSelector} from '../../../reducers/hooks'
import {fetchConversations, messagingActions} from '../../../shared/redux/messaging.slice'
import {ZIndexLayers} from '../../../theme'
import ChatContainer from './ChatContainer'
import ChevronUpSvg from '../../../assets/svg/ChevronUpSvg'
import ChevronDownSvg from '../../../assets/svg/ChevronDownSvg'

export const CollapsibleChat = memo(() => {
  const dispatch = useAppDispatch()
  const messaging = useAppSelector((state) => state.messagingR)

  const [isOpen, setIsOpen] = useState<boolean>(!!messaging.activeConvId)

  const toggleMessages = useCallback(() => {
    // noinspection PointlessBooleanExpressionJS
    const isClosing = isOpen === true

    // const isOpening = next === true
    if (!isOpen) {
      // this.props.socket.emit('getAllConvForOneUser', this.props.user.id);
    }
    if (isClosing) {
      if (messaging.activeConvId) dispatch(messagingActions.setActiveConv())
    } else {
      dispatch(messagingActions.startConnecting())
    }

    setIsOpen(!isOpen)
    // this.props.socket.emit('allMessagesRead', this.props.user.id);
  }, [isOpen, messaging.activeConvId])

  useEffect(() => {
    if (messaging.activeConvId && !isOpen) {
      if (window.innerWidth > 576) {
        toggleMessages()
      }
    }
  }, [messaging.activeConvId, isOpen])

  useEffect(() => {
    dispatch(fetchConversations())
  }, [])

  return (
    <div id="messageBlock" className="container-fluid position-fixed col-md-4 col-lg-3" style={{bottom: -10, left: 0, zIndex: ZIndexLayers.ChatBox}}>
      <div className="card w-100 bg-light shadow-lg">
        <header className="card-header message-header rounded message-profile bg-transparent" style={{cursor: 'pointer'}} onClick={() => toggleMessages()}>
          <div className="text-center pt-1">
            <p style={{fontSize: 16}}>
              <strong className="position-relative">
                <span className="position-absolute badge badge-pill badge-danger rounded-circle" style={{top: -15, right: -20, fontSize: 14}}>
                  {messaging.haveNewMessages || ''}
                </span>
                Messages
              </strong>
            </p>
          </div>
          {isOpen ? <ChevronDownSvg style={{top: 20, right: 20}} /> : <ChevronUpSvg style={{top: 18, right: 20}} />}
        </header>
        {isOpen && <ChatContainer />}
      </div>
    </div>
  )
})
