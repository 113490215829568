import React, { Component } from 'react';
import api from '../../../services/api';
import {withRouter} from 'react-router-dom';
import { API_PASSWORD_FORGOT_RESET, API_TEST_IF_RESET_PASSWORD_WORK } from '../../../services/constants';
import queryString from 'query-string';
import {connect} from 'react-redux';
import {logger} from "../../../services/logger";

class NewPassword extends Component {
    constructor() {
        super();
        this.state = {
            pwd: "",
            confirmed_pwd: "",
            good_to_reset: false,
        }
        this.handleChange = this.handleChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    handleChange = (name, data) => {
        document.querySelector('#new_password').classList.remove('is-invalid');
        document.querySelector('#new_password_confirm').classList.remove('is-invalid');
        this.setState({[name]: data, });
    }

    errorHandle = () => {
        if (!this.state.pwd || this.state.pwd === "") {
            document.querySelector('#new_password').classList.add('is-invalid');
            return true;
        }
        if (!this.state.confirmed_pwd || this.state.confirmed_pwd === "") {
            document.querySelector('#new_password_confirm').classList.add('is-invalid');
            return true;
        }
        if (this.state.pwd !== this.state.confirmed_pwd) {
            document.querySelector('#new_password').classList.add('is-invalid');
            document.querySelector('#new_password_confirm').classList.add('is-invalid');
            return true;
        }
        return false;
    }

    onSubmit = (e) => {
        e.preventDefault();
        const {email, id_forget_password, token, club_id} = this.state;
        if (this.errorHandle()) {
            return;
        }
        api.post(API_PASSWORD_FORGOT_RESET(email, id_forget_password, token, club_id), {password: this.state.pwd})
        .then(res => {
            this.setState({password_send: true, })
            setTimeout(this.props.history.push('/login'), 3000)
        })
        .catch(err => {
            logger.debug("Send new password ERROR: ", err);
        })
    }

    componentWillReceiveProps(newProps) {
        const params = queryString.parse(this.props.location.search);
        const {club_id} = newProps;

        if (club_id) {
            api.get(API_TEST_IF_RESET_PASSWORD_WORK(params.email, params.id, params.token, club_id))
            .then(res => {
                this.setState({
                    pwd: "",
                    confirmed_pwd: "",
                    email: params.email,
                    id_forget_password: params.id,
                    token: params.token,
                    club_id: club_id,
                    good_to_reset: true
                })
            })
            .catch(err => {
                console.error('Get if url is valid ERROR: ', err);
                this.setState({good_to_reset: false,});
            })
        }
    }

    componentDidMount() {
        const params = queryString.parse(this.props.location.search);
        const {club_id} = this.props;

        if (club_id) {
            api.get(API_TEST_IF_RESET_PASSWORD_WORK(params.email, params.id, params.token, club_id))
            .then(res => {
                this.setState({
                    pwd: "",
                    confirmed_pwd: "",
                    email: params.email,
                    id_forget_password: params.id,
                    token: params.token,
                    club_id: club_id,
                    good_to_reset: true
                })
            })
            .catch(err => {
                console.error('Get if url is valid ERROR: ', err);
                this.setState({good_to_reset: false,});
            })
        }
    }

    render() {
        return (
            <div className="header-wrapper login-header d-flex vh-100 w-100 bg-dark align-items-center justify-content-center">
                <div className="container">
                    <div className="row m-h-100 ">
                        <div className="col-md-4 m-auto">
                            <div className="card shadow-lg ">
                                <div className="card-body ">
                                    {
                                        this.state.good_to_reset &&
                                        <h4 className="text-center py-2">Entrez votre nouveau mot de passe</h4>
                                    }
                                    {
                                        this.state.password_send &&
                                        <p className="text-center">Votre mot de passe à bien été changé, vous allez être redirigé dans 3 secondes...</p>
                                    }
                                    {
                                        this.state.good_to_reset ?
                                        <form name="form" method="post">
                                            <div className="form-group">
                                                <label htmlFor="new_password">Mot de passe</label>
                                                <input type="password" id="new_password" name="mail" required="required"
                                                        value={this.state.pwd} placeholder="Nouveau mot de passe" className="form-control" onChange={(e)=>this.handleChange("pwd", e.target.value)}/>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="new_password">Confirmer le mot de passe</label>
                                                <input type="password" id="new_password_confirm" name="mail" required="required"
                                                        value={this.state.confirmed_pwd} placeholder="Confirmer mot de passe" className="form-control" onChange={(e) => this.handleChange("confirmed_pwd", e.target.value)} />
                                            </div>
                                            <div className="form-group">
                                                <button type="submit" className="btn btn-primary btn-block btn-lg" onClick={this.onSubmit}>Confirmer</button>
                                            </div>
                                        </form>
                                        :
                                        <div className="w-100">
                                            <p className="text-center">Ce lien n'est plus valide...</p>
                                            <button type="button" className="btn btn-primary btn-block btn-lg ml-auto" onClick={(e) => this.onSubmit(e)}>Redirection vers page de connexion.</button>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    club_id: state.clubState.club_id
})

export default withRouter(connect(mapStateToProps)(NewPassword));
