import React from 'react'
import {withRouter} from 'react-router-dom'
import MobileFooter from '../component/footer/MobileFooter'
import {NavBar} from '../component/header'
import {useAppSelector} from '../reducers/hooks'
import {selectConnexionStatus} from '../shared/redux/connexionStatus.slice'

const NotFound = () => {
  const {isSignIn} = useAppSelector(selectConnexionStatus)
  return (
    <div className="bg-welcome w-100 h-100" style={{position: 'absolute'}}>
      {isSignIn && <NavBar />}
      <div className="page">
        <div className="container-fluid px-md-4" style={{color: 'white'}}>
          <h1>Oops! Cette page n'existe pas.</h1>
          {isSignIn && <p>Vous pouvez cliquer sur un lien du menu pour retrouver votre chemin.</p>}
          {!isSignIn && (
            <p>
              Authentifiez-vous{' '}
              <a href="/login" title="login" style={{color: 'white', textDecoration: 'underline'}}>
                ici
              </a>
              .
            </p>
          )}
        </div>
      </div>
      {isSignIn && <MobileFooter />}
    </div>
  )
}

export default withRouter(NotFound)
