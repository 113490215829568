import React from 'react';
import RouterModule from '../services/router';
import { getToken, removeToken } from '../actions/apiAction';
import { connect } from 'react-redux';
import { companyLoad } from '../actions/companyAction';
import { userActions } from "../reducers/userReducer";
import { syncWordTheme } from "../shared/redux/wordThemeSlice";
import { messagesRActions } from "../reducers/messagesReducer";
import { featureSliceActions, syncFeatures } from "../shared/redux/feature.slice";
import { logger } from "../services/logger";
import userApi from "../shared/api/userApi";
import clubApi from "../shared/api/clubApi";
import { UserNotAuthenticatedError } from "../services/api";
import { clubSliceActions } from "../reducers/clubSlice";
import { connexionStatusActions } from "../shared/redux/connexionStatus.slice";
import clubConfigApi from "../api/clubConfigApi";
import { hostname } from "../services/url";

class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            stylePath: "",
            loading: true,
            no_club: false
        };
        this.getTokenFromLocal = this.getTokenFromLocal.bind(this);
    }

    hideLoaderAfterMs (ms) {
      var props = this.props
      setTimeout(function () {
        props.hideLoader()
      }, ms)
    }

    componentDidMount() {
        this.getTokenFromLocal()
        this.props.syncWordTheme()
        var club;
        //console.log("load config file", `${window.location.origin}/config/config.json`);
        clubConfigApi.getClubConfigs()
        .then(config => {
            for (club in config) {
              const clubConfig = config[club]
                if (clubConfig['subdomain_url'] === hostname) {
                  club = clubConfig;
                    var css = "assets/css/" + club.css_filename + (club.css_filename.includes('.css') ? "" : ".css");
                    this.setState({stylePath: css, loading: false});
                    clubApi.getClubInfo(club.club_id)
                    .then(res => {
                        logger.debug('DEBUG - Getting club data', res);
                        this.props.clubLoad(res);
                        this.props.setClubFeatures(res.features.map(({featureKey}) => featureKey));
                        // this.props.setClubFeatures(res.data.features.map(({featureKey}) => featureKey));
                    })
                    .catch(err => {
                      logger.error("Getting club info from API ERROR: ", err);
                    })
                    return;
                }
            }
            this.setState({no_club: true, });
        })
        .catch(err => {
            this.setState({no_club: true, });
          logger.error("Loading config.json ERROR: ", err);
        })
    }

    getTokenFromLocal = async () => {
        if (this.props.user.id === 0 && !this.props.config.headers.Authorization) {
            try {
                const token = JSON.parse(localStorage.getItem('Authorization'));
                const user = JSON.parse(localStorage.getItem('User'));
                logger.debug("DEBUG - Token saved in local", token);
                logger.debug("DEBUG - User saved in local", user);
                if (token && token !== "" && user) {
                    this.props.loadToken(token);
                    logger.debug('DEBUG - User id to get data: ', user.id);
                    try {
                        const res = await userApi.getUserInfo()
                        logger.debug('DEBUG - Getting user data: ', res);
                        this.props.userLoad(res);
                        this.props.companyLoad(res.company);

                        const deviceId = JSON.parse(localStorage.getItem('Device'))
                        const account = JSON.parse(localStorage.getItem('Account'))
                        const club = JSON.parse(localStorage.getItem('Club'))

                        this.props.onConnexion({
                          idUser: res.id,
                          tokenUser: token,
                          deviceId,
                          account,
                          user,
                          club,
                        })

                    } catch (err) {
                      if (err instanceof UserNotAuthenticatedError) {
                        this.props.removeToken()
                        this.props.resetUser()
                        localStorage.removeItem('User')
                      } else {
                        logger.error(`Get user on Load ERROR:
${err.stack}`,
                            JSON.stringify(err, undefined, 2));

                      }
                    }
                } else {
                    this.props.companyLoad({ rank: 0 });
                }
            } catch (err) {
                logger.error("Get data from local ERROR: ", err);
            } finally {
              this.hideLoaderAfterMs(200)
            }
        }
    }


    render() {
        return (
            <div>
                {
                    this.state.no_club &&
                    <div className="container">
                        <p className="alert alert-danger my-2" role="alert">Club doesn't exist</p>
                    </div>
                }
                {
                    this.state.loading && !this.state.no_club &&
                    <div className="container">
                        <p className="alert alert-dark" role="alert">Loading...</p>
                    </div>
                }
                {
                    !this.state.loading &&
                    <>
                        <link rel="stylesheet" type="text/css" href={this.state.stylePath} />
                        {
                            this.props.company.rank !== undefined &&
                            <RouterModule />
                        }
                    </>
                }
            </div>
        );
    }
}

const mapStateToProps = state => ({
    user: state.userR,
    company: state.companyR,
    config: state.apiR,
    socket: state.conversationsR.socket,
})

const mapDispatchToProps = dispatch => ({
    loadToken: (payload) => dispatch(getToken(payload)),
    removeToken: (payload) => dispatch(removeToken(payload)),
    userLoad: (payload) => dispatch(userActions.userLoad(payload)),
    resetUser: () => dispatch(userActions.reset()),
    companyLoad: (payload) => dispatch(companyLoad(payload)),
    syncWordTheme: () => dispatch(syncWordTheme()),
    syncFeatures: () => dispatch(syncFeatures()),
    connectedUsers: (payload) => dispatch(messagesRActions.connectedUsersUpdate(payload)),
    onConnexion: (payload) => dispatch(connexionStatusActions.onConnexion(payload)),
    clubLoad: (payload) => dispatch(clubSliceActions.clubLoad(payload)),
    setClubFeatures: (payload) => dispatch(featureSliceActions.setClubFeatures(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Index);
