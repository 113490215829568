import React from 'react';
import CardHeaders from '../card_header';
import { Avatar } from '../../../../component/user';
import { dateFormaterPost } from '../../../../services/utils';
import {logger} from "../../../../services/logger";

const AdPostBody = ({ post }) => {
    return (
        <div className="container">
            <div className="cards_author d-flex align-items-center w-100">
                <div className="col-9 p-0 d-flex align-items-center">
                    <div className="avatar-edit mr-3">
                        <Avatar alt={post.name && post.name[0]} img={post.logo} size="sm" />
                    </div>
                    <div className="user-profile-name flex-column">
                        <p className="text-primary-darker m-0"><strong>{post.name}</strong></p>
                        {
                            post.createDate &&
                            <p className="text-primary m-0" style={{fontSize: 10}}>{dateFormaterPost(post.createDate)}</p>
                        }
                    </div>
                </div>
                <div className="ml-auto badge badge-primary p-1">
                    Sponsorisé
                </div>
            </div>
            <div className="cards-content py-3">
                {post.content}
            </div>
            {
                post.advertLink && post.advertLink.match(/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/i) &&
                <div className="cards-footer d-flex justify-content-between align-items-center">
                    <div className="replay-btn w-100 text-right">
                        <a href={post.advertLink} target="_blank" rel="noopener noreferrer"><button type="button" className="btn btn-outline-primary p-0">Voir</button></a>
                    </div>
                </div>
            }
        </div>
    )
}

const AdPost = ({ post }) => {
    if (!post.advertType) {
        // ! Code AP-001
        logger.debug('DEBUG -- Une erreur est survenue... Code AP-001');
        return null;
    }

    return (
        <div style={{marginBottom: 2.7 + 'rem'}}>
            <div className={"card mt-3 mb-n1 post-type-" + post.advertType.libelle} style={{zIndex:1}}>
                <CardHeaders type='ad' post_type={post.advertType.libellus} attachment={post.attachment} />
                <div className="card-body p-2">
                    <AdPostBody post={post} />
                </div>
            </div>
        </div>
    );
};

export default AdPost;
