import React, {useCallback, useEffect, useState} from 'react'
import LoadingScreen from 'react-loading-screen'
import Conversation from '../conversations/Conversation'
import MessageInput from '../conversations/MessageInput'
import {useAppSelector} from '../../../shared/redux/reduxHooks'
import {selectMessaging} from '../../../shared/redux/messaging.slice'
import {selectConnexionStatus} from '../../../shared/redux/connexionStatus.slice'
import {ConvList} from './ConvList'

const ChatContainer = () => {
  const {account} = useAppSelector(selectConnexionStatus)
  const club = useAppSelector((state) => state.clubState)

  const {activeConvId, timestamp, isLoadingConversations} = useAppSelector(selectMessaging)
  const [height, setHeight] = useState(window.innerHeight)

  const onWindowResize = useCallback(() => {
    setHeight(window.innerHeight)
  }, [])
  useEffect(() => {
    window.addEventListener('resize', onWindowResize)
    return () => window.removeEventListener('resize', onWindowResize)
  }, [])

  const isLoading = timestamp === 0 && (isLoadingConversations || !account)

  const maxHeight = height - (window.innerWidth < 576 ? 150 : 230)

  return (
    <div id="messagesBox" className="card card-body mt-n3 mx-1" style={{minHeight: 250}}>
      <LoadingScreen loading={isLoading} bgColor="#f8f9fa" spinnerColor={club.club_color} logoSrc={club.club_image}>
        {activeConvId ? (
          <div style={{height: 'auto', maxHeight, overflowX: 'hidden'}}>
            <Conversation maxHeight={height} />
            <MessageInput />
          </div>
        ) : (
          <ConvList maxHeight={maxHeight} />
        )}
      </LoadingScreen>
    </div>
  )
}

export default ChatContainer
