import {useCallback, useEffect, useRef, useState} from 'react'
import {HttpError, UnknownError, UserFacingError} from './errors'

export type ErrCodes = 'InvalidInvitation' | 'AlreadyRegistered' | 'NotFound'
export type ErrResponse = {response?: {status: number; data: {errCode: ErrCodes}}}
export type UseApiError = ErrResponse | HttpError | UserFacingError | UnknownError | string | null
export const isErrorWithResponse = (e: UseApiError): e is ErrResponse => {
  // @ts-ignore
  return e?.response !== undefined
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type UseApi<BODY extends any[], RES> = {
  call: (...args: BODY) => Promise<void>
  isLoading: boolean
  error?: UseApiError | null
  res?: RES
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useApi<BODY extends any[], RES>(
  apiCall: (...args: BODY) => Promise<RES>,
  onSuccess?: (res: RES) => void,
  onError?: (e: UseApiError) => void
): UseApi<BODY, RES> {
  // one way to memoize the input callbacks...
  // (passing a callback in a deps array results in infinite loops unless that callback was well and truly already wrapped in a useCallback)
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<UseApiError>(null)
  const [res, setRes] = useState<RES>()
  const isAlive = useRef<boolean>(true)

  // on cleanup
  useEffect(() => {
    isAlive.current = true
    return () => {
      isAlive.current = false
    }
  }, [])

  const reset = useCallback(() => {
    setRes(undefined)
    setError(null)
    setIsLoading(false)
  }, [])

  const call = useCallback(
    async (...body: BODY) => {
      if (isLoading || !isAlive.current) return

      reset()

      setIsLoading(true)
      setError(null)

      try {
        const r = await apiCall(...body)
        if (isAlive.current) {
          setRes(r)
        }
      } catch (err) {
        if (isAlive.current) setError(err)
      } finally {
        if (isAlive.current) {
          setIsLoading(false)
        }
      }
    },
    [apiCall, isLoading]
  )

  useEffect(() => {
    if (error && onError && isAlive.current) onError(error)
    // we don't want to call the onError if onError is refreshed
  }, [error])

  useEffect(() => {
    if (res && onSuccess && isAlive.current) onSuccess(res)
    // we don't want to call the onSuccess if onSuccess is refreshed
  }, [res])

  return {
    call,
    isLoading,
    error,
    res,
  }
}
