import React from 'react';
import logger from "../../services/logger";


const PageNoResult = ({ onReturn }) => {
  logger.debug('DEBUG -- Une erreur est survenue... Code GS-001');

  return (
    <div className="card group card-active group p4">
      <div className="position-absolute w-50 clearfix text-right" style={{height: 35, zIndex: 10, right: 20, paddingRight: 20, paddingTop: 20}}>
        <a className="m-2 text-primary bg-light rounded p-1" style={{zIndex: 9, fontWeight: 800}} onClick={onReturn}>
          Retour
        </a>
      </div>
      <div className="card-body">
        <p>Aucun résultat</p>
      </div>
    </div>
  );
};

export default PageNoResult;
