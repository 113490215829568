/* eslint-disable jsx-a11y/label-has-associated-control */
import React, {useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {RouteComponentProps} from 'react-router'
import {withRouter, useLocation} from 'react-router-dom'
import {FormControl, InputLabel, MenuItem, Select, TextField, Button, Alert} from '@mui/material'
import * as yup from 'yup'
import {useAppSelector} from '../../reducers/hooks'
import {useFeatures} from '../../shared/hooks/useFeatures'
import BasePageStructure from '../BasePageStructure'
import {Documents} from './Documents'

import {useApi} from '../../shared/api/useApi'
import galleriesApi from '../../shared/api/galleriesApi'
import {DataAddDocument, GalleryType} from '../../types/GalleryType'
import documentApi from '../../api/documentApi'
import {selectConnexionStatus} from '../../shared/redux/connexionStatus.slice'

export const formSchema = yup.object({
  folder: yup.string().nullable(),
  title: yup.string().max(255, 'Le titre ne peut pas dépasser 255 caractères').required('Le titre est obligatoire').trim(),
  description: yup.string().nullable().max(255, 'La description ne peut pas dépasser 255 caractères').trim(),
  file: yup.string().required('Le fichier est obligatoire'),
})

const DocumentsPage = ({history}: RouteComponentProps) => {
  const {hasFeatures} = useFeatures()

  const clubState = useAppSelector((state) => state.clubState)
  const {tokenUser, club} = useAppSelector(selectConnexionStatus)

  const [isShown, setIsShown] = useState<boolean>(false)
  const [isAlert, setIsAlert] = useState<boolean>(false)

  const [folders, setFolders] = useState([])
  const [currentErrors, setCurrentErrors] = useState([])

  const {search} = useLocation()
  let selectedDocumentId: number | undefined
  try {
    const id = new URLSearchParams(search).get('id')
    if (id) {
      selectedDocumentId = parseInt(id, 10)
    }
  } catch (e) {
    // do nothing
  }

  const [data, setData] = useState({
    selectedFolder: '',
    title: '',
    description: '',
    file: '',
  })

  const getApiDocs = useApi(
    galleriesApi.getDocuments,
    (res) => {},
    (err) => {
      // ! Code An-200
      console.error('Get all the documents of the club ERROR: Code An-200', err)
    }
  )

  useEffect(() => {
    if (tokenUser && club) getApiDocs.call(tokenUser, club.id)
  }, [tokenUser, club?.id])

  const getGalleriesApi = useApi(
    galleriesApi.getGalleries,
    (res) => {
      setFolders(res.data)
    },
    (err) => {
      // ! Code An-200
      console.error('Get all the folders of the club ERROR: Code An-200', err)
    }
  )

  useEffect(() => {
    if (clubState && hasFeatures('ged')) {
      getGalleriesApi.call(clubState.club_id)
    }
  }, [clubState, hasFeatures])

  if (!hasFeatures('ged')) {
    history.push('/')
    return null
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const changeValue = (field: string, val: any) => {
    const newData = {...data}
    newData[field as keyof DataAddDocument] = val
    setData(newData)
  }

  const openModal = () => setIsShown(true)

  const closeModal = () => setIsShown(false)

  useEffect(() => {
    if (!isShown) {
      setData({
        selectedFolder: '',
        title: '',
        description: '',
        file: '',
      })
    }
  }, [isShown])

  const submitForm = () => {
    formSchema
      .validate(data, {abortEarly: false})
      .then(() => {
        setCurrentErrors([])
        const formData = new FormData()
        formData.append('title', data.title)
        formData.append('description', data.description)
        formData.append('file', data.file)
        formData.append('gallerie', data.selectedFolder)
        formData.append('club_id', `${clubState.club_id}`)

        return documentApi.addDocument(formData)
      })
      .then(() => {
        setIsShown(false)
        setIsAlert(true)
        if (tokenUser && club?.id) getApiDocs.call(tokenUser, club.id)
      })
      .catch((err) => {
        setCurrentErrors(err.errors)
      })
  }

  return (
    <BasePageStructure>
      {isAlert && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}>
          <Alert
            style={{
              width: '30%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            severity="success">
            Votre document a bien été crée !
          </Alert>
        </div>
      )}

      <div className="d-flex flex-column flex-sm-row justify-content-between align-items-center">
        <h3 className="text-light m-0">Bibliothèque</h3>
        {hasFeatures('ged_user_add_document') && (
          <Button color="inherit" variant="contained" onClick={openModal}>
            Ajouter un document
          </Button>
        )}
        <Modal show={isShown} onHide={() => setIsShown(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Ajouter un document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {folders.length > 0 && (
              <FormControl style={{width: '100%', color: `${clubState.club_color}`}} size="small">
                <InputLabel id="demo-select-small">Dossier</InputLabel>
                <Select
                  labelId="demo-select-small"
                  id="demo-select-small"
                  label="Dossier"
                  onChange={(e) => changeValue('selectedFolder', e.target.value)}
                  style={{width: '100%'}}
                  size="small">
                  <MenuItem sx={{borderColor: ''}} value="">
                    Aucun dossier sélectionné...
                  </MenuItem>
                  {folders.map((folder: GalleryType) => (
                    <MenuItem key={`mi${folder.id}`} value={folder.id}>
                      {folder.title}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            <TextField
              id="outlined-error-small"
              label="Titre *"
              onChange={(e) => changeValue('title', e.target.value)}
              placeholder="Titre du document..."
              style={{width: '100%', marginTop: 20, color: `${clubState.club_color}`}}
              size="small"
            />
            <TextField
              id="outlined-error-small"
              label="Description"
              onChange={(e) => changeValue('description', e.target.value)}
              placeholder="Description du document...."
              style={{width: '100%', marginTop: 20, color: `${clubState.club_color}`}}
              size="small"
            />
            <input type="file" name="file" accept=".pdf" onChange={(e) => changeValue('file', e.target.files?.[0])} style={{marginTop: 20}} />
            <div style={{paddingTop: 18}}>
              {currentErrors.map((e) => {
                return (
                  <div style={{color: 'red'}} key={e}>
                    {e}
                  </div>
                )
              })}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button color="inherit" variant="contained" onClick={closeModal}>
              Annuler
            </Button>
            <Button variant="contained" onClick={submitForm} style={{backgroundColor: `${clubState.club_color}`}}>
              Enregistrer
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
      <Documents getDocumentsApi={getApiDocs} selectedDocumentId={selectedDocumentId} />
    </BasePageStructure>
  )
}

export default withRouter(DocumentsPage)
