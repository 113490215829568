export const alreadyLikeFind = (likes, user_id) => {
    if (!likes || !user_id) {
        return false;
    }

    for (var i = 0; i < likes.length; i++) {
        if (likes[i].user.id === user_id) {
            return true;
        }
    }
    return false;
}

export const areEqualObjects = (obj1, obj2) => {
    var obj1Props = Object.getOwnPropertyNames(obj1);
    var obj2Props = Object.getOwnPropertyNames(obj2);

    if (obj1Props.length !== obj2Props.length) {
        return false;
    }

    for (var i = 0; i < obj1Props.length; i++) {
        var propName = obj1Props[i];

        if (obj1[propName] !== obj2[propName]) {
            return false;
        }
    }
    return true;
}

export const conversationExists = (send_id, dest_id, conv) => {
    if (conv.idUserOne === send_id && conv.idUserTwo === dest_id) {
        return true;
    }
    if (conv.idUserOne === dest_id && conv.idUserTwo === send_id) {
        return true;
    }
    return false;
}

export const imageAppendUrl = (url, image) => {
    if (!image) return undefined;
    if (image.match('http')) return image;
    return url + image
}

export const dateFormaterPost = (date_complete) => {
    const MONTHS = ["janvier", "février", "mars", "avril", "mai", "juin", "juillet", "août", "septembre", "octobre", "novembre", "décembre"];
    var [date, hour] = date_complete.split('T');
    const [year, month, day] = date.split('-');
    hour = hour.split(':', 2).join('h');
    date = day + ' ' + MONTHS[month - 1] + ' ' + year
    return (date + ' à ' + hour);
}

export function shuffle(a) {
    for (let i = a.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [a[i], a[j]] = [a[j], a[i]];
    }
    return a;
}

export const getLinks = (link) => {
    if (!link) {
        return null;
    }
    if (!link.match(/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/)) {
        return 'https://' + link;
    }
    return link;
}

export const formatLinks = (link) => {
    if (!link) {
        return null;
    }
    return link.replace('https://', '').replace('http://', '');
}
