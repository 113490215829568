import type {AnyAction, ThunkAction} from '@reduxjs/toolkit'
import type {AppDispatch, RootState} from '../../redux/store'
import loginApi from '../api/loginApi'
import {connexionStatusActions} from './connexionStatus.slice'
import {logger} from '../../services/logger'

export const refreshAuthTokenThunk = (): ThunkAction<void, RootState, unknown, AnyAction> => async (dispatch: AppDispatch, getState: () => RootState) => {
  try {
    const res = await loginApi.refreshAuthToken()
    dispatch(connexionStatusActions.updateAuthToken(res.token))
  } catch (e) {
    logger.error('An error occured refreshing auth token.', e)
  }
}
