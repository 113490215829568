import React, {useEffect} from 'react'
import {shallowEqual, useSelector} from 'react-redux'
import {BrowserRouter, Route, Switch} from 'react-router-dom'
import AnnuairePage from '../pages/annuaire/AnnuairePage'
import CalendarPage from '../pages/calendar/CalendarPage'
import DocumentsPage from '../pages/docs/DocumentsPage'
import {Faq} from '../pages/faq'
import {Gallery} from '../pages/gallery'
import Home from '../pages/home/home'
import Login from '../pages/login/login'
import newPassword from '../pages/login/password-reset/new-password'
import Logout from '../pages/logout/logout'
import MailLogin from '../pages/mail_login'
import NotFound from '../pages/NotFound'
import SignUp from '../pages/signup'
import {ApiConfState} from '../reducers/apiReducer'
import {useAppDispatch, useAppSelector} from '../reducers/hooks'
import {messagesRActions} from '../reducers/messagesReducer'
import {RootState} from '../redux/store'
import {selectConnexionStatus} from '../shared/redux/connexionStatus.slice'
import {syncFeatures} from '../shared/redux/feature.slice'
import {fetchConversations, messagingActions} from '../shared/redux/messaging.slice'
import PrivateRoute from './private_route'
import {FinalizeAccount} from '../pages/mail_login/FinalizeAccount'
import {CollapsibleChat} from '../component/messages/block/CollapsibleChat'
import {useFeatures} from '../shared/hooks/useFeatures'
import {refreshAuthTokenThunk} from '../shared/redux/auth.thunks'

const getAuthLevel = (config: ApiConfState, company: {rank: number}) => {
  let Authorized = 0
  if (company.rank === -1) {
    Authorized = -1
  }
  if (config.headers.Authorization) {
    Authorized = 1
  }
  if (Authorized && company.rank) {
    Authorized = 2
  }
  return Authorized
}

const RouterModule = () => {
  const dispatch = useAppDispatch()
  const config = useAppSelector((state) => state.apiR)
  const {club, isSignIn} = useAppSelector(selectConnexionStatus)
  const company = useAppSelector((state) => state.companyR)
  const [authLevel, setAuthLevel] = React.useState<number | undefined>(undefined)

  const {hasFeatures} = useFeatures()

  React.useEffect(() => {
    setAuthLevel(getAuthLevel(config, company))
  }, [config, company])

  useEffect(() => {
    if (club?.id) {
      dispatch(syncFeatures())
      dispatch(refreshAuthTokenThunk())
    }
  }, [club?.id])

  const messaging = useSelector((state: RootState) => state.messagingR, shallowEqual)

  useEffect(() => {
    dispatch(messagingActions.startConnecting())
  }, [])

  useEffect(() => {
    if (messaging.activeConvId) {
      if (window.innerWidth <= 476) {
        dispatch(messagesRActions.showDrawerMessages(true))
      }
    }
  }, [messaging.activeConvId])

  useEffect(() => {
    // console.log('messaging.need_update', messaging.need_update)
    if (messaging.shouldUpdateConvs) {
      dispatch(fetchConversations())
    }
  }, [messaging.shouldUpdateConvs])

  if (authLevel === undefined) {
    return <div>Chargement de votre page...</div>
  }

  return (
    <>
      <BrowserRouter>
        <Switch>
          <Route path="/login" component={Login} exact />
          <Route path="/forget_password" component={newPassword} exact />
          {/* Preserving for backwards compat (users still trying to authenticate using previously sent emails) */}
          <Route path="/mail_login" component={MailLogin} exact />
          <Route path="/finalize-account" component={FinalizeAccount} exact />
          <Route path="/signup" component={SignUp} />
          <PrivateRoute path="/logout" component={Logout} exact authorized={authLevel} rank={1} />
          <PrivateRoute path="/faq" component={Faq} exact authorized={authLevel} rank={2} />
          <PrivateRoute path="/" component={Home} exact authorized={authLevel} rank={2} />
          <PrivateRoute path="/annuaire" component={AnnuairePage} exact authorized={authLevel} rank={1} />
          <PrivateRoute path="/gallery" component={Gallery} exact authorized={authLevel} features={['gallery']} rank={1} />
          <PrivateRoute path="/bibliotheque" component={DocumentsPage} exact authorized={authLevel} features={['ged']} rank={1} />
          <PrivateRoute path="/calendar" component={CalendarPage} exact authorized={authLevel} rank={1} />
          <Route path="*" component={NotFound} />
        </Switch>
      </BrowserRouter>
      {isSignIn && hasFeatures('messages') && <CollapsibleChat />}
    </>
  )
}

export default RouterModule
