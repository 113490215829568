import {unauthedApi} from '../services/api'
import {logger} from '../services/logger'

export type ClubConfig = {
  club_id: number
  subdomain_url: string
  css_filename: string
  club_logo: string
  club_name: string
  primary_color: string
  secondary_color: string
  contact_email: string
  ad_frequence: number
}

const getClubConfigs = async (): Promise<Record<string, ClubConfig>> => {
  try {
    return (await unauthedApi.get('/fo-config.json')).data
  } catch (e) {
    logger.error('Could not get the club configurations', e)
    throw e
  }
}
export default {
  getClubConfigs,
}
