type TaskListener = {
  p: Promise<void>
  resolve: ((value: void | PromiseLike<void>) => void) | undefined
  reject: ((error?: Error) => void) | undefined
  cancel: () => void
  isCancelled: boolean
}

const createTaskListener = (): TaskListener => {
  let cancelled = false
  let resolve
  let reject

  const p = new Promise<void>((res, rej) => {
    resolve = res
    reject = rej
  })

  // return the ongoing connection
  return {
    p,
    resolve,
    reject,
    cancel: () => {
      cancelled = true
    },
    isCancelled: cancelled,
  }
}

/**
 * only execute a task once. If shared() is called multiple times,
 * the same shared result is returned, and the same promise is awaited.
 */
export default class SharedAsyncTask {
  private taskInProgress: TaskListener | undefined

  private inProgress = false

  public isRunning = () => this.inProgress

  public async shared(run: () => Promise<void>): Promise<void> {
    if (this.taskInProgress) {
      return this.taskInProgress.p
    }

    const theTask: TaskListener = createTaskListener()
    this.taskInProgress = theTask

    try {
      this.inProgress = true
      await run()
      if (theTask.isCancelled) {
        // do nothing.
        return Promise.resolve()
      }
      this.inProgress = false

      if (theTask.resolve) {
        theTask.resolve()
      }
    } catch (e) {
      this.inProgress = false
      if (theTask.reject) {
        theTask.reject(e)
      }
    } finally {
      this.taskInProgress = undefined
    }
    return Promise.resolve()
  }

  public cancel() {
    if (this.taskInProgress && this.inProgress) {
      this.taskInProgress.cancel()
      this.inProgress = false
      this.taskInProgress = undefined
    }
  }
}
