import {AnyAction, configureStore} from '@reduxjs/toolkit'
import thunk, {ThunkAction} from 'redux-thunk'
import {persistReducer, persistStore} from 'redux-persist'
import messagingSocketMiddleware from '../shared/ws/messagingSocket.middleware'
import reducers from '../reducers/index'
import {persistConfig} from './persistStoreConfig'

const persistedReducer = persistReducer(persistConfig.storeConfig, reducers)

export type RootState = ReturnType<typeof reducers>
const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk, messagingSocketMiddleware],
})
const persistor = persistStore(store)

export type AppDispatch = typeof store.dispatch
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, AnyAction>

export default {store, persistor}
