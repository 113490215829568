import React, {useCallback, useEffect, useState} from 'react'
import {authedApi} from '../../../services/api'
import {API_NOTIFICATIONS_ALL_LOAD, USER_IMAGE_UPLOAD_LINK} from '../../../services/constants'
import {logger} from '../../../services/logger'
import {imageAppendUrl} from '../../../services/utils'
import {useApi} from '../../../shared/api/useApi'
import NotificationElement from './NotificationElement'

type Notification = {
  notificationType: {libelle: string}
  enabled: boolean
  sender: {
    avatar: string
  }
  user: {
    avatar: string
  }
  id: number
}
const NotificationsDropdown = () => {
  const [notifs, setNotifs] = useState<Notification[]>([])
  const [isDropdownVisible, setIsDropDownVisible] = useState(false)

  const getAllNotifications = useApi(
    () => authedApi.get(API_NOTIFICATIONS_ALL_LOAD),
    (res) => {
      const notifications = res.data.map((notif: Notification) => {
        const newNotif = {...notif}
        if (notif.sender) {
          newNotif.sender = {
            ...notif.sender,
            avatar: imageAppendUrl(USER_IMAGE_UPLOAD_LINK, notif.sender.avatar),
          }
        }
        if (notif.user) {
          newNotif.user = {
            ...notif.user,
            avatar: imageAppendUrl(USER_IMAGE_UPLOAD_LINK, notif.user.avatar),
          }
        }
        return newNotif
      })
      setNotifs(notifications)
      // console.log("Notifications", notifications.slice(0).reverse().slice(0, 10));
    },
    (e) => {
      logger.error('Get unread notificaitons ERROR: ', e)
    }
  )
  const markAllReadApi = useApi(
    () => authedApi.post('/api/user/notification_all', {}),
    () => {
      getAllNotifications.call()
    }
  )

  const unreadNotifCount = notifs.filter(({enabled}) => enabled === true).length

  useEffect(() => {
    getAllNotifications.call()
  }, [])

  const openNotifs = useCallback(() => {
    const shouldShow = !isDropdownVisible
    setIsDropDownVisible(shouldShow)
    if (shouldShow) {
      getAllNotifications.call()
    }
  }, [isDropdownVisible])

  const renderNotifs = useCallback(() => {
    if (getAllNotifications.error) {
      return (
        // ! Code NN-003
        <div className="w-100">
          <p className="text-center mb-0">Aucune notification...</p>
          <small className="text-muted">Erreur NN-003</small>
        </div>
      )
    }
    if (notifs.length === 0) {
      return (
        <div className="w-100">
          <p className="text-center mb-0">Aucune notification...</p>
        </div>
      )
    }
    return notifs.map((notif, i) => (
      <NotificationElement
        notif={notif}
        key={notif.id}
        onNotifChanged={({id, enabled}) => {
          setNotifs((prev) => {
            prev.forEach((n) => {
              if (n.id === id) {
                // eslint-disable-next-line no-param-reassign
                n.enabled = enabled
              }
            })
            return prev
          })
        }}
      />
    ))
  }, [notifs, getAllNotifications.error])

  let width
  let left
  if (window.innerWidth <= 546) {
    width = 360
    left = -259
  } else {
    width = 460
    left = -435
  }
  return (
    <div className="nav-link p-0 dropdown show">
      <a
        className="dropdwon-toggle"
        style={{zIndex: 1}}
        id="dropdownMenuButton"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
        onClick={() => openNotifs()}>
        {!unreadNotifCount ? (
          <svg
            version="1.1"
            id="Capa_1"
            xmlns="http://www.w3.org/2000/svg"
            className="nav-icon icon-hover text-white"
            x="0px"
            y="0px"
            width="510px"
            height="510px"
            viewBox="0 0 510 510">
            <g id="notifications">
              <path
                fill="currentColor"
                d="M255,510c28.05,0,51-22.95,51-51H204C204,487.05,226.95,510,255,510z M420.75,357V216.75c0-79.05-53.55-142.8-127.5-160.65V38.25C293.25,17.85,275.4,0,255,0c-20.4,0-38.25,17.85-38.25,38.25V56.1c-73.95,17.85-127.5,81.6-127.5,160.65V357l-51,51v25.5h433.5V408L420.75,357z"
              />
            </g>
          </svg>
        ) : (
          unreadNotifCount && (
            <div>
              <svg
                version="1.1"
                id="Capa_1"
                xmlns="http://www.w3.org/2000/svg"
                className="nav-icon icon-hover text-white"
                x="0px"
                y="0px"
                width="510px"
                height="510px"
                viewBox="0 0 510 510">
                <g id="notifications">
                  <path
                    fill="currentColor"
                    d="M255,510c28.05,0,51-22.95,51-51H204C204,487.05,226.95,510,255,510z M420.75,357V216.75c0-79.05-53.55-142.8-127.5-160.65V38.25C293.25,17.85,275.4,0,255,0c-20.4,0-38.25,17.85-38.25,38.25V56.1c-73.95,17.85-127.5,81.6-127.5,160.65V357l-51,51v25.5h433.5V408L420.75,357z"
                  />
                </g>
              </svg>
              <span className="position-absolute badge badge-pill badge-danger" style={{top: -10, right: -10}}>
                {unreadNotifCount}
              </span>
            </div>
          )
        )}
      </a>
      <div className={`dropdown-menu ${isDropdownVisible ? 'show' : 'hide'}`} role="menu" aria-labelledby="dropdownMenuButton" style={{width, left}}>
        <div className="container" style={{padding: 0, flexDirection: 'column'}}>
          {unreadNotifCount > 0 && notifs.length > 0 && (
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'flex-end'}}>
              <a style={{padding: 5, paddingRight: 15, textDecoration: 'underline', fontStyle: 'italic'}} onClick={() => markAllReadApi.call()}>
                Tout marquer comme Lu
              </a>
            </div>
          )}
          <div style={{height: 'auto', maxHeight: 400, overflowX: 'hidden'}}>{renderNotifs()}</div>
        </div>
      </div>
    </div>
  )
}

export default NotificationsDropdown
