import React, {ChangeEvent, useCallback, useRef, useState} from 'react'
import {v4 as uuidv4} from 'uuid'
import {messagingActions, selectMessaging} from '../../../shared/redux/messaging.slice'
import {useAppDispatch, useAppSelector} from '../../../reducers/hooks'
import {Message, MessageType} from '../../../shared/api/api.types'
import {authedApi} from '../../../bridge/api'
import {selectConnectedState} from '../../../shared/redux/connexionStatus.slice'
import {useApi} from '../../../shared/api/useApi'
import SendButtonSvg from '../../../assets/svg/SendButtonSvg'
import {logger} from '../../../services/logger'

const buildMessageMeta = (convId: number) => ({
  uuid: uuidv4(),
  convId,
  timestamp: new Date().getTime(),
})

const MessageInput = () => {
  const dispatch = useAppDispatch()
  const {account, deviceId} = useAppSelector(selectConnectedState)
  const {activeConvId} = useAppSelector(selectMessaging)

  const [message, setMessage] = useState('')
  const [mediaFile, setMediaFile] = useState<File | undefined>()
  const [mediaUri, setMediaUri] = useState<string | undefined>()

  const formRef = useRef<HTMLFormElement | null>()
  const inputMediaRef = useRef<HTMLInputElement | null>()
  const inputRef = useRef<HTMLInputElement | null>()
  const handleChange = useCallback(
    (e) => {
      e.preventDefault()
      if (inputRef.current?.classList.contains('is-invalid')) {
        inputRef.current.classList.remove('is-invalid')
      }
      setMessage(e.target.value)
    },
    [inputRef.current]
  )

  const handleChangeMedia = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    const file = e.target.files && e.target.files[0]
    if (file) {
      setMediaFile(file)
      setMediaUri(URL.createObjectURL(file))
    }
  }, [])

  const removePicture = useCallback(() => {
    if (inputMediaRef.current) {
      inputMediaRef.current.value = ''
    }

    setMediaFile(undefined)
    setMediaUri(undefined)
  }, [inputMediaRef.current])

  const send = useApi(
    async (ev) => {
      ev.preventDefault()

      if (!activeConvId) {
        throw new Error('Not in a Conversation')
      }

      if (!mediaFile && message.trim().length === 0) {
        inputRef.current?.classList.add('is-invalid')
        throw new Error('Nothing to send')
      }
      const isPicture = mediaFile !== undefined

      const localMessage: Message & {message: string} = {
        ...buildMessageMeta(activeConvId),
        type: isPicture ? MessageType.PICTURE : MessageType.MESSAGE,
        message,
        content: mediaUri,
        source: account.id,
        sourceDevice: deviceId,
        status: 'pending',
      }

      // Attention: le chargement de l'image pour l'utilisation en local ne peut se faire en même temps que l'ajout dans le FormData.
      // Il faut donc bien `await p` avant l'ajout dans le formulaire
      if (mediaFile) {
        // Chargement de l'image locale pour permettre à la Messagerie d'afficher tout de suite ce message.
        const reader = new FileReader()
        const p = new Promise<string | undefined>((resolve, reject) => {
          reader.addEventListener('load', (event) => {
            if (event.target && typeof event.target.result === 'string') {
              resolve(event.target.result)
            } else reject()
          })
        })
        reader.readAsDataURL(mediaFile)

        localMessage.content = await p
      }

      const formData = new FormData()
      formData.set('type', `${localMessage.type}`)
      formData.set('uuid', localMessage.uuid)
      formData.set('timestamp', `${localMessage.timestamp}`)
      formData.set('message', localMessage.message)

      if (mediaFile) {
        formData.set('content', mediaFile)
      }

      try {
        dispatch(messagingActions.receiveMessage({message: localMessage, fromMe: true}))

        const res = await authedApi.post(`apicommon/conversations/${activeConvId}/message`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        return res.data
      } catch (e) {
        dispatch(
          messagingActions.receiveMessage({
            message: {...localMessage, status: 'error'},
            fromMe: true,
          })
        )
        throw e
      }
    },
    (res) => {
      // eslint-disable-next-line no-param-reassign
      res.status = 'sent'

      dispatch(messagingActions.receiveMessage({message: res, fromMe: true}))
      removePicture()
      setMessage('')
    },
    (e) => {
      logger.error('Error sending message', e)
    }
  )

  // console.log('media', this.state.media)
  return (
    <form
      id="new-message-form"
      ref={(r) => {
        formRef.current = r
      }}
      className="container"
      onSubmit={(e) => send.call(e)}
      encType="multipart/form-data">
      {mediaFile && (
        <div className="w-100">
          <div className="d-flex justify-content-end mb-1">
            <button
              type="button"
              onClick={() => {
                removePicture()
              }}
              className="btn btn-primary"
              style={{minWidth: '40px', width: '40px', height: '40px', borderRadius: '20px'}}>
              <i className="fas fa-times" />
            </button>
          </div>
          <img src={mediaUri} className="w-100" />
        </div>
      )}
      <div className="message-send mt-1 d-flex flex-row align-items-center">
        <div className={mediaFile !== undefined ? 'd-none message-input-media-block' : 'message-input-media-block'}>
          <input
            id="message-input-media"
            ref={(r) => {
              inputMediaRef.current = r
            }}
            type="file"
            accept="image/*"
            name="content"
            onChange={(e: ChangeEvent<HTMLInputElement>) => handleChangeMedia(e)}
            className="messaging-file-input"
          />
          <label htmlFor="message-input-media">
            <i className="fas fa-image" />
          </label>
        </div>
        <input
          id="message-input"
          type="text"
          name="message"
          placeholder="Votre message..."
          className="form-control"
          value={message}
          onChange={(e) => handleChange(e)}
        />
        <a className="p-1" onClick={(e) => send.call(e)}>
          <SendButtonSvg />
        </a>
      </div>
    </form>
  )
}
export default MessageInput
