import React from 'react';
import Avatar from '../avatar/Avatar';
import { connect } from 'react-redux';
import { userDetailsLoad, userDetailsShow } from '../../../actions/userDetailsAction';
import api from '../../../services/api';
import { API_USER_LOAD } from '../../../services/constants';
import { getLinks } from '../../../services/utils';
import UserDataSkeleton from './user-data-skeleton';
import { fetchRequestCommon } from '../../../shared/api/fetch';
import { fetchConversations, messagingActions } from '../../../shared/redux/messaging.slice';
import { messagesRActions } from "../../../reducers/messagesReducer";
import {logger} from "../../../services/logger";

function UserData (props) {
    const [isCreatingConv, setIsCreatingConv] = React.useState(false);

    function userDetails() {
        api.get(API_USER_LOAD(props.data.id), props.config)
        .then(res => {
            props.userDetailsLoad(res.data);
            props.userDetailsShow(true);
        })
        .catch(err => {
            logger.debug("User load details ERROR: ", err);
        });
    }

    const contactUser = () => {
        if (!isCreatingConv) {
            setIsCreatingConv(true);
            //console.log(props.data);

            const tokenUser = JSON.parse(localStorage.getItem('Authorization'));

            fetchRequestCommon(
                '/messaging/findorcreate/' + props.data.accounts[0].id,
                'GET',
                tokenUser,
                {},
            ).then(async (res) => {
                //console.log("res", res);
                if (res.error) {
                    alert(res.message);
                } else {
                    if (res.newConv) {
                        props.setNeedUpdate(res.conversation.id);
                    } else {
                        props.setActiveConv(res.conversation.id);
                        if (window.innerWidth <= 476) {
                            props.setShowDrawerMessage(true);
                        }
                    }
                }
            });

            /*const {socket, conversations, user, user_general, where} = props;
            if (window.innerWidth <= 476) {
                props.showDrawerMessages(true);
            }
            for (var i = 0; i < conversations.length; i++) {
                if (conversationExists(user_general.id, (where === "profile" ?  user.id : where.id), conversations[i])) {
                    props.openConversation(conversations[i]);
                    setIsCreatingConv(false);
                    return;
                }
            }*/
            /*socket.emit('createConv', {
                idUserOne: user_general.id,
                idUserTwo: (where === "profile" ?  user.id : where.id),
                nameUserOne: user_general.firstName + ' ' + user_general.lastName,
                nameUserTwo: (where === "profile" ?  user.firstName : where.firstName) + ' ' + (where === "profile" ?  user.lastName : where.lastName),
                avatarUserOne: imageAppendUrl(USER_IMAGE_UPLOAD_LINK, user_general.avatar),
                avatarUserTwo: imageAppendUrl(USER_IMAGE_UPLOAD_LINK, (where === "profile" ?  user.avatar : where.avatar)),
            });*/
            setIsCreatingConv(false);
        }
    }

    if (!props.data) {
        return <UserDataSkeleton />;
    }

    //console.log(props.rank);

    return (
        <>
            <div className="card-header d-flex align-items-center justify-content-center flex-column w-100">
                <div className="d-flex align-items-center justify-content-center flex-column" onClick={userDetails} style={{cursor: "pointer"}}>
                    <Avatar alt={props.data.firstName && props.data.lastName ? props.data.firstName[0]+props.data.lastName[0] : ''} img={props.data.image} size="md" />
                    <h4 className="mt-1 text-center">{props.data.firstName} <span className="text-uppercase">{props.data.lastName}</span></h4>
                    <p className="card-subtitle text-muted text-center">{props.data.position}</p>
                </div>
                {
                    props.data.linkedin_url && props.data.linkedin_url.match(/[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/) &&
                    <a href={getLinks(props.data.linkedin_url)} target="_blank" rel="noopener noreferrer">
                        <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="linkedin-in" className="icon text-primary icon-hover" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"></path></svg>
                    </a>
                }
            </div>
            <div className="card-body d-flex justify-content-center align-items-center mt-n2">
                <div className="card-text">
                    <div className="d-flex flex-row align-items-center justify-content-center">
                        <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="envelope" className="icon mr-1 text-primary" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M464 64H48C21.49 64 0 85.49 0 112v288c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49 48-48V112c0-26.51-21.49-48-48-48zm0 48v40.805c-22.422 18.259-58.168 46.651-134.587 106.49-16.841 13.247-50.201 45.072-73.413 44.701-23.208.375-56.579-31.459-73.413-44.701C106.18 199.465 70.425 171.067 48 152.805V112h416zM48 400V214.398c22.914 18.251 55.409 43.862 104.938 82.646 21.857 17.205 60.134 55.186 103.062 54.955 42.717.231 80.509-37.199 103.053-54.947 49.528-38.783 82.032-64.401 104.947-82.653V400H48z"></path></svg>
                        <span className="text-muted"><a href={"mailto:"+props.data.email}>{props.data.email}</a></span>
                    </div>
                    {
                        window.innerWidth <= 476 && props.data.phone_number ?
                        <div className="d-flex flex-row align-items-center justify-content-center">
                            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="phone-alt" className="icon mr-1 text-primary" style={{marginBottom: 2}} role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M497.39 361.8l-112-48a24 24 0 0 0-28 6.9l-49.6 60.6A370.66 370.66 0 0 1 130.6 204.11l60.6-49.6a23.94 23.94 0 0 0 6.9-28l-48-112A24.16 24.16 0 0 0 122.6.61l-104 24A24 24 0 0 0 0 48c0 256.5 207.9 464 464 464a24 24 0 0 0 23.4-18.6l24-104a24.29 24.29 0 0 0-14.01-27.6z"></path></svg>
                            <span className="text-muted"><a href={"tel:"+props.data.phone_number}>{props.data.phone_number}</a></span>
                        </div>
                        :
                        props.data.phone_number &&
                        <div className="d-flex flex-row align-items-center justify-content-center">
                            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="phone-alt" className="icon mr-1 text-primary" style={{marginBottom: 2}} role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M497.39 361.8l-112-48a24 24 0 0 0-28 6.9l-49.6 60.6A370.66 370.66 0 0 1 130.6 204.11l60.6-49.6a23.94 23.94 0 0 0 6.9-28l-48-112A24.16 24.16 0 0 0 122.6.61l-104 24A24 24 0 0 0 0 48c0 256.5 207.9 464 464 464a24 24 0 0 0 23.4-18.6l24-104a24.29 24.29 0 0 0-14.01-27.6z"></path></svg>
                            <span className="text-muted"><a href={"tel:"+props.data.phone_number}>{props.data.phone_number}</a></span>
                        </div>
                    }
                    {
                        props.data.description &&
                        <p className="text-center" style={{whiteSpace: 'pre-line'}}>
                            {props.data.description}
                        </p>
                    }
                    {
                        (props.user_general.id !== props.data.id && props.rank > 0) &&
                        <div className="mt-1 d-flex flex-row align-items-center justify-content-center">
                            <a className="text-primary p-2" onClick={contactUser}>Contacter</a>
                        </div>
                    }
                </div>
            </div>
        </>
    );
}

const mapStateToProps = state => ({
    config: state.apiR,
    user_general: state.userR,
    user: state.userDetailsR,
    socket: state.conversationsR.socket,
    conversations: state.conversationsR.conversations,
})

const mapDispatchToProps = dispatch => ({
    userDetailsLoad: (payload) => dispatch(userDetailsLoad(payload)),
    userDetailsShow: (payload) => dispatch(userDetailsShow(payload)),
    showDrawerMessages: (payload) => dispatch(messagesRActions.showDrawerMessages(payload)),
    setActiveConv: (convId) =>
        dispatch(messagingActions.setActiveConv(convId)),
    setNeedUpdate: (convId) =>
        dispatch(fetchConversations(convId)),
    setShowDrawerMessage: (payload) =>
        dispatch(messagesRActions.showDrawerMessages(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(UserData);
