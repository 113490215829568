import React, { Component } from 'react';
import Group from './group/group';
import GroupDetails from './group-details/group-details';
import {connect} from 'react-redux';
import UserDetails from '../../component/user/user-details/user-details';
import { userDetailsShow } from '../../actions/userDetailsAction';
import api from '../../services/api';
import SearchDetails from '../../component/form/search/search-details';
import { searchFilter, searchResults } from '../../actions/searchAction';
import { API_COMPANY_LOAD, API_COMPANIES_LOAD, USER_IMAGE_UPLOAD_LINK, COMPANY_IMAGE_UPLOAD_LINK } from '../../services/constants';
import { imageAppendUrl } from '../../services/utils';
import {logger} from "../../services/logger";
import PageNoResult from "./page-no-result";

class Groups extends Component {
    constructor() {
        super();
        this.state = {
            groups: [],
            reload: true,
            height: window.innerHeight,
            details: undefined,
        }
        this.groupDetailsShow = this.groupDetailsShow.bind(this);
        this.showGroups = this.showGroups.bind(this);
    }

    showGroups = () => {
        if (!this.state.details && !this.props.userDetailsR.show) {
            this.props.searchFilter("");
            this.props.searchResults({users: [], companies: []});
        }
        this.setState({details: undefined, });
        this.props.userDetailsShow(false);
        this.getCompanies();
    }

    groupDetailsShow = (group_id) => {
        api.get(API_COMPANY_LOAD(group_id), this.props.config)
        .then(res => {
            for (var i = 0; i < res.data.users.length; i++) {
                res.data.users[i].image = imageAppendUrl(USER_IMAGE_UPLOAD_LINK, res.data.users[i].avatar);
            }
            res.data.image = imageAppendUrl(COMPANY_IMAGE_UPLOAD_LINK, res.data.logo);
            res.data.background_image =  imageAppendUrl(COMPANY_IMAGE_UPLOAD_LINK, res.data.backgroundImage);
            this.setState({details: res.data,});
        })
        .catch(err => {
            logger.debug("Get company details ERROR: ", err);
        })
    }

    getCompanies = () => {
        api.get(API_COMPANIES_LOAD, this.props.config)
        .then(res => {
            this.setState({elements: res.data.map(el => ({
                ...el,
                image: imageAppendUrl(COMPANY_IMAGE_UPLOAD_LINK, el.logo),
                background_image: imageAppendUrl(COMPANY_IMAGE_UPLOAD_LINK, el.backgroundImage),
                users: el.users.map(el => ({
                    ...el,
                    image: imageAppendUrl(USER_IMAGE_UPLOAD_LINK, el.avatar),
                })),
            })),});
        })
        .catch(err=> {
            logger.debug("Get all companies ERROR: ", err);
        })
    }

    // onWindowResize = () => {
    //     if (window.innerHeight < 800) {
    //         this.setState({height: window.innerHeight, reload: false});
    //     }
    // }

    componentWillMount() {
        this.getCompanies();
        // window.addEventListener('resize', this.onWindowResize);
    }

    // componentWillUnmount() {
    //     window.removeEventListener('resize', this.onWindowResize);
    // }

    render() {
        var Details = undefined;
        if (this.props.userDetailsR.show) {
            if (this.state.details) {
                this.setState({details: undefined, });
            }
            Details = (props) => (<UserDetails {...props} />)
        } else if (this.state.details !== undefined) {
            Details = (props) => (<GroupDetails data={this.state.details} {...props} />)
        } else if (this.props.searchR.users.length || this.props.searchR.companies.length) {
            if (this.state.details) {
                this.setState({details: undefined, });
            }
            Details = (props) => (<SearchDetails data={this.props.searchR} groupDetails={(data) => this.groupDetailsShow(data)} {...props} />)
        }

        return (
            <div className="rounded my-2" style={{minHeight: 150, height: 'auto', maxHeight: this.state.height < 800 ? this.state.height - (window.innerWidth < 576 ? 150 : 170 ) : 600, overflowX: 'visible', overflowY: 'scroll'}}>
                {
                    Details ?
                    <>
                        <div className="position-absolute w-50 clearfix text-right" style={{height: 35, zIndex: 10, right: 20, paddingRight: 20, paddingTop: 20}}>
                            <a className="m-2 text-primary bg-light rounded p-1" style={{zIndex: 9, fontWeight: 800}} onClick={this.showGroups}>
                                Retour
                            </a>
                        </div>
                        <Details onReload={this.state.reload}/>
                    </>
                    :
                    this.state.elements && this.state.elements.length > 0
                    ?
                    <div className="card group card-active group p4">
                        <div className="card-body">
                            {
                                this.state.elements.splice(0).map((element, i) => (
                                    <Group key={element.id} data={element} seeDetails={(group_id) => this.groupDetailsShow(group_id)} onReload={this.state.reload}/>
                                ))
                            }
                        </div>
                    </div>
                    :
                    // ! Code GS-001
                    <PageNoResult onReturn={this.showGroups} />
                }
            </div>
        );
    }
}

const mapStateToProps = state => ({
    userDetailsR: state.userDetailsR,
    config: state.apiR,
    searchR: state.searchR
});

const mapDispatchToProps = dispatch => ({
    userDetailsShow: (payload) => dispatch(userDetailsShow(payload)),
    searchFilter: (payload) => dispatch(searchFilter(payload)),
    searchResults: (payload) => dispatch(searchResults(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Groups);
