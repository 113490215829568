import axios, {AxiosRequestConfig} from 'axios'
import {logger} from './logger'

export class UserNotAuthenticatedError extends Error {}

export const unauthedApi = axios.create({
  // baseURL: "${BASE_URL}/",
  withCredentials: false,
  baseURL: `${process.env.REACT_APP_BASE_URL}/`,
})
export const authedApi = axios.create({
  // baseURL: "${BASE_URL}/",
  withCredentials: false,
  baseURL: `${process.env.REACT_APP_BASE_URL}/`,
})
authedApi.interceptors.request.use((config: AxiosRequestConfig) => {
  const auth = localStorage.getItem('Authorization')
  if (!auth) throw new UserNotAuthenticatedError('User is not authenticated')
  try {
    const tokenUser = JSON.parse(auth)
    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization = `Bearer ${tokenUser}`
  } catch (e) {
    logger.error('Could not set authorization header', e)
    throw new UserNotAuthenticatedError('Could not retrive user token')
  }

  return config
})

export default unauthedApi
