import React, {useEffect} from 'react'
import {Redirect, RouteComponentProps, useLocation} from 'react-router-dom'
import queryString from 'query-string'
import SnackbarContent from '@material-ui/core/SnackbarContent'
import Button from '@material-ui/core/Button'
import {unauthedApi} from '../../services/api'
import {logger} from '../../services/logger'
import {useAppDispatch, useAppSelector} from '../../reducers/hooks'
import {isErrorWithResponse, useApi} from '../../shared/api/useApi'
import {userActions} from '../../reducers/userReducer'
import {companyLoad} from '../../actions/companyAction'
import {HttpError, UnknownError} from '../../shared/api/errors'
import {accountRegistrationActions} from '../../reducers/accountRegistrationSlice'

const isNotEmptyString = (s: unknown): s is string => s !== undefined && typeof s === 'string' && s.trim().length > 0
export const FinalizeAccount = ({history}: RouteComponentProps) => {
  const dispatch = useAppDispatch()
  const {club_id: clubId} = useAppSelector((state) => state.clubState)
  const {search} = useLocation()

  const queryParams = queryString.parse(search)

  const verifyInvitationApi = useApi(
    async (accountId?: number, params?: {clubId: number; deviceType: 'web'; token?: string}) => {
      try {
        if (!accountId || !params?.token) {
          throw new UnknownError("Lien d'invitation non valide")
        }
        const res = await unauthedApi.post(`/userapi/account/${accountId}/finalize-account`, params)
        const {data} = res
        if (!data.token) {
          throw new UnknownError('Erreur lors de la vérification de la requête')
        }
        return data
      } catch (e) {
        logger.debug('Email login ERROR: ', JSON.stringify(e))
        if (isErrorWithResponse(e) && e.response?.data?.errCode) {
          throw new HttpError(e.response.status, e.response.data.errCode)
        }
        throw e
      }
    },
    (res) => {
      dispatch(accountRegistrationActions.setToken(res.token))
      dispatch(userActions.userLoad(res.account.user))
      dispatch(companyLoad(res.account.user.company))
    }
  )

  let accountId: undefined | number
  let token: undefined | string
  let username: undefined | string

  if (queryParams.id && typeof queryParams.id === 'string') {
    try {
      accountId = parseInt(queryParams.id, 10)
    } catch (e) {
      // do nothing
    }
  }
  if (isNotEmptyString(queryParams.t)) {
    token = queryParams.t.trim()
  }
  useEffect(() => {
    verifyInvitationApi.call(accountId, {
      clubId,
      deviceType: 'web',
      token,
    })
  }, [])

  const isLoading = verifyInvitationApi.isLoading || (!verifyInvitationApi.error && !verifyInvitationApi.res)

  if (isLoading) {
    return (
      <div className="d-flex justify-content-center">
        <div className="loading-center text-center">
          <div className="lds-ripple">
            <div />
            <div />
          </div>
        </div>
      </div>
    )
  }

  if (verifyInvitationApi.error) {
    logger.debug('account invitation verification ERROR: ', JSON.stringify(verifyInvitationApi.error))

    let msg = 'Une erreur est intervenu lors du chargement.'
    let errCode = 'Unknown'
    if (verifyInvitationApi.error instanceof HttpError) {
      errCode = verifyInvitationApi.error.httpMessage
    } else if (verifyInvitationApi.error instanceof UnknownError) {
      errCode = verifyInvitationApi.error.message
    }

    switch (errCode) {
      case 'AlreadyRegistered':
        if (isNotEmptyString(queryParams.username)) {
          username = queryParams.username.trim()
        }

        return (
          <div className="d-flex justify-content-center">
            <SnackbarContent
              className="m-2 bg-primary w-50"
              message="Vous avez déjà activé votre compte."
              action={
                <Button color="inherit" size="small" onClick={() => history.push(`/login?username=${username}`)}>
                  Se connecter
                </Button>
              }
            />
          </div>
        )
      case 'InvalidInvitation':
        msg = "Le lien de l'invitation n'est pas valide."
        break
      case 'NotFound':
        msg = "Ce compte n'a pas été trouvé."
        break
      case 'Unknown':
      default:
        break
    }
    return (
      <div className="d-flex justify-content-center">
        <SnackbarContent className="m-2 bg-danger w-50 d-flex justify-content-center" message={msg} />
      </div>
    )
  }

  return (
    <div className="d-flex justify-content-center">
      <Redirect to="/signup" />
    </div>
  )
}
