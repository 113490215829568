import React from 'react';

const AnnuaireTableStructure = ({ children }) => {
    const [title, searchButton, table] = children;
    return (
        <>
            <div className="d-flex flex-column flex-sm-row justify-content-between align-items-center">
                {title}
                {searchButton}
            </div>
            <div className="card mt-3">
                <div className="card-body rounded">
                    {table}
                </div>
            </div>
        </>
    );
};

export default AnnuaireTableStructure;