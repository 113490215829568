import { onlyUnique } from "../shared/utils/arrays";

const posts = {
    posts: [],
    filter: undefined,
    updateHeader: false,
    post_details: null,
    ad_posts: [],
}

export default (state = posts, action) => {
    var allPosts;
    switch (action.type) {
      case 'loadPosts':
          const posts = [...state.posts, ...action.payload]
              .filter(onlyUnique((a, b) => a.id === b.id))

            return {
                ...state,
                posts,
            }
        case 'erasePosts':
            var newPosts = state.posts.filter((item) => item.id !== action.payload);
            return {
                ...state,
                posts: [
                    ...newPosts,
                ],
            }
        case 'postNewAdd':
            return {
                ...state,
                posts: [
                    action.payload,
                    ...state.posts,
                ],
            }
        case 'postLikes':
            allPosts = state.posts;
            for (var i = 0; i < allPosts.length; i++) {
                if (allPosts[i].id === action.payload.post_id) {
                    allPosts[i].likes = action.payload.likesList
                    break;
                }
            }
            return {
                ...state,
                posts: allPosts,
            }
        case 'postsFilter':
            return {
                ...state,
                filter: action.payload,
            };
        case 'postModify':
            allPosts = state.posts;
            for (var k = 0; k < allPosts.length; k++) {
                if (allPosts[k].id === action.payload.id) {
                    allPosts[k] = action.payload;
                    break;
                }
            }
            return {
                ...state,
                updateHeader: true,
                posts: allPosts,
            }
        case 'postUpdateHeader':
            return {
                ...state,
                updateHeader: action.payload,
            }
        case 'postDetails':
            return {
                ...state,
                post_details: action.payload,
            }
        case 'cleanPosts':
            return posts;
        case 'adPostsLoad':
            return {
                ...state,
                ad_posts: action.payload,
            }
        default:
            return state;
    }
}
