import React, { Component } from 'react';
import {Redirect, withRouter} from 'react-router-dom';
import queryString from 'query-string';
import API, { unauthedApi } from '../../services/api';
import {getToken} from '../../actions/apiAction';
import {connect} from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import Button from '@material-ui/core/Button';
import { companyLoad } from '../../actions/companyAction';
import { API_USER_MAIL_LOGIN } from '../../services/constants';
import { userActions } from "../../reducers/userReducer";
import {logger} from "../../services/logger";
import { accountRegistrationActions } from "../../reducers/accountRegistrationSlice";

class MailLogin extends Component {
    constructor() {
        super();
        this.state = {
            permited: "waiting"
        };
    }

    componentDidMount() {
        var params = queryString.parse(this.props.location.search);
        params.club_id = this.props.clubState.club_id;
        params.deviceType = 'web';
        if (!params.club_id) {
            return;
        }
        API.post(API_USER_MAIL_LOGIN, params, this.props.apiR)
        .then((res) => {
            if (res.data.token) {
                this.props.tokenAction(res.data.token);
                this.props.userLoad(res.data.account.user);
                this.props.companyLoad(res.data.account.user.company)
                this.setState({permited: "true"});
            } else {
                this.setState({permited: "false"});
            }
        })
        .catch((err) => {
            this.setState({permited: "error"})
            logger.debug('Email login ERROR: ', err);
        })
    }

    componentWillReceiveProps(newProps) {
        var params = queryString.parse(this.props.location.search);
        params.club_id = newProps.clubState.club_id;
        params.deviceType = 'web';
        if (!params.club_id) {
            return;
        }
        unauthedApi.post(API_USER_MAIL_LOGIN, params)
        .then((res) => {
            if (res.data.token) {
                this.props.tokenAction(res.data.token);
                this.props.userLoad(res.data.account.user);
                this.props.companyLoad(res.data.account.user.company)
                this.setState({permited: "true"});
            } else {
                this.setState({permited: "false"});
            }
        })
        .catch((err) => {
          if (err.response && err.response.status === 401) {
            this.setState({permited: "bad-password"})
            return
          }
            this.setState({permited: "error"})
            logger.debug('Email login ERROR: ', err);
        })
    }

    render() {
        return (
            <div className="d-flex justify-content-center">
                {
                    this.state.permited === "waiting" &&
                    <div className="loading-center text-center">
                        <CircularProgress style={{width: 100, height: 100}} color="inherit"/>
                    </div>
                }
              {
                  this.state.permited === "true" &&
                  <Redirect to="/signup" />
              }
              {
                  this.state.permited === "bad-password" &&
                  <SnackbarContent
                      className="m-2 bg-primary w-50"
                      message='Vous avez déjà activé votre compte.'
                      action ={<Button color="inherit" size="small" onClick={() => this.props.history.push(`/login?username=${queryString.parse(this.props.location.search).username}`)}>
                        Se connecter
                      </Button>}
                  />
              }
                {
                    this.state.permited === "false" &&
                    <SnackbarContent
                    className="m-2 bg-primary w-50"
                    message='Le lien a expiré'
                    action ={<Button color="inherit" size="small" onClick={() => this.props.history.push('/login')}>
                        Se connecter
                    </Button>}
                    />
                }
                {
                    this.state.permited === "error" &&
                    <SnackbarContent
                    className="m-2 bg-danger w-50 d-flex justify-content-center"
                    message='Une erreur est intervenu lors du chargement.'
                    />
                }
            </div>
        );
    }
}

const mapStateToProps = state => ({
    ...state
})

const mapDispatchToProps = dispatch => ({
    tokenAction: (payload) => {
      dispatch(getToken(payload));
      // Specify the registration auth token that is used further.
      dispatch(accountRegistrationActions.setToken(payload));
    },
    companyLoad: (payload) => dispatch(companyLoad(payload)),
    userLoad:(payload) => dispatch(userActions.userLoad(payload)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MailLogin));
