import React, { Component } from 'react';
import InputFiles from 'react-input-files';
import api from '../../../services/api';
import { API_POST_MODIFY, REGEX_USER_MENTION, REGEX_HASHTAGS, POST_IMAGE_UPLOAD_LINK, USER_IMAGE_UPLOAD_LINK } from '../../../services/constants';
import { postModify } from '../../../actions/postsAction';
import { connect } from 'react-redux';
import { MessagePost } from '../../../component/form';
import { imageAppendUrl } from '../../../services/utils';
import {fileReaderToBase64} from "../../../services/fs";
import {logger} from "../../../services/logger";

class ModifyPost extends Component {
    constructor(props) {
        super();
        this.state = {
            content: props.post.content,
            tags: [],
            video: props.post.attachment
        }
        this.image = this.ImagePost.bind(this);
        this.video = this.VideoPost.bind(this);
        this.text = this.TextPost.bind(this);
        this.onModify = this.onModify.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange = (value) => {
        this.setState({content: value, });
    }

    onModify = async (e) => {
        e.preventDefault();
        const { content, tags, image, video, users, image_ext } = this.state;
        const { post, config, postModify, returnValue } = this.props;

        const mentioned_users = content.match(REGEX_USER_MENTION);
        if (mentioned_users) {
            mentioned_users.map(element => {
                var id = element.substring(element.indexOf('(') + 1, element.lastIndexOf(')'))
                return id;
            })
            await this.setState({users: mentioned_users});
        }
        const tags_post = content.match(REGEX_HASHTAGS);
        if (tags_post) {
            await this.setState({tags: tags_post});
        }

        let bodyFormData = new FormData();

        bodyFormData.set('content', content);
        bodyFormData.set('postType', post.postType.id);
        bodyFormData.set('tags', tags ? JSON.stringify(tags) : []);
        bodyFormData.set('mentioned_users_id', users ? JSON.stringify(users) : []);
        bodyFormData.set('attachment', image ? image : (video ? video : ""));
        bodyFormData.set('ext', image_ext ? image_ext : "");

        api.post(API_POST_MODIFY(post.id), bodyFormData, config)
        .then(res => {
            if (res.data.attachment && res.data.postType.libelle === "image") {
                res.data.attachment = imageAppendUrl(POST_IMAGE_UPLOAD_LINK, res.data.attachment);
            }
            res.data.user.avatar = imageAppendUrl(USER_IMAGE_UPLOAD_LINK, res.data.user.avatar);
            postModify(res.data);
            returnValue(false);
        })
        .catch(err => {
            logger.debug("Post modify ERROR: ", err);
        })
    }

    onCancel = () => {
        this.setState({content: this.props.post.content});
        this.props.returnValue(false);
    }

    TextPost = (props) => {
        return (<div className="d-flex flex-column align-items-center w-100">
                <MessagePost className="w-100"
                             returnLists={(users, hashtags) => this.setState({users: users, tags: hashtags})}
                             defaultValue={this.state.content} returnValue={(value) => this.handleChange(value)}/>
                <div className="flex-row ml-auto">
                    <button type="button" className="btn btn-secondary mr-1" onClick={this.onCancel}>Annuler</button>
                    <button type="button" className="btn btn-primary" onClick={this.onModify}>Modifier</button>
                </div>
            </div>
        )
    }

    handleFiles = (files) => {
        fileReaderToBase64(files, (data) => {
            this.setState({image: data.image.split(',')[1], image_ext: data.extension, image_name: data.image_name});
        })
    }

    ImagePost = (props) => {
        return (<div className="d-flex flex-column">
                <div className="position-relative mb-3">
                    <InputFiles onChange={files => this.handleFiles(files)}>
                        <div className="custom-file-input w-100 h-100"></div>
                        <label className="custom-file-label">{this.state.image_name}</label>
                    </InputFiles>
                </div>
                <div className="d-flex flex-column align-items-center w-100">
                    <MessagePost className="w-100"
                                 returnLists={(users, hashtags) => this.setState({users: users, tags: hashtags})}
                                 default={this.state.content} returnValue={(value) => this.handleChange(value)}/>
                    <div className="flex-row ml-auto">
                        <button type="button" className="btn btn-secondary mr-1" onClick={this.onCancel}>Annuler</button>
                        <button type="button" className="btn btn-primary" onClick={this.onModify}>Modifier</button>
                    </div>
                </div>
            </div>
        )
    }

    handleVideo = (data) => {
        this.setState({video: data});
    }

    VideoPost = (props) => (
        <div className="d-flex flex-column">
            <div className="form-group my-2">
                <label htmlFor="video-link">Lien vers la vidéo:</label>
                <input className="form-control" type="url" id="video-link" value={this.state.video} placeholder="https://" onChange={e => this.handleVideo(e.target.value)}/>
            </div>
            <div className="d-flex flex-column align-items-center w-100">
                <MessagePost className="w-100"
                             returnLists={(users, hashtags) => this.setState({users: users, tags: hashtags})}
                             default={this.state.content} returnValue={(value) => this.handleChange(value)}/>
                <div className="flex-row ml-auto">
                    <button type="button" className="btn btn-secondary mr-1" onClick={this.onCancel}>Annuler</button>
                    <button type="button" className="btn btn-primary" onClick={this.onModify}>Modifier</button>
                </div>
            </div>
        </div>
    )

    render() {
        if (!this.props.post || !this.props.post.postType) {
            // ! Code PM-002
            logger.debug('DEBUG -- Une erreur est survenue... Code PM-002');
            return <p className="text-muted">Une erreur est survenue lors de la modification du post... Code PM-002</p>;
        }
        const ToModify = this[this.props.post.postType.libelle];
        return (
            <div>
                <ToModify post={this.props.post}/>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    config: state.apiR,
})

const mapDispatchToProps = dispatch => ({
    postModify: (payload) => dispatch(postModify(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ModifyPost);
