import {Divider, List, ListItemButton, ListItemIcon, ListItemText, ListSubheader, Stack, Typography} from '@mui/material'
import React, {useCallback, useEffect, useMemo, useState} from 'react'
// @ts-ignore
import LoadingScreen from 'react-loading-screen'
import DocumentsSvg from '../../assets/svg/DocumentsSvg'
import {useAppSelector} from '../../reducers/hooks'
import {RootState} from '../../redux/store'
import {baseUrl} from '../../services/url'
import {dateFormaterPost} from '../../services/utils'
import galleriesApi, {DocumentApiRes, DocumentResItem} from '../../shared/api/galleriesApi'
import {UseApi, useApi} from '../../shared/api/useApi'
import {selectConnexionStatus} from '../../shared/redux/connexionStatus.slice'
import OpenInNewSvg from './OpenInNewSvg'
import PdfIconSvg from './PdfIconSvg'

export type Document = {
  src: string
  title: string
  description?: string
  filesize: number
  id: number
  date: string | null
}

type Folder = {
  documents: Document[]
  key: string
  id: number
  title: string
}

const sortByDateDesc = (a: {date: string | null}, b: {date: string | null}) => {
  const aTimestamp = a.date ? new Date(a.date).getTime() : 0
  const bTimestamp = b.date ? new Date(b.date).getTime() : 0
  return bTimestamp - aTimestamp
}
const sortByIdAsc = (a: {id: number}, b: {id: number}) => a.id - b.id

const foldersFromDocumentResItems = (documentResItems: DocumentApiRes) => {
  // documentResItems = [
  //   ...documentResItems,
  //   ...documentResItems,
  //   ...documentResItems,
  //   ...documentResItems,
  //   ...documentResItems,
  //   ...documentResItems,
  //   ...documentResItems,
  //   ...documentResItems,
  // ].map((item, index) => ({
  //   ...item,
  //   id: index,
  // }))
  // pictureApiRes = []

  // Group by folders
  const folders = documentResItems.reduce((acc, item) => {
    let v: undefined | DocumentResItem[]
    const k = item.gallery ? item.gallery.id : -1
    // not a folder
    v = acc[k]
    if (!v) {
      v = []
      acc[k] = v
    }
    v.push(item)
    return acc
  }, {} as Record<number, DocumentResItem[]>)

  return Object.values(folders)
    .map((items) => {
      const {gallery} = items[0]
      const documents = items
        .map((item) => ({
          id: item.id,
          key: `${item.id}`,
          src: `${baseUrl}/uploads/documents/${item.filename}`,
          url: `${baseUrl}/uploads/documents/${item.filename}`,
          date: item.created_at,
          title: item.title,
          description: item.description,
          filesize: item.filesize,
        }))
        .sort(sortByDateDesc)
        .slice(0)
      return {
        documents,
        key: `${gallery?.id || -1}`,
        id: gallery?.id || -1,
        title: gallery?.title || 'Non placés',
      }
    })
    .sort(sortByIdAsc)
}

export const Documents = ({
  getDocumentsApi,
  selectedDocumentId,
}: {
  getDocumentsApi: UseApi<[tokenUser: string, clubId: number], DocumentApiRes>
  selectedDocumentId?: number
}) => {
  const [folders, setFolders] = useState<Folder[]>([])
  const [selectedFolders, setSelectedFolders] = useState<number[]>([])

  useEffect(() => {
    if (selectedDocumentId && folders.length > 0) {
      setSelectedFolders(folders.filter((f) => f.documents.findIndex(({id}) => id === selectedDocumentId) >= 0).map(({id}) => id))
    }
  }, [selectedDocumentId, folders])
  const filteredFolders = useMemo(() => {
    if (selectedFolders.length === 0) return folders
    return folders
      .filter(({id}) => selectedFolders.includes(id))
      .sort((a, b) => {
        const indexOfA = selectedFolders.indexOf(a.id)
        const indexOfB = selectedFolders.indexOf(b.id)
        return indexOfB - indexOfA
      })
  }, [selectedFolders.length, folders, selectedDocumentId])

  useEffect(() => {
    if (getDocumentsApi.res) {
      setFolders(foldersFromDocumentResItems(getDocumentsApi.res))
    }
  }, [getDocumentsApi.res])

  const renderDocument = useCallback(
    (doc: Document) => (
      <ListItemButton
        sx={doc.id === selectedDocumentId ? {backgroundColor: 'lightgray'} : {}}
        className="px-4 d-flex"
        download={doc.title}
        href={doc.src}
        target="_blank">
        <ListItemIcon>
          <PdfIconSvg />
        </ListItemIcon>
        <ListItemText
          primary={doc.title}
          secondary={
            <>
              <Typography sx={{display: 'inline'}} component="span" variant="body2" color="text.primary">
                {dateFormaterPost(doc.date)}
              </Typography>
              {doc.description && ` — ${doc.description}`}
            </>
          }
        />
        <ListItemIcon className="d-flex justify-content-end">
          <OpenInNewSvg />
        </ListItemIcon>
      </ListItemButton>
    ),
    [selectedDocumentId]
  )
  const renderFolder = useCallback((folder: Folder) => {
    if (folder.id < 0) {
      return (
        <List
          key={`f${folder.id}`}
          sx={{width: '100%'}}
          className="pb-3"
          subheader={
            <ListSubheader component="div" id="nested-list-subheader">
              <h5 className="text-dark d-flex flex-row align-items-center">{folder.title}</h5>
            </ListSubheader>
          }>
          {folder.documents.map((doc) => {
            return (
              <div key={`d${doc.id}`}>
                {renderDocument(doc)}
                <Divider light className="mx-3" />
              </div>
            )
          })}
        </List>
      )
    }
    return (
      <List
        key={`f${folder.id}`}
        sx={{width: '100%'}}
        className="pb-3"
        subheader={
          <ListSubheader component="div" id="nested-list-subheader">
            <h5 className="text-dark d-flex flex-row align-items-center">
              <DocumentsSvg width={20} height={20} className="mr-1" /> {folder.title}
            </h5>
          </ListSubheader>
        }>
        {folder.documents.map((doc) => {
          return (
            <div key={`d${doc.id}`}>
              {renderDocument(doc)}
              <Divider light className="mx-3" />
            </div>
          )
        })}
      </List>
    )
  }, [])

  if (getDocumentsApi.isLoading) {
    return (
      <div className="card mt-3">
        <div className="card-body rounded min-vh-100 d-flex justify-content-center align-items-top">
          <div className="lds-ripple">
            <div />
            <div />
          </div>
        </div>
      </div>
    )
  }
  if (getDocumentsApi.error) {
    return (
      <div className="card mt-3">
        <div className="card-body rounded min-vh-100">Nous n'avons pu récupérer les documents de la bibliothèque...</div>
      </div>
    )
  }

  return (
    <>
      <div className="card mt-3 d-flex flex-wrap flex-row justify-content-start align-items-center">
        {folders.map(({id, title}) => {
          const isSelected = selectedFolders.includes(id)
          return (
            <button
              key={`b${id}`}
              type="button"
              className={`btn px-3 mr-4 d-flex flex-row align-items-center ${isSelected ? 'btn-light text-dark' : 'btn-primary-darker text-white'}`}
              onClick={() =>
                setSelectedFolders((prev) => {
                  if (isSelected) return prev.filter((prevId) => id !== prevId)
                  return [...prev, id]
                })
              }>
              {id >= 0 && <DocumentsSvg width={20} height={20} className="mr-1" />}
              {title}
            </button>
          )
        })}
      </div>
      <Stack>
        <div className="card mt-3">
          <div className="card-body rounded">
            {folders.length === 0 && "Vous n'avez pas encore de documents"}
            {filteredFolders.map((f) => renderFolder(f))}
          </div>
        </div>
      </Stack>
    </>
  )
}
