import {useCallback} from 'react'
// eslint-disable-next-line import/no-cycle
import {ConnexionState, selectConnexionStatus} from '../redux/connexionStatus.slice'
import {selectClubFeatures} from '../redux/feature.slice'
import {useAppSelector} from '../redux/reduxHooks'

export type Feature = 'annuaire' | 'news' | 'messages' | 'gallery' | 'ged' | 'calendar' | 'ged_user_add_document' | 'partner_modify_for_members' | 'sponsors'

export function isAllowedRank(user: ConnexionState['user'], club: ConnexionState['club']) {
  if (club !== undefined && club !== null) {
    if (club.offerModel !== undefined && club.offerModel !== null && club.offerModel > 1) {
      if (user !== undefined && user !== null) {
        if (user.company !== undefined && user.company !== null) {
          if (user.company.rank === 1) {
            return true
          }
        }
      }
    }
  }

  return false
}

export const hasFeatures = (user: ConnexionState['user'], club: ConnexionState['club'], clubFeatures: Feature[], ...requestedFeatures: Feature[]) => {
  const permissions: Feature[] = []
  if (isAllowedRank(user, club)) {
    permissions.push('news', 'messages', 'gallery', 'calendar')
  }
  permissions.push(...clubFeatures)
  return requestedFeatures.every((requestedFeature) => permissions.includes(requestedFeature))
}
export function useFeatures() {
  const {user, account, club} = useAppSelector(selectConnexionStatus)
  const clubFeatures = useAppSelector(selectClubFeatures)

  return {
    hasFeatures: useCallback(
      (...requestedFeatures: Feature[]) => hasFeatures(user, club, clubFeatures, ...requestedFeatures),
      [user, club, clubFeatures, account?.id]
    ),
  }
}
