export const TITLES: string[] = [
  'PDG',
  'Gérant',
  'Président(e)',
  'Co-Président(e)',
  'Directeur Général',
  'Directeur',
  'Directeur Administratif',
  'CEO',
  'Responsable',
  'Chef de service',
  'Ingénieur',
  'Cadre',
  'Chargé de projet ',
  'Agent de maîtrise',
  'Aide à domicile',
  'Assistant',
  'Technicien',
  'Opérateur',
  'Commercial',
  'Consultant',
  'Délégué - Secrétaire générale',
  'Professeur - Scolaire',
  'Profession libérale',
  'Directeur Général Adjoint RH',
  'Avocat Associé',
  'Chargé(e) de Mission',
  'Chargé(e) de la Relation Entreprise',
  'Chef de la Mission Mécénat',
  'Chef de Projet RH',
  'Consultant(e) RH',
  'Délégué(e) Régional(e)',
  'Directeur/trice Commercial(e)',
  'Directeur/trice RH',
  'Directeur/trice administratif et financier',
  'Directeur/trice du Développement et Relation Client',
  'Directeur/trice Général(e)',
  'Directeur/trice Général(e) Adjoint(e)',
  'Directeur/trice Opérationnel(le)',
  'Directeur/trice RH Adjoint(e)',
  'Directeur/trice RH Europe',
  'Directeur/trice RH France',
  'Dirigeant(e)',
  'Head of People Development',
  'Human Resources Manager',
  'International Mobility Manager',
  'Président(e) - Fondateur/trice',
  'Professeur(e) des Universités',
  'Psychologue',
  'Responsable de Mission',
  'Responsable Département Relations Sociales',
  'Responsable des Opérations',
  'Responsable des Ventes',
  'Responsable Développement RH International',
  'Responsable du Développement Social',
  'Responsable RH',
  'Responsable Formation',
  'Responsable Occitanie',
  'Responsable Réseau Commercial',
  'Responsable RH et Paie',
  'Responsable des Solutions Emplois',
  "Responsable Sportif de l'association",
  'Responsable CDF',
  'Responsable Pédagogique CDF',
  'Responsable EDR',
  'Responsable Arbitres',
  'Représentant APARE',
  'Secrétaire Général(e)',
  'Secrétariat association',
  'Vice Président(e) RH',
]

TITLES.sort()

export const POSITIONS: {id: number; title: string | null}[] = TITLES.map((title, index) => {
  return {id: index + 1, title}
})
