import React from 'react'
import {connect} from 'react-redux'
import {useAppSelector} from '../../../reducers/hooks'

const HeaderSignup = ({signin}: {signin: boolean}) => {
  const clubState = useAppSelector((state) => state.clubState)
  return (
    <div className="text-center py-4 text-white">
      <div className="avatar avatar-lg mb-3">
        <img src={clubState.club_image} className="avatar-img img-fluid rounded-circle" alt="tmo-logo-club" />
      </div>
      {!signin && (
        <div>
          <h1 className="mb-3 welcome-title">Bienvenue !</h1>
          <p>Veuillez compléter votre profil pour finaliser votre inscription.</p>
        </div>
      )}
      {signin && (
        <div>
          <h1 className="mb-3 welcome-title">Bonjour !</h1>
          <p>Bienvenue sur le réseau social des partenaires du {clubState.club_name}</p>
        </div>
      )}
    </div>
  )
}

export default HeaderSignup
