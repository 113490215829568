import React, {useCallback, useEffect, useState} from 'react'
import InputFiles from 'react-input-files'
import ReactModal from 'react-modal'
import {addNewPost} from '../../../actions/postsAction'
import {useAppDispatch, useAppSelector} from '../../../reducers/hooks'
import api from '../../../services/api'
import {API_POST_ADD, POST_IMAGE_UPLOAD_LINK, REGEX_HASHTAGS, REGEX_USER_MENTION, USER_IMAGE_UPLOAD_LINK} from '../../../services/constants'
import {fileReaderToBase64} from '../../../services/fs'
import {logger} from '../../../services/logger'
import {imageAppendUrl} from '../../../services/utils'
import {ZIndexLayers} from '../../../theme'
import MessagePost from '../../form/input/message-post'

enum PostType {
  Text = 1,
  Image = 2,
  Video = 3,
}

const modalStyles: ReactModal.Styles = {
  ...ReactModal.defaultStyles,
  content: {
    position: 'initial',
    margin: '40px',
    marginLeft: '80px',
    marginRight: '80px',
  },
  overlay: {
    ...ReactModal.defaultStyles.overlay,
    flexDirection: 'column',
    display: 'inline-flex',
    zIndex: ZIndexLayers.Modal,
  },
}

ReactModal.setAppElement('#root')
const PostModal = ({onDismiss, isVisible}: {onDismiss: () => void; isVisible: boolean}) => {
  const dispatch = useAppDispatch()
  const config = useAppSelector((state) => state.apiR)

  const [visible, setVisible] = useState(isVisible)

  useEffect(() => {
    setVisible(isVisible)
  }, [isVisible])

  const [selectedTab, setSelectedTab] = useState<'text' | 'video' | 'image'>('text')
  const [content, setContent] = useState('')
  const [videoUrl, setVideoUrl] = useState<string | undefined>()
  const [asset, setAsset] = useState<{
    image?: string | ArrayBuffer | null
    image_ext?: string
    image_name: string
  }>({
    image: undefined,
    image_ext: undefined,
    image_name: 'Selectionnez une image',
  })

  const hasContent = content !== undefined && content.trim().length === 0
  const hasVideo = videoUrl !== undefined
  const hasImage = asset !== undefined && asset.image !== undefined
  const hasChanges = hasContent || hasVideo || hasImage

  const onTabChange = useCallback(
    (next: 'text' | 'video' | 'image') => {
      setSelectedTab((prev) => {
        if (prev === next) return prev
        switch (next) {
          case 'text':
            setAsset({image: undefined, image_ext: undefined, image_name: ''})
            setVideoUrl(undefined)
            break
          case 'video':
            setAsset({image: undefined, image_ext: undefined, image_name: ''})
            break
          case 'image':
            setVideoUrl(undefined)
            break
          default:
            break
        }
        return next
      })
    },
    [selectedTab]
  )

  const handleFiles = useCallback((files: File[]) => {
    fileReaderToBase64(files, (data) => {
      if (data.image && typeof data.image === 'string') {
        setAsset({image: data.image.split(',')[1], image_ext: data.image_ext, image_name: data.image_name})
      } else {
        // TODO what ?
      }
    })
  }, [])
  const resetData = useCallback(() => {
    setSelectedTab('text')
    setContent('')
    setAsset({image: undefined, image_ext: undefined, image_name: 'Selectionnez une image'})
    setVideoUrl(undefined)
  }, [])
  const submitData = useCallback(async () => {
    const {image, image_ext} = asset

    let mentionedUsers = content.match(REGEX_USER_MENTION)
    if (mentionedUsers) {
      mentionedUsers = mentionedUsers.map((element) => element.substring(element.indexOf('(') + 1, element.lastIndexOf(')')))
    }

    const tags = content.match(REGEX_HASHTAGS)

    const bodyFormData = new FormData()

    bodyFormData.set('content', content)
    bodyFormData.set('mentioned_users_id', JSON.stringify(mentionedUsers || []))
    bodyFormData.set('tags', JSON.stringify(tags || []))

    if (!image && !videoUrl) {
      bodyFormData.set('postType', `${PostType.Text}`)
    } else if (videoUrl) {
      bodyFormData.set('postType', `${PostType.Video}`)
      bodyFormData.set('attachment', videoUrl)
    } else if (image) {
      bodyFormData.set('postType', `${PostType.Image}`)
      bodyFormData.set('attachment', (image as string) || '')
      bodyFormData.set('ext', image_ext || '')
    }

    api
      .post(API_POST_ADD, bodyFormData, config)
      .then((res: {data: {postType: {libelle: string}; user: {avatar: string}; attachment: string}}) => {
        resetData()
        onDismiss()

        dispatch(
          addNewPost({
            ...res.data,
            attachment: res.data.postType.libelle === 'image' ? imageAppendUrl(POST_IMAGE_UPLOAD_LINK, res.data.attachment) : res.data.attachment,
            user: {
              ...res.data.user,
              avatar: imageAppendUrl(USER_IMAGE_UPLOAD_LINK, res.data.user.avatar),
            },
          })
        )
      })
      .catch((err) => {
        logger.debug('Add new post ERROR: ', err)
      })
  }, [asset, content, videoUrl])

  return (
    <ReactModal
      isOpen={visible}
      style={modalStyles}
      shouldCloseOnOverlayClick={!hasChanges}
      // should match react-modal.scss fade timing
      closeTimeoutMS={250}>
      <div className="modal-content">
        <div className="modal-body">
          <div className="modal-avatar-edit mr-3 d-none">
            <img src="https://randomuser.me/api/portraits/men/10.jpg" className=" avatar-md shadow" alt="tmo-logo-user" />
          </div>
          <div className="modal-inputs w-100">
            <div className="tab-content">
              <div role="tabpanel" id="post_simple" className={`${selectedTab === 'text' ? 'active ' : ''}tab-pane fade show`} aria-labelledby="messenger-tab">
                <form className="my-2">
                  <MessagePost defaultValue={content} returnValue={(value: string) => setContent(value)} />
                </form>
              </div>
              <div role="tabpanel" id="post_image" className={`${selectedTab === 'image' ? 'active ' : ''}tab-pane fade show`} aria-labelledby="messenger-tab">
                <form className="my-2">
                  <div className="form-group my-2">
                    <label htmlFor="up-glr">Selectionnez une image :</label>
                    <div id="up-glr" className="custom-file">
                      <div className="position-relative w-100 h-100">
                        <InputFiles onChange={(files: Array<File>) => handleFiles(files)}>
                          <div className="custom-file-input w-100 h-100" />
                          <label className="custom-file-label" htmlFor="validatedCustomFile">
                            {asset.image_name}
                          </label>
                        </InputFiles>
                      </div>
                      <div className="invalid-feedback">Example invalid custom file feedback</div>
                    </div>
                  </div>
                  <MessagePost defaultValue={content} returnValue={(value: string) => setContent(value)} />
                </form>
              </div>
              <div role="tabpanel" id="post_video" className={`${selectedTab === 'video' ? 'active ' : ''}tab-pane fade show`} aria-labelledby="messenger-tab">
                <form className="my-2">
                  <div className="form-group my-2">
                    <label htmlFor="video-link">Lien vers la vidéo :</label>
                    <input className="form-control" type="url" id="video-link" placeholder="https://" onChange={(e) => setVideoUrl(e.target.value)} />
                  </div>
                  <MessagePost defaultValue={content} returnValue={(value: string) => setContent(value)} />
                </form>
              </div>
            </div>
            <div className="feed-type post-navigation">
              <ul className="button-nav nav nav-tabs d-flex align-items-center border-0" role="tablist" id="message-tabs">
                <li>
                  <a
                    className={selectedTab === 'text' ? 'active' : ''}
                    href="#post_simple"
                    data-toggle="tab"
                    role="tab"
                    id="post_simple"
                    aria-selected={selectedTab === 'text'}
                    onClick={() => onTabChange('text')}>
                    <svg
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fas"
                      data-icon="align-left"
                      className="icon"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512">
                      <path
                        fill="currentColor"
                        d="M12.83 352h262.34A12.82 12.82 0 0 0 288 339.17v-38.34A12.82 12.82 0 0 0 275.17 288H12.83A12.82 12.82 0 0 0 0 300.83v38.34A12.82 12.82 0 0 0 12.83 352zm0-256h262.34A12.82 12.82 0 0 0 288 83.17V44.83A12.82 12.82 0 0 0 275.17 32H12.83A12.82 12.82 0 0 0 0 44.83v38.34A12.82 12.82 0 0 0 12.83 96zM432 160H16a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16zm0 256H16a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16z"
                      />
                    </svg>
                    <p className="m-0 p-0">Texte</p>
                  </a>
                </li>
                <li>
                  <a
                    className={selectedTab === 'image' ? 'active' : ''}
                    href="#post_image"
                    data-toggle="tab"
                    role="tab"
                    id="messenger-tab"
                    aria-selected={selectedTab === 'image'}
                    onClick={() => onTabChange('image')}>
                    <svg
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="far"
                      data-icon="image"
                      className="icon"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512">
                      <path
                        fill="currentColor"
                        d="M464 64H48C21.49 64 0 85.49 0 112v288c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49 48-48V112c0-26.51-21.49-48-48-48zm-6 336H54a6 6 0 0 1-6-6V118a6 6 0 0 1 6-6h404a6 6 0 0 1 6 6v276a6 6 0 0 1-6 6zM128 152c-22.091 0-40 17.909-40 40s17.909 40 40 40 40-17.909 40-40-17.909-40-40-40zM96 352h320v-80l-87.515-87.515c-4.686-4.686-12.284-4.686-16.971 0L192 304l-39.515-39.515c-4.686-4.686-12.284-4.686-16.971 0L96 304v48z"
                      />
                    </svg>
                    <p className="m-0 p-0">Image</p>
                  </a>
                </li>
                <li>
                  <a
                    className={selectedTab === 'video' ? 'active' : ''}
                    href="#post_video"
                    data-toggle="tab"
                    role="tab"
                    id="messenger-tab"
                    aria-selected={selectedTab === 'video'}
                    onClick={() => onTabChange('video')}>
                    <svg
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fas"
                      data-icon="film"
                      className="icon"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512">
                      <path
                        fill="currentColor"
                        d="M488 64h-8v20c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12V64H96v20c0 6.6-5.4 12-12 12H44c-6.6 0-12-5.4-12-12V64h-8C10.7 64 0 74.7 0 88v336c0 13.3 10.7 24 24 24h8v-20c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v20h320v-20c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v20h8c13.3 0 24-10.7 24-24V88c0-13.3-10.7-24-24-24zM96 372c0 6.6-5.4 12-12 12H44c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40zm0-96c0 6.6-5.4 12-12 12H44c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40zm0-96c0 6.6-5.4 12-12 12H44c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40zm272 208c0 6.6-5.4 12-12 12H156c-6.6 0-12-5.4-12-12v-96c0-6.6 5.4-12 12-12h200c6.6 0 12 5.4 12 12v96zm0-168c0 6.6-5.4 12-12 12H156c-6.6 0-12-5.4-12-12v-96c0-6.6 5.4-12 12-12h200c6.6 0 12 5.4 12 12v96zm112 152c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40zm0-96c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40zm0-96c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40z"
                      />
                    </svg>
                    <p className="m-0 p-0">Vidéo</p>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-outline-secondary p-0"
            data-dismiss="modal"
            onClick={() => {
              resetData()
              onDismiss()
            }}>
            Fermer
          </button>
          <button type="button" className="btn btn-primary p-0" id="submit" onClick={() => submitData()}>
            Publier
          </button>
        </div>
      </div>
    </ReactModal>
  )
}

export default PostModal
