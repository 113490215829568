export const postDelete = (payload) => ({
    type: 'erasePosts',
    payload
})

export const postLoad = (payload) => ({
    type: 'loadPosts',
    payload
})

export const addNewPost = (payload) => ({
    type: 'postNewAdd',
    payload
})

export const postLikes = (payload) => ({
    type: 'postLikes',
    payload
})

export const postFilter = (payload) => ({
    type: 'postsFilter',
    payload
})

export const postModify = (payload) => ({
    type: 'postModify',
    payload
})

export const postUpdateHeader = (payload) => ({
    type: 'postUpdateHeader',
    payload
})

export const postDetails = (payload) => ({
    type: 'postDetails',
    payload
})

export const cleanPosts = () => ({
    type: 'cleanPosts'
})

export const adPostsLoad = (payload) => ({
    type: 'adPostsLoad',
    payload
})