import React, { Component } from 'react';
import { connect } from 'react-redux';
import { postFilter } from '../../../actions/postsAction';
import { Post } from '../post';
import InfiniteLoader from 'react-infinite-loader';
import { imageAppendUrl } from '../../../services/utils';
import { POST_IMAGE_UPLOAD_LINK, USER_IMAGE_UPLOAD_LINK, API_POSTS_FILTER_LOAD } from '../../../services/constants';
import api from '../../../services/api';
import {logger} from "../../../services/logger";

class PostFilter extends Component {
    constructor() {
        super();
        this.state = {
            postsFiltered: [],
            pagination: 1,
        }
        this.loadFilteredPosts = this.loadFilteredPosts.bind(this);
    }

    componentDidMount() {
        this.loadFilteredPosts();
    }

    loadFilteredPosts = () => {
        if (this.state.pagination >= 5) {
            return;
        }
        api.post(API_POSTS_FILTER_LOAD(this.state.pagination, this.state.postsFiltered.length), {filter: this.props.postsR.filter}, this.props.config)
        .then(async res => {
            for (var i = 0; i < res.data.post.length; i++) {
                if (res.data.post[i].postType.libelle !== "video") {
                    res.data.post[i].attachment = imageAppendUrl(POST_IMAGE_UPLOAD_LINK, res.data.post[i].attachment);
                }
                if (res.data.post[i].user) {
                    res.data.post[i].user.avatar = imageAppendUrl(USER_IMAGE_UPLOAD_LINK, res.data.post[i].user.avatar);
                }
            }
            if (this.state.postsFiltered.length === 10 * (this.state.pagination - 1)) {
                await this.setState({pagination: this.state.pagination + 1, postsFiltered: [...this.state.postsFiltered, ...res.data.post], });
            }
        })
        .catch(err => {
            if (err.response && err.response.status === 400) {
                return;
            }
            logger.debug('Load posts ERROR', err);
        })
    }

    render() {
        return (
            <div id="#postsFiltered" className="container">
                <div className="d-flex align-items-center">
                    <h6 className="my-1 text-left text-light">Filtré par: {this.props.postsR && this.props.postsR.filter}</h6>
                    <div className="ml-auto">
                        <button className="btn btn-primary-darker text-light" onClick={() => this.props.postFilter(undefined)}>Retour</button>
                    </div>
                </div>
                {
                    this.state.postsFiltered && this.state.postsFiltered.length <= 0 &&
                    <h5 className="text-center text-white pt-4">Il n'existe désormais pas de posts avec le tag: {this.props.postsR && this.props.postsR.filter}.</h5>
                }
                <ul key="0">
                    {
                        this.state.postsFiltered.map((element) => (
                            <li key={element.id}>
                                <Post post={element} />
                            </li>
                        ))
                    }
                    <InfiniteLoader onVisited={() => setTimeout(this.loadFilteredPosts(), 500)} loaderStyle={{display: "none"}} />
                </ul>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    postsR: state.postsR,
    config: state.apiR,
})

const mapDispatchToProps = dispatch => ({
    postFilter: (payload) => dispatch(postFilter(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(PostFilter);
