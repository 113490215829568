import React, {useEffect, useMemo, useState} from 'react'
import {RouteComponentProps} from 'react-router'
import {NavLink, withRouter} from 'react-router-dom'
import {postFilter} from '../../../actions/postsAction'
import {showDrawerSearch} from '../../../actions/searchAction'
import {useAppDispatch, useAppSelector} from '../../../reducers/hooks'
import {useFeatures} from '../../../shared/hooks/useFeatures'
import {selectConnexionStatus} from '../../../shared/redux/connexionStatus.slice'
import NotificationsDropdown from '../../form/dropdown/notifications'
import {Avatar} from '../../user'
import AnnuaireIcon from './AnnuaireIcon'
import CalendarIcon from './CalendarIcon'
import DocumentsSvg from '../../../assets/svg/DocumentsSvg'
import FaqIcon from './FaqIcon'
import FilActuIcon from './FilActuIcon'
import GalleryIcon from './GalleryIcon'

const NavBar = ({history}: RouteComponentProps) => {
  const dispatch = useAppDispatch()
  const company = useAppSelector((state) => state.companyR)
  const clubState = useAppSelector((state) => state.clubState)
  const userR = useAppSelector((state) => state.userR)
  const {club} = useAppSelector(selectConnexionStatus)

  const {hasFeatures} = useFeatures()

  const [homePage] = useState(window.location.pathname === '/')
  const [annuairePage] = useState(window.location.pathname === '/annuaire')
  const [galleryPage] = useState(window.location.pathname === '/gallery')
  const [documentsPage] = useState(window.location.pathname === '/bibliotheque')
  const [calendarPage] = useState(window.location.pathname === '/calendar')
  const [userRanked, setUserRanked] = useState(false)

  useEffect(() => {
    if (club !== undefined && club !== null) {
      if (club.offerModel !== undefined && club.offerModel !== null && club.offerModel > 1) {
        switch (company.rank) {
          case 0:
            setUserRanked(false)
            break
          case 1:
            setUserRanked(true)
            break
          default:
            setUserRanked(false)
        }
      }
    }
  }, [company, club])

  function goToHomePage() {
    window.scrollTo(0, 0)
    dispatch(postFilter(undefined))
    if (window.location.pathname !== '/') {
      history.push('/')
    } else {
      window.location.reload()
    }
  }

  const NewsButton = useMemo(
    () => (
      <NavLink
        to="/"
        className={`${homePage ? 'text-light' : 'disable_button_top'} font-weight-bold pl-2 d-flex justify-content-center align-items-center flex-wrap`}>
        <FilActuIcon style={{width: 26}} className="mr-sm-1" />
        <span className="d-none d-sm-block" style={{whiteSpace: 'nowrap'}}>
          Fil d'actualité
        </span>
      </NavLink>
    ),
    [homePage]
  )

  const GalleryButton = useMemo(
    () => (
      <NavLink
        to="/gallery"
        className={`${galleryPage ? 'text-light' : 'disable_button_top'} font-weight-bold pl-2 d-flex justify-content-center align-items-center flex-wrap`}>
        <GalleryIcon style={{width: 26}} className="mr-sm-1" />
        <span className="d-none d-sm-block" style={{whiteSpace: 'nowrap'}}>
          Galerie
        </span>
      </NavLink>
    ),
    [galleryPage]
  )

  const DocumentsButton = useMemo(
    () => (
      <NavLink
        to="/bibliotheque"
        className={`${documentsPage ? 'text-light' : 'disable_button_top'} font-weight-bold pl-2 d-flex justify-content-center align-items-center flex-wrap`}>
        <DocumentsSvg height={26} width={26} className="mr-sm-1" />
        <span className="d-none d-sm-block" style={{whiteSpace: 'nowrap'}}>
          Bibliothèque
        </span>
      </NavLink>
    ),
    [documentsPage]
  )

  const AnnuaireButton = useMemo(
    () => (
      <NavLink
        to="/annuaire"
        className={`${annuairePage ? 'text-light' : 'disable_button_top'} font-weight-bold pl-2 d-flex justify-content-center align-items-center flex-wrap`}>
        <AnnuaireIcon style={{width: 26}} className="mr-sm-1" />
        <span className="d-none d-sm-block" style={{whiteSpace: 'nowrap'}}>
          Annuaire
        </span>
      </NavLink>
    ),
    [annuairePage]
  )

  const CalendarButton = useMemo(
    () => (
      <NavLink
        to="/calendar"
        className={`${calendarPage ? 'text-light' : 'disable_button_top'} font-weight-bold pl-2 d-flex justify-content-center align-items-center flex-wrap`}>
        <CalendarIcon style={{width: 26}} className="mr-sm-1" />
        <span className="d-none d-sm-block" style={{whiteSpace: 'nowrap'}}>
          Agenda
        </span>
      </NavLink>
    ),
    [calendarPage]
  )

  const initials = useMemo(
    () =>
      `${userR.firstName} ${userR.lastName}`
        .split(' ')
        .map((s) => s[0])
        .join('')
        .toUpperCase(),
    [userR.firstName, userR.lastName]
  )

  return (
    <nav className="navbar navbar-expand-lg bg-primary fixed-top">
      <a className="navbar-brand d-none d-lg-block" onClick={goToHomePage}>
        {/* <Avatar alt={clubR.club_name[0]} img={clubR.club_image} size="sm" color="transparent" /> */}
        <span className="ml-1 text-white text-uppercase">{clubState.club_name}</span>
      </a>
      <div className="navbar-container d-flex w-100 justify-content-between align-items-center" id="mobile">
        <div className="nav-item d-flex align-items-center ml-lg-5">
          {AnnuaireButton}
          {hasFeatures('news') && NewsButton}
          {hasFeatures('gallery') && GalleryButton}
          {hasFeatures('ged') && DocumentsButton}
          {hasFeatures('calendar') && CalendarButton}
        </div>
        {!annuairePage && (
          <>
            <ul className="ml-auto">
              <li className="nav-item" data-toggle="tooltip" data-placement="bottom" title="Notifications">
                <NotificationsDropdown />
              </li>
            </ul>
            <ul>
              <li className="nav-item mx-1">
                <NavLink className="text-center" to="/faq" data-toggle="tooltip" data-placement="bottom" title="Aide">
                  <FaqIcon />
                </NavLink>
              </li>
            </ul>
          </>
        )}
        <ul className={`navbar-nav${annuairePage ? ' ml-auto' : ''}`}>
          <li className="nav-item d-none d-lg-block position-relative">
            <a className="btn nav-link nav-profile p-0 pr-3 btn-primary-darker">
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="power-off"
                className="icon mr-1"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512">
                <path
                  fill="white"
                  d="M400 54.1c63 45 104 118.6 104 201.9 0 136.8-110.8 247.7-247.5 248C120 504.3 8.2 393 8 256.4 7.9 173.1 48.9 99.3 111.8 54.2c11.7-8.3 28-4.8 35 7.7L162.6 90c5.9 10.5 3.1 23.8-6.6 31-41.5 30.8-68 79.6-68 134.9-.1 92.3 74.5 168.1 168 168.1 91.6 0 168.6-74.2 168-169.1-.3-51.8-24.7-101.8-68.1-134-9.7-7.2-12.4-20.5-6.5-30.9l15.8-28.1c7-12.4 23.2-16.1 34.8-7.8zM296 264V24c0-13.3-10.7-24-24-24h-32c-13.3 0-24 10.7-24 24v240c0 13.3 10.7 24 24 24h32c13.3 0 24-10.7 24-24z"
                />
              </svg>
              <span className="text-light" onClick={() => history.push('/logout')}>
                Déconnexion
              </span>
              <Avatar className="position-absolute" img={userR.avatar} alt={initials} size="sm" />
            </a>
          </li>
          <li className="btn-search nav-item d-lg-none d-block">
            <div className="dropdown">
              <div
                className="avatar-edit mr-n1 dropdown-toggle"
                id="dropdownMenu1"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                style={{cursor: 'pointer'}}>
                <Avatar img={userR.avatar} alt={initials} size="sm" />
              </div>
              <div className="dropdown-menu position-absolute" style={{left: -75}} aria-labelledby="dropdownMenu1">
                {userRanked && (
                  <>
                    <a
                      className="dropdown-item bg-transparent text-dark"
                      onClick={() => {
                        if (window.location.pathname !== '/') {
                          history.push('/')
                        }
                        dispatch(showDrawerSearch(true))
                      }}>
                      Mon Réseau
                    </a>
                    <div className="dropdown-divider" />
                  </>
                )}
                <a className="dropdown-item bg-transparent text-dark" onClick={() => history.push('/logout')}>
                  Déconnexion
                </a>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </nav>
  )
}

export default withRouter(NavBar)
