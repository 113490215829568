import React from 'react';
import api from '../../../services/api';
import { connect } from 'react-redux';
import { searchResults, searchFilter } from '../../../actions/searchAction';
import { API_SEARCH_COMPANIES_USERS, COMPANY_IMAGE_UPLOAD_LINK, USER_IMAGE_UPLOAD_LINK } from '../../../services/constants';
import { imageAppendUrl } from '../../../services/utils';
import {logger} from "../../../services/logger";
const qs = require('querystring');

class SearchButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            filter: "",
            pagination: 1,
            showed: props.showed,
        }
        this.handleSearch = this.handleSearch.bind(this);
    }

    handleSearch = async (e) => {
        e.preventDefault();
        await this.setState({filter: e.target.value, });
        this.props.searchFilter(this.state.filter);
        if (this.state.filter === "" || this.state.filter === undefined) {
            this.props.searchResults({users: [], companies: []});
            return;
        }
        api.post(API_SEARCH_COMPANIES_USERS(this.state.pagination), qs.stringify({filter: this.state.filter}), this.props.config)
        .then(res => {
            const searchResult = {
                companies: res.data.companies && res.data.companies.map(el => ({
                    ...el,
                    image: imageAppendUrl(COMPANY_IMAGE_UPLOAD_LINK, el.logo),
                    background_image: imageAppendUrl(COMPANY_IMAGE_UPLOAD_LINK, el.backgroundImage),
                    users: el.users && el.users.map(el => ({
                        ...el,
                        image: imageAppendUrl(USER_IMAGE_UPLOAD_LINK, el.avatar),
                    }))
                })),
                users: res.data.users && res.data.users.map(el => ({
                    ...el,
                    avatar: imageAppendUrl(USER_IMAGE_UPLOAD_LINK, el.avatar),
                }))
            }
            this.props.searchResults(searchResult);
        })
        .catch(err => {
            logger.debug("Search results ERROR: ", err);
        });
    }

    render() {
        return (
            <form onSubmit={e => e.preventDefault()} id="group_form" className="group group-hide form-inline position-relative text-right my-2 my-lg-0 w-100">
                <input id="search_group" value={this.props.search.filter} className={"form-control search_group w-100 hide" + (this.state.showed ? " show" : "")} type="text" placeholder="Rechercher" aria-label="Search" onChange={this.handleSearch}/>
                <div className="search_group_icon text-right position-absolute w-auto">
                    <div className="shadow-lg" onClick={() => this.setState({showed: !this.state.showed})}>
                        <svg id="icon_search" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="search" className="icon text-primary" style={{width: 45, height: 45}} role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"></path></svg>
                    </div>
                </div>
            </form>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    searchResults: (payload) => dispatch(searchResults(payload)),
    searchFilter: (payload) => dispatch(searchFilter(payload)),
})

const mapStateToProps = state => ({
    config: state.apiR,
    user: state.userR,
    search: state.searchR,
})

export default connect(mapStateToProps, mapDispatchToProps)(SearchButton);
