export const userDetailsLoad = (payload) => {
    return {
        type: "userDetailsLoad",
        payload
    }
}

export const userDetailsShow = (payload) => {
    return {
        type: "userDetailsShow",
        payload
    }
}