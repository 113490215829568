import React, { Component } from 'react';
import {SignupInput} from '../../../component/form';
import {connect} from 'react-redux';
import {companyImg, companyData} from '../../../actions/companyAction';
import { ImageEdit } from '../../../component/images';
import { unauthedApi } from '../../../services/api';
import { API_GET_ALL_SECTORS, selectStyle } from '../../../services/constants';
import Select from 'react-select'
import {logger} from "../../../services/logger";

class Signup_2 extends Component {
    constructor(props) {
        super();
        this.state = {
            ...props.companyR,
            activitiesSector: []
        }
    }

    async retrieveData(data, name) {
        await this.setState({
            ...this.state,
            [name]: data,
        })
        var payload = this.state;
        this.props.companyData(payload);
    }

    newImg(file, extension) {
        this.props.companyImg({img_data: file.split(',')[1], extension: extension});
        this.setState({company_avatar: file})
    }

    componentDidMount() {
        unauthedApi.get(API_GET_ALL_SECTORS, {
          headers: {
            Authorization: `Bearer ${this.props.accountRegistrationSlice.token}`,
          }
        })
        .then(res => {
            this.setState({
                ...this.state,
                activitiesSector: res.data.map(el => ({
                    label: el.libelle,
                    value: el.id
                }))
            })
        })
        .catch(err => {
            console.error('Get all companies of club ERROR: ', err);
        })
    }

    render() {
        if (!this.props.companyR) {
            // ! Code SU-002
            logger.debug('DEBUG -- Une erreur est survenue... Code SU-002');
            return <h1 className="text-center">Une erreur est survenue lors du chargement de cette page... Code SU-002</h1>
        }

        return (
            <div className="text-center mt-n4">
                <ImageEdit classes="avatar-edit" avatar={{alt: this.props.companyR.name && this.props.companyR.name[0], src: this.state.company_avatar}} setDB={(file, extension) => {this.newImg(file, extension)}}/>
                <p className="text-primary mt-n1"><strong>{this.props.companyR.name}</strong></p>
                <form>
                    <p className="text-left mb-1"><small className="text-muted">Secteur d'activité *</small></p>
                    {
                        this.state.activitiesSector ?
                        <Select
                            styles={selectStyle(this.props.clubState.club_color)}
                            options={this.state.activitiesSector}
                            onChange={(value) => this.retrieveData(value, 'sector')}
                            defaultValue={this.state.activitiesSector.filter(el => el.label === (this.props.companyR.sector && this.props.companyR.sector.libelle))[0]}
                            value={this.state.activitiesSector.filter(el => el.label === (this.props.companyR.sector && this.props.companyR.sector.libelle))[0]}
                            placeholder="Secteur d'activité..."
                            noOptionsMessage={() => "Pas de secteurs d'activités"}
                        />
                        :
                        // Code SUA-001
                        logger.debug('DEBUG -- Une erreur est survenue... Code SUA-001')
                    }
                    <SignupInput id="linkedinUrl" label="Linkedin" value={this.props.companyR.linkedinUrl} retrieve={(data, name) => this.retrieveData(data, name)}/>
                    <SignupInput id="websiteUrl" label="Site internet" value={this.props.companyR.websiteUrl} retrieve={(data, name) => this.retrieveData(data, name)}/>
                    <SignupInput id="subtitle" label="Description" value={this.props.companyR.subtitle} params={{multiline: true}} retrieve={(data, name) => this.retrieveData(data, name)}/>
                </form>
            </div>
        );
    }
}


const mapStateToProps = state => ({
  ...state
});
const mapDispatchToProps = dispatch => ({
  companyData: (payload) => dispatch(companyData(payload)),
  companyImg: (payload) => dispatch(companyImg(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Signup_2);
