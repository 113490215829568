import React, {Component} from 'react'
import {connect} from 'react-redux'
import {HeaderSignup} from '../../component/header'
import {Signup_1, Signup_2, Signup_3} from '../../component/signup'
import API from '../../services/api'
import {getToken} from '../../actions/apiAction'
import {ImageEdit} from '../../component/images'
import {companyBackImg, companyLoad, companyImg} from '../../actions/companyAction'
import {API_USER_REGISTRATION} from '../../services/constants'
import {userActions} from '../../reducers/userReducer'
import {logger} from '../../services/logger'
import {ConnexionState, connexionStatusActions} from '../../shared/redux/connexionStatus.slice'
import {syncWordTheme} from '../../shared/redux/wordThemeSlice'
import { Feature, hasFeatures } from '../../shared/hooks/useFeatures'
import {featureSliceActions} from '../../shared/redux/feature.slice'

const qs = require('querystring')

const PAGE_FINISH = 2
const PAGE_PROFILE_COMPANY = 1
const PAGE_PROFILE_USER = 0

class SignUp extends Component {
  constructor(props) {
    super()
    this.state = {
      index: 0,
      page: Signup_1,
      button: 'Suivant',
      signin: false,
      isOkay: true,
      background_image: props.companyR.background_image,
      canLogin: false,
    }
    this.pages = [Signup_1, Signup_2, Signup_3]
    this.buttons = ['Suivant', 'Confirmer', '']
  }

  nullValues() {
    const userState = this.props.userR
    const required = [userState.email, userState.phone_number, userState.password, userState.password_confirm]
    const required_id = ['email', 'phone_number', 'password', 'password_confirm']
    let element = this.state.index ? 5 : 0
    const checked = 0

    for (; element < required.length; element++) {
      if (!this.state.index && element === 5) {
        if (!this.state.isOkay && document.getElementById('phone_number') && document.getElementById('phone_number').parentElement) {
          document.getElementById('phone_number').parentElement.classList.add('Mui-error')
          return true
        }
        if (checked) {
          return true
        }
        return false
      }
      if (required[element] === undefined || required[element] === '') {
        if (document.getElementById(required_id[element]) && document.getElementById(required_id[element]).parentElement) {
          document.getElementById(required_id[element]).parentElement.classList.add('Mui-error')
        }
        return true
      }
    }
    if (checked) {
      return true
    }
    return false
  }

  nextPage(action, e) {
    e.preventDefault()

    if (this.nullValues()) {
      return
    }

    let nextPageIndex = this.state.index + action

    if (nextPageIndex === PAGE_PROFILE_COMPANY) {
      const isCompanyModifiable = hasFeatures(this.props.connexionStatusRedux.user, this.props.connexionStatusRedux.club, this.props.features.club, 'partner_modify_for_members')
      if (!isCompanyModifiable) {
        // skip modifying of company
        nextPageIndex += 1
      }
    }

    let nextPage = this.pages[nextPageIndex]

    if (nextPageIndex === PAGE_FINISH) {
      this.sendData()
      this.setState({signin: true})
    } else if (this.state.signin) {
      this.setState({signin: false})
    }
    this.setState({button: this.buttons[nextPageIndex]})
    this.setState({page: nextPage, index: nextPageIndex})
  }

  async sendData() {
    const data = qs.stringify({...this.props.userR, ...this.props.companyR, sector: this.props.companyR.sector.value, deviceType: 'web'})
    await API.post(API_USER_REGISTRATION, data, {
      headers: {
        Authorization: `Bearer ${this.props.accountRegistrationSlice.token}`,
      },
    })
      .then((res) => {
        const {user, deviceId, token, club} = res.data
        if (token) {
          this.props.onConnexion({
            idUser: user.id,
            tokenUser: token,
            deviceId,
            user,
            club,
          })
          this.props.setClubFeatures(club.features.map(({featureKey}) => featureKey))

          this.props.syncWordTheme()
          this.props.getToken(token)

          this.props.userLoad({
            ...user,
            deviceId,
          })
          this.props.companyLoad(user.company)

          this.props.getToken(token)

          localStorage.setItem('Authorization', JSON.stringify(token))
          localStorage.setItem('User', JSON.stringify(user))
          localStorage.setItem('Device', JSON.stringify(deviceId))
          localStorage.setItem('Company', JSON.stringify(user.company))
          localStorage.setItem('Club', JSON.stringify(club))

          this.setState({canLogin: true})
        }
      })
      .catch((err) => {
        logger.debug('Submit signup data ERROR: ', err)
      })
  }

  saveImg(file, extension) {
    this.setState({background_image: file})
    this.props.companyBackImg({img_data: file.split(',')[1], extension})
  }

  render() {
    const PageContent = this.state.page
    return (
      <div className="bg-welcome mb-4 pb-4">
        <HeaderSignup signin={this.state.signin} />
        <div className="container p-0">
          <div className="row justify-content-center">
            <div className="col-12 col-sm-8 col-lg-6">
              <div className="card ml-1 mr-1">
                {this.state.index === 1 && (
                  <ImageEdit
                    classes="img-timeline"
                    style={{backgroundImage: `url(${this.state.background_image})`}}
                    setDB={(file, extension) => this.saveImg(file, extension)}
                  />
                )}
                <div className="card-body">
                  <PageContent returnValue={(value) => this.setState({...this.state, isOkay: value})} canLogin={this.state.canLogin} />
                  {this.state.index !== 2 && (
                    <div className="text-right mt-1">
                      {this.state.index > 0 && (
                        <button
                          type="submit"
                          className="btn btn-outline-secondary"
                          style={{right: 4, position: 'relative'}}
                          onClick={this.nextPage.bind(this, -1)}>
                          Précédent
                        </button>
                      )}
                      <button type="submit" className="btn btn-outline-primary" onClick={this.nextPage.bind(this, 1)}>
                        {this.state.button}
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  ...state,
})

const mapDispatchToProps = (dispatch) => ({
  onConnexion: (connexionInfo) => dispatch(connexionStatusActions.onConnexion(connexionInfo)),
  getToken: (payload) => dispatch(getToken(payload)),
  syncWordTheme: () => dispatch(syncWordTheme()),
  companyLoad: (payload) => dispatch(companyLoad(payload)),
  setClubFeatures: (payload) => dispatch(featureSliceActions.setClubFeatures(payload)),
  userLoad: (payload) => dispatch(userActions.userLoad(payload)),
  companyBackImg: (payload) => dispatch(companyBackImg(payload)),
  companyAvatar: (payload) => dispatch(companyImg(payload)),
  userImg: (payload) => dispatch(userActions.userImg(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(SignUp)
