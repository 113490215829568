import React from 'react'
import {RouteComponentProps} from 'react-router'
import {withRouter} from 'react-router-dom'
import BasePageStructure from '../BasePageStructure'
import CalendarComponents from './calendarComponents'

const CalendarPage = ({location}: RouteComponentProps) => {
  return (
    <BasePageStructure>
      <CalendarComponents queryString={location.search} />
    </BasePageStructure>
  )
}

export default withRouter(CalendarPage)
