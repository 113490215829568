import {fetchRequestNotAuth} from './fetch'
import type {Feature} from '../hooks/useFeatures'
import {authedApi, unauthedApi} from '../../services/api'
import {logger} from '../../services/logger'
import {HttpError, InvalidCredentialsError} from './errors'

export type LoginResAccount = {
  id: number
  enabled: true
  authkey: string
}

export type LoginClubRes = {
  id: number
  enabled: boolean
  name: string
  avatar: string
  email: string
  offerModel: number
  domainName: string
  primaryColor: string
  secondaryColor: string
  features: {featureKey: Feature}[]
  filename_sponsor?: string
}

export type LoginCompany = {
  logo: string
  enabled: boolean
  name: string
  subtitle: string
  linkedinUrl: string
  websiteUrl: string
  backgroundImage: string
  rank: number
  sector: {
    id: number
    libelle: string
  }
}

export type LoginUser = {
  id: number
  enabled: boolean
  firstName: string
  lastName: string
  email: string
  company: LoginCompany
  position: string
  avatar: string
  phone_number: string
  linkedin_url: string
  description: string
  accounts: LoginResAccount[]
}

export type AccountAuth = {
  user: LoginUser
  company: LoginCompany
  account: LoginResAccount
  club: LoginClubRes
  deviceId: number
  token: string
}
export type LoginRes = {
  auth: AccountAuth[]
}

export type LoginParams = {
  username: string
  password: string
  deviceId?: string
  deviceType: 'ios' | 'android' | 'windows' | 'macos' | 'web'
}

const login = async (params: LoginParams): Promise<LoginRes & {status: number; response?: Response}> => {
  try {
    const res = await unauthedApi.post('userapi/login', params)
    return res.data
  } catch (e) {
    if (e.response) {
      logger.warn('could not connect to server. (response)', JSON.stringify(e.response))
      if (e.response.status === 401) {
        throw new InvalidCredentialsError()
      } else if (e.response.status !== undefined) {
        throw new HttpError(e.response.status, e.response.statusText)
      }
    } else if (e.request) {
      logger.warn('could not connect to server.', e)
    } else {
      logger.warn('unknown error', e)
    }
    throw e
  }
}

const refreshAuthToken = async (): Promise<{token: string}> => {
  try {
    const res = await authedApi.post('/apicommon/refresh-auth-token', {})
    return res.data
  } catch (e) {
    if (e.response) {
      logger.warn('could not connect to server. (response)', JSON.stringify(e.response))
      if (e.response.status === 401) {
        throw new InvalidCredentialsError()
      }
    } else if (e.request) {
      logger.warn('could not connect to server.', e)
    } else {
      logger.warn('unknown error', e)
    }
    throw e
  }
}

export default {
  login,
  refreshAuthToken,
}
