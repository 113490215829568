import React from 'react';
import Avatar from './Avatar';
import { dateFormaterPost } from '../../../services/utils';

function ClubIdentifier (props) {
    if (!props.user) {
        return null;
    }
    return (
        <div className="col-9 p-0 d-flex align-items-center">
            <div className="avatar-edit mr-3">
                <Avatar alt={props.user.name && props.user.name[0]} img={props.user.avatar} size="sm" />
            </div>
            <div className="user-profile-name flex-column">
                <p className="text-primary-darker m-0"><strong>{props.user.name}</strong></p>
                {
                    props.date &&
                    <p className="text-primary m-0" style={{fontSize: 10}}>{dateFormaterPost(props.date)}</p>
                }
            </div>
        </div>
    );
}


export default ClubIdentifier;
