import React from 'react'
import {Provider} from 'react-redux'
import {ConfirmProvider} from 'material-ui-confirm'
import {PersistGate} from 'redux-persist/integration/react'
import Index from './pages/loading_page'
import store from './redux/store'

export const App = ({hideLoader}: {hideLoader: () => void}) => (
  <Provider store={store.store}>
    <PersistGate loading={<div>Loading...</div>} persistor={store.persistor}>
      <ConfirmProvider>
        <Index hideLoader={hideLoader} />
      </ConfirmProvider>
    </PersistGate>
  </Provider>
)
