import {createSlice, PayloadAction} from '@reduxjs/toolkit'

type MessagesState = {
  connected_users: []
  showDrawerMessages: boolean
  newMessages: undefined | number
}
const messages: MessagesState = {
  connected_users: [],
  showDrawerMessages: false,
  newMessages: undefined,
}

const messagesSlice = createSlice({
  initialState: messages,
  name: 'messagesSlice',
  reducers: {
    connectedUsersUpdate: (state, action: PayloadAction<MessagesState['connected_users']>) => {
      state.connected_users = action.payload
    },
    showDrawerMessages: (state, action: PayloadAction<MessagesState['showDrawerMessages']>) => {
      state.showDrawerMessages = action.payload
    },
    nbrMessages: (state, action: PayloadAction<MessagesState['newMessages']>) => {
      state.newMessages = action.payload
    },
  },
})

export const messagesRActions = messagesSlice.actions

export default messagesSlice.reducer
