import React, {useEffect, useMemo} from 'react'
import Datatable, {Media, TableColumn} from 'react-data-table-component'
import LoadingScreen from 'react-loading-screen'
import {loadCompaniesAnnuaire} from '../../../actions/annuaireActions'
import {RootState} from '../../../redux/store'
import {useApi} from '../../../shared/api/useApi'
import {Avatar} from '../../user'
import {useAppDispatch, useAppSelector} from '../../../reducers/hooks'
import api from '../../../services/api'
import {API_GET_ALL_COMPANIES, COMPANY_IMAGE_UPLOAD_LINK} from '../../../services/constants'
import {formatLinks, getLinks, imageAppendUrl} from '../../../services/utils'
import {WordTheme} from '../../../shared/api/wordThemeApi'
import {selectWordTheme} from '../../../shared/redux/wordThemeSlice'
import AnnuaireTableContentExpandRow, {CompanyData} from './annuaire-table-content.js/annuaire-table-content-expand-row'
import AnnuaireTableContentFilter from './annuaire-table-content.js/annuaire-table-content-filter'
import AnnuaireTableStructure from './annuaire-table-structure'

const getColumns: (wordTheme: WordTheme) => TableColumn<CompanyData>[] = (wordTheme: WordTheme) => [
  {
    selector: ({logo}) => logo || '',
    maxWidth: '100px',
    cell: (row) => (
      <div className="py-1">
        <Avatar alt={row.logo} img={imageAppendUrl(COMPANY_IMAGE_UPLOAD_LINK, row.logo)} size="sm" />
      </div>
    ),
  },
  {
    name: `Nom ${wordTheme.of_the_partner_type.toLowerCase()}`,
    selector: ({name}) => name || '',
    sortable: true,
  },
  {
    name: "Secteur d'activité",
    selector: ({sector}) => sector?.libelle || '',
    sortable: true,
    hide: Media.SM,
    cell: (row) => <span>{row.sector && row.sector.libelle}</span>,
  },
  {
    name: 'Description',
    selector: ({subtitle}) => subtitle || '',
    hide: Media.SM,
  },
  {
    name: 'Email / Téléphone / Site internet',
    selector: ({email}) => email || '',
    hide: Media.SM,
    cell: (row) => (
      <div className="d-flex flex-column">
        {row.email && (
          <a href={`mailto:${row.email}`} target="_blank" rel="noopener noreferrer" className="py-1">
            {row.email}
          </a>
        )}
        {row.phone && (
          <a href={`tel:${row.phone}`} className="pb-1">
            {row.phone}
          </a>
        )}
        {row.websiteUrl && (
          <a href={getLinks(row.websiteUrl)} target="_blank" rel="noopener noreferrer">
            {formatLinks(row.websiteUrl)}
          </a>
        )}
      </div>
    ),
  },
]

const AnnuaireTable = () => {
  const dispatch = useAppDispatch()

  const w = useAppSelector(selectWordTheme)
  const config = useAppSelector((state) => state.apiR)
  const companies = useAppSelector((state) => state.annuaireR.companies)

  const [filterText, setFilterText] = React.useState('')
  const [researchByUser, setResearchByUser] = React.useState(false)

  const paginationOptions = {rowsPerPageText: `${w.plural_partner_name} par page`, rangeSeparatorText: 'de'}
  const columns = useMemo(() => getColumns(w), [w])

  const filteredItems = companies.filter((el: CompanyData) => {
    if (!filterText) {
      return true
    }
    if (el.name && el.name.toLowerCase().includes(filterText.toLowerCase())) {
      return true
    }
    if (el.siret && el.siret.toLowerCase().includes(filterText.toLowerCase())) {
      return true
    }
    if (researchByUser) {
      for (let i = 0; i < el.users.length; i++) {
        if (!el.users[i]) {
          // eslint-disable-next-line no-continue
          continue
        }
        if (el.users[i].firstName && el.users[i].firstName.toLowerCase().includes(filterText.toLowerCase())) {
          return true
        }
        if (el.users[i].lastName && el.users[i].lastName.toLowerCase().includes(filterText.toLowerCase())) {
          return true
        }
        if (el.users[i].email && el.users[i].email.toLowerCase().includes(filterText.toLowerCase())) {
          return true
        }
      }
    }
    return false
  })

  const getAllCompaniesApi = useApi(
    () => api.get(API_GET_ALL_COMPANIES, config),
    (res) => {
      dispatch(loadCompaniesAnnuaire(res.data))
    },
    (err) => {
      // ! Code An-200
      console.error('Get all the companies of the club ERROR: Code An-200', err)
    }
  )

  useEffect(() => {
    getAllCompaniesApi.call()
  }, [])

  if (getAllCompaniesApi.isLoading) {
    return (
      <>
        <h3 className="text-light m-0">{w.plural_partner_name}</h3>
        <div className="card mt-3">
          <div className="card-body rounded min-vh-100 d-flex justify-content-center align-items-top">
            <div className="lds-ripple">
              <div />
              <div />
            </div>
          </div>
        </div>
      </>
    )
  }
  if (getAllCompaniesApi.error) {
    return (
      <>
        <h3 className="text-light m-0">{w.plural_partner_name}</h3>
        <div className="card mt-3">
          <div className="card-body rounded min-vh-100">Nous n'avons pu récupérer la liste des {w.plural_partner_name}...</div>
        </div>
      </>
    )
  }

  return (
    <AnnuaireTableStructure>
      <h3 className="text-light m-0">{w.plural_partner_name}</h3>
      <AnnuaireTableContentFilter onFilter={setFilterText} searchByUsers={() => setResearchByUser(!researchByUser)} />
      <Datatable<CompanyData>
        defaultSortFieldId="company_name"
        columns={columns}
        data={filteredItems}
        noHeader
        noDataComponent={`Aucune entreprise ${researchByUser ? 'ni membre trouvé' : 'trouvée'}...`}
        highlightOnHover
        className="text-primary"
        expandableRows
        expandOnRowClicked
        expandableRowsComponent={AnnuaireTableContentExpandRow}
        pagination
        paginationComponentOptions={paginationOptions}
      />
    </AnnuaireTableStructure>
  )
}

export default AnnuaireTable
