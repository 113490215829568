/* eslint-disable max-classes-per-file */

export class UserFacingError extends Error {
  constructor(public readonly title: string, public readonly detail: string) {
    super(`UserFacingError[title:${title},message:${detail}]`)
  }
}

// Gestion des erreurs http
export class HttpError extends Error {
  constructor(code: number, httpMessage: string, error?: Error) {
    super(`Http error: ${code}, message: ${httpMessage}`)

    this.code = code
    this.httpMessage = httpMessage
    this.error = error
  }

  readonly code: number

  readonly httpMessage: string

  readonly error?: Error
}

// Gestion des erreurs non référencées
export class UnknownError extends Error {
  constructor(message?: string, error?: Error) {
    super(message)
    this.error = error
  }

  readonly error?: Error
}

export class UserNotAuthenticatedError extends Error {}
export class InvalidCredentialsError extends HttpError {
  constructor() {
    super(401, 'Identifiant ou mot de passe invalide.')
  }
}
