import React, { Component } from 'react';
import UserIdentifier from '../../../component/user/avatar/identifier';
import Group from '../../../component/groups/group/group';

class SearchDetails extends Component {
    shouldComponentUpdate() {
        return this.props.onReload;
    }

    render() {
        return (
            <>
                <div className="mb-2 bg-transparent">
                {
                    this.props.data && this.props.data.companies && this.props.data.companies.length > 0 &&
                    this.props.data.companies.map((element, i) => (
                        <div className="card mb-1" key={element.id}>
                            <div className="card-body">
                                <Group data={element} seeDetails={(index) => this.props.groupDetails(index)}/>
                            </div>
                        </div>
                    ))
                }
                </div>
                {
                    this.props.data && this.props.data.users &&
                    this.props.data.users.length > 0 &&
                    this.props.data.users.map((element, i) => (
                        <div className="card mb-1" key={element.id}>
                            <div className="card-body">
                                <UserIdentifier user={element}/>
                            </div>
                        </div>
                    ))
                }
            </>
        );
    }
}

export default (SearchDetails);
