import {authedApi} from '../services/api'
import {logger} from '../services/logger'
import {baseUrlCommon} from '../services/url'
import {UserFacingError} from '../shared/api/errors'

const addDocument = async (data: FormData) => {
  try {
    return (
      await authedApi.post(`${baseUrlCommon}/document/add`, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
    ).data
  } catch (e) {
    logger.error('what', e)
    if (e.response) {
      logger.error('error creating post.', JSON.stringify(e.response, undefined, 2))
      throw new UserFacingError('Oups !', "Nous n'avons pû publier votre document, veuillez réessayer plus tard.")
    }
    if (e.request) {
      logger.warn('could not connect to server.', e)
      throw new UserFacingError('Oups !', 'Connection au serveur impossible, veuillez réessayer plus tard.')
    } else {
      logger.error('unknown error', e)
      throw new UserFacingError('Oups !', 'Une erreur est arrivée, veuillez réessayer plus tard.')
    }
  }
}

export default {
  addDocument,
}
