import React, { Component } from 'react';
import InputFiles from 'react-input-files';
import { Avatar } from '../../../component/user';
import {fileReaderToBase64} from "../../../services/fs";

class ImageEdit extends Component {
    constructor(props) {
        super();
        this.state = {
            avatar_src: props.avatar ? props.avatar.src : "",
        }
        this.newImg = this.newImg.bind(this);
    }

    newImg(files) {
        fileReaderToBase64(files, (data) => {
            this.props.setDB(data.image, data.image_ext);
            this.setState({avatar_src: data.image});
        });
    }

    componentWillReceiveProps(newProps) {
        this.setState({avatar_src: newProps.avatar ? newProps.avatar.src : ""});
    }

    render() {
        return (
            <div className={this.props.containerClass} style={{...this.props.containerStyle}}>
                <div className={this.props.classes} style={{...this.props.style}}>
                    {
                        this.props.avatar &&
                        <Avatar alt={this.props.avatar.alt} img={this.state.avatar_src} size="md" />
                    }
                  {
                    !this.props.disabled ?
                      (    <InputFiles onChange={files => this.newImg(files)}>
                              <div className="edit-button"></div>
                          </InputFiles>
                    ) : <div />
                  }
                </div>
            </div>
        );
    }
}



export default ImageEdit;
