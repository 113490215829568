import React from 'react';
import { useSelector } from 'react-redux';
import api from '../../../services/api';
import { API_GET_ALL_SECTORS } from '../../../services/constants';
import SectorElement from './sector-element';
import { Hidden } from '@material-ui/core';

export const SectorListContainer = () => {
    const [sectors, setSectors] = React.useState([]);
    const config = useSelector(state => state.apiR);

    React.useEffect(() => {
        api.get(API_GET_ALL_SECTORS, config)
        .then(res => {
            setSectors(res.data);
        })
        .catch(err => {
            console.error('Get all the sectors ERROR: ', err);
        })
    }, [config])

    return (
        <div className="container" style={{maxHeight: 500, overflowY: 'scroll'}}>
            <SectorElement data={{libelle: 'Tout sélectionner', id: undefined}} />
            <hr className="pt-2 m-0" />
            {
                sectors.map(el => <SectorElement data={el} />)
            }
        </div>
    )
}

const SectorList = () => {
    const [toggleMessage, setToggleMessage] = React.useState(false);

    return (
        <div id="sectorsBlock" className="container-fluid position-fixed" style={{bottom: -10, right: 0, left: "auto"}}>
            <div className="row justify-content-end">
                <div className="col-md-4 col-lg-3">
                    <div className="card w-100 shadow-lg bg-white">
                        <header className="card-header message-header rounded message-profile bg-transparent" style={{cursor: 'pointer'}} onClick={() => setToggleMessage(!toggleMessage)}>
                            <div className="text-center pt-1">
                                <p style={{fontSize: 16}}>
                                    <strong>
                                        Secteurs d'activité
                                    </strong>
                                </p>
                            </div>
                            {
                                toggleMessage &&
                                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 129 129" className="nav-icon text-primary position-absolute" style={{top: 20, right: 20}} xlink="http://www.w3.org/1999/xlink" enableBackground="new 0 0 129 129"><g><path fill="currentColor" d="m121.3,34.6c-1.6-1.6-4.2-1.6-5.8,0l-51,51.1-51.1-51.1c-1.6-1.6-4.2-1.6-5.8,0-1.6,1.6-1.6,4.2 0,5.8l53.9,53.9c0.8,0.8 1.8,1.2 2.9,1.2 1,0 2.1-0.4 2.9-1.2l53.9-53.9c1.7-1.6 1.7-4.2 0.1-5.8z"/></g></svg>
                            }
                            {
                                !toggleMessage &&
                                <svg width="64" version="1.1" xmlns="http://www.w3.org/2000/svg" height="64" viewBox="0 0 64 64" className="nav-icon text-primary position-absolute" style={{top: 18, right: 20}} xlink="http://www.w3.org/1999/xlink" enableBackground="new 0 0 64 64"><g><path fill="currentColor" d="M3.352,48.296l28.56-28.328l28.58,28.347c0.397,0.394,0.917,0.59,1.436,0.59c0.52,0,1.04-0.196,1.436-0.59   c0.793-0.787,0.793-2.062,0-2.849l-29.98-29.735c-0.2-0.2-0.494-0.375-0.757-0.475c-0.75-0.282-1.597-0.107-2.166,0.456   L0.479,45.447c-0.793,0.787-0.793,2.062,0,2.849C1.273,49.082,2.558,49.082,3.352,48.296z"/></g></svg>
                            }
                        </header>
                        {
                            toggleMessage &&
                            <SectorListContainer />
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SectorList;
