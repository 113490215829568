const SET_WEBSOCKET = 'websocket/SET_WEBSOCKET';
const UNSET_WEBSOCKET = 'websocket/UNSET_WEBSOCKET';

const initialStateWebsocket = {
    connection: null,
};

export default (state = initialStateWebsocket, action) => {
    switch (action.type) {
        case SET_WEBSOCKET: {
            let {connection} = action.data;
            //console.log("SET_WEBSOCKET call");
            return {
                ...initialStateWebsocket,
                connection,
            };
        }

        case UNSET_WEBSOCKET: {
            //console.log("UNSET_WEBSOCKET call");
            return {
                ...initialStateWebsocket
            };
        }

        default:
            return state;
    }
}
