const isEqualString = (a?: string, b?: string) => {
  if (a && b) return a === b
  if (a) return a.trim().length === 0
  if (b) return b.trim().length === 0
  return true
}

export const isEqualShallow = (obj1: Record<string, unknown>, obj2: Record<string, unknown>, depth = 1): boolean => {
  const hasEqualKeyCount = Object.keys(obj1).length === Object.keys(obj2).length
  return (
    hasEqualKeyCount &&
    (Object.keys(obj1) as (keyof typeof obj1)[]).every((key) => {
      const hasProp = Object.prototype.hasOwnProperty.call(obj2, key)
      if (!hasProp) {
        console.warn('state is missing prop', key)
        return false
      }

      const a = obj1[key]
      const b = obj2[key]

      let isEqualProp = false
      if (a && b && typeof a === typeof b && typeof a === 'object' && depth >= 0) {
        // drill down
        return isEqualShallow(obj1[key] as Record<string, unknown>, obj2[key] as Record<string, unknown>, depth - 1)
      }
      if ((typeof a === 'string' && !b) || (typeof b === 'string' && !a)) {
        isEqualProp = isEqualString(a as string | undefined, b as string | undefined)
      } else {
        isEqualProp = obj1[key] === obj2[key]
      }

      if (!isEqualProp)
        console.info('not equal', {
          key,
          base: obj1[key],
          state: obj2[key],
        })
      return hasProp && isEqualProp
    })
  )
}
