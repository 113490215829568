import type {Conv, Message} from '../api/api.types'
import {unique} from '../utils/arrays'
import type {ApiGroupConversation} from '../api/conversationsApi'

const sortByTimestampDESC = (a: Message, b: Message) => {
  if (a.serverTimestamp && b.serverTimestamp) {
    return b.serverTimestamp - a.serverTimestamp
  }
  if (a.timestamp && b.timestamp) {
    return b.timestamp - a.timestamp
  }
  return 0
}

type MessageId = {
  id?: number
  uuid: string
  serverTimestamp?: number
  timestamp: number
  source: number
}
export const isEqualMessages = (a: MessageId, b: MessageId) => {
  return a.uuid === b.uuid
}

export const mergeApiMessagesIntoLocal = (apiMessages: Message[], localMessages: Message[]) => {
  // const keyPropsAsArray = (ms: Message[]) => ms.map(({id, uuid, serverTimestamp, message}) => ({id, uuid, serverTimestamp, message}))
  // logger.debug('apiMessages', keyPropsAsArray(apiMessages.slice(0, 5)))
  // logger.debug('localMessages', keyPropsAsArray(localMessages.slice(0, 5)))

  const nonExisting = apiMessages.filter((apiMessage) => {
    return !localMessages.some((message) => isEqualMessages(message, apiMessage))
  })
  return unique([...nonExisting, ...localMessages], isEqualMessages)
}
// For each received conversation, translate it to the local format, then sort it
const mergeApiConvsIntoLocal = <LocalConv extends Conv, ApiConv extends ApiGroupConversation>(
  localConvs: Record<number, LocalConv>,
  apiConvs: ApiConv[],
  newEntry: (apiConv: ApiConv) => LocalConv,
  updatedEntry: (apiConv: ApiConv, existingConv: LocalConv) => LocalConv
): Record<number, LocalConv> =>
  apiConvs.reduce((acc, apiConv) => {
    const existingConv = localConvs[apiConv.id]
    // If the conversation doesn't exist yet, do the simple translate
    if (!existingConv) {
      acc[apiConv.id] = newEntry(apiConv)
      return acc
    }

    const updatedConv = updatedEntry(apiConv, existingConv)

    const updatedMessages = mergeApiMessagesIntoLocal(
      apiConv.messages.map((msg) => {
        // eslint-disable-next-line no-param-reassign
        msg.status = msg.status || 'sent'
        return msg
      }),
      existingConv.messages
    )

    if (updatedMessages.length !== existingConv.messages.length) {
      updatedMessages.sort(sortByTimestampDESC)
    }

    updatedConv.messages = updatedMessages

    acc[apiConv.id] = updatedConv
    return acc
  }, {} as Record<number, LocalConv>)

export const translateAndMergeIntoLocal = (currentAccountId: number, local: Record<number, Conv>, remote: ApiGroupConversation[]) => {
  let haveNewMessages = 0

  const convs = mergeApiConvsIntoLocal(
    local,
    remote || [],
    // @ts-ignore
    (apiConv: ApiGroupConversation) => {
      apiConv.messages.forEach((msg) => {
        // eslint-disable-next-line no-param-reassign
        msg.status = msg.status || 'sent'
      })
      let otherAccountId
      // Extract Account from GroupMessageAccount objects
      const accounts = apiConv.accounts
        .filter(({account}) => {
          if (!account.user) {
            return false
          }
          if (!apiConv.isGroup && account.id !== currentAccountId) otherAccountId = account.id
          return true
        })
        .map(({account}) => account)

      return {
        ...apiConv,
        accounts,
        otherAccountId,
      }
    },
    (apiConv: ApiGroupConversation, existingConv: Conv) => {
      if (currentAccountId !== apiConv.lastMessageAccountId && !apiConv.lastMessageRead) {
        haveNewMessages++
      }

      let otherAccountId
      // Extract Account from GroupMessageAccount objects
      const accounts = apiConv.accounts
        .filter(({account}) => {
          if (!account.user) {
            return false
          }
          if (!apiConv.isGroup && account.id !== currentAccountId) otherAccountId = account.id
          return true
        })
        .map(({account}) => account)

      return {
        ...existingConv,
        name: apiConv.name,
        lastMessageAccountId: apiConv.lastMessageAccountId,
        lastMessageDate: apiConv.lastMessageDate,
        lastMessageRead: apiConv.lastMessageRead,
        isGroup: apiConv.isGroup,
        picture: apiConv.picture,
        accounts,
        otherAccountId,
      }
    }
  )

  return {
    convs,
    haveNewMessages,
  }
}
