import React, { useEffect, useRef, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import api from '../../services/api';
import { API_GET_EVENTS, BASE_URL } from '../../services/constants';
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import esLocale from '@fullcalendar/core/locales/fr'
import '@fullcalendar/core/main.min.css'
import '@fullcalendar/daygrid/main.min.css'
import '@fullcalendar/timegrid/main.min.css'
import './calendar.css'
import moment from 'moment'
import { Button, Image, Modal } from 'react-bootstrap'
import _ from 'lodash'
import { getToken } from "../../actions/apiAction";
import { companyLoad } from "../../actions/companyAction";
import { userActions } from "../../reducers/userReducer";
import { messagesRActions } from "../../reducers/messagesReducer";
import { clubSliceActions } from "../../reducers/clubSlice";
import { LinkItUrl} from "react-linkify-it";
import style from "../../component/style.css"


let events = []
const CalendarComponents = (props) => {
    // console.log("props", props);
    const config = useSelector(state => state.apiR);
    const dispatch = useDispatch();
    const [show, setShow] = useState(false)
    const [showSuccess, setShowSuccess] = useState(false)
    const [calendarEvents, setCalendarEvents] = useState([])
    const [eventsInformations, setEventsInformations] = useState(null)
    const [involde, setInvolde] = useState(null)
    const [currentDate, setCurrentDate] = useState(null);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [eventToShow, setEventToShow] = useState(new URLSearchParams(props.queryString).get("event_id"));

    const participation = (involve) => {
        api.get(API_GET_EVENTS + selectedEvent + '/involve/' + involve, config)
            .then(res => {
                //console.log("la res", res);
                setShowSuccess(true)
            })
            .catch(err => {
                // ! Code An-200
                console.error('Get all the companies of the club ERROR: Code An-200', err);
            })
    }
    const getOtherEvents = (dateCalendar) => {
        api.get(API_GET_EVENTS + dateCalendar, config)
            .then(res => {
                //console.log("la res", res);
                const tab = events
                _.transform(res.data, (resultYear, numYear, keyYear) => {
                    _.transform(numYear, (resultMonth, numMonth, keyMonth) => {
                        numMonth.events.map((itemEvents) => {
                            const {beginDate, endDate} = itemEvents;
                            const stringDate =
                                keyYear + '-' + keyMonth + '-' + moment(beginDate).format('DD');
                            const dateEvent = moment(stringDate).format('YYYY-MM-DD');

                            let subTitle;
                            if (beginDate === endDate) {
                                subTitle = 'Le ' + moment(beginDate).format('DD/MM/YYYY');
                            } else {
                                subTitle =
                                    'De ' +
                                    moment(beginDate).format('HH:mm') +
                                    ' à ' +
                                    moment(endDate).format('HH:mm');
                            }
                            const eventsData = {
                                ...itemEvents,
                                subTitle: subTitle,
                                date: dateEvent,
                            };

                            tab.push(eventsData)
                        });
                    });
                });
                //console.log('tab', tab)
                setCalendarEvents(tab)
                events = tab
            })
            .catch(err => {
                // ! Code An-200
                console.error('Get all the companies of the club ERROR: Code An-200', err);
            })
    }
    const ref = useRef(null);

    useEffect(() => {
        let formattedDate = "";
        if (currentDate !== null) {
            formattedDate = currentDate;
        } else {
            formattedDate = moment().format('YYYYMM');
            setCurrentDate(formattedDate);
        }
        api.get(API_GET_EVENTS + formattedDate + '?first=1', config)
            .then(res => {
                //console.log("la res", res);
                const tab = []
                _.transform(res.data, (resultYear, numYear, keyYear) => {
                    _.transform(numYear, (resultMonth, numMonth, keyMonth) => {
                        numMonth.events.map((itemEvents) => {
                            const {beginDate, endDate} = itemEvents;
                            const stringDate =
                                keyYear + '-' + keyMonth + '-' + moment(beginDate).format('DD');
                            const dateEvent = moment(stringDate).format('YYYY-MM-DD');

                            let subTitle;
                            if (beginDate === endDate) {
                                subTitle = 'Le ' + moment(beginDate).format('DD/MM/YYYY');
                            } else {
                                subTitle =
                                    'De ' +
                                    moment(beginDate).format('HH:mm') +
                                    ' à ' +
                                    moment(endDate).format('HH:mm');
                            }
                            const eventsData = {
                                ...itemEvents,
                                subTitle: subTitle,
                                date: dateEvent,
                            };

                            tab.push(eventsData)
                        });
                    });
                });
                setCalendarEvents(tab)
                events = tab

                let i = 3;
                for (i; i <= 12; i++) {
                    //console.log('iiiii', i)
                    getOtherEvents(moment(new Date()).subtract(i, 'months').format('YYYYMM'))
                    getOtherEvents( moment(new Date()).add(i, 'months').endOf('month').format('YYYYMM'))
                }
            })
            .catch(err => {
                // ! Code An-200
                console.error('Get all the companies of the club ERROR: Code An-200', err);
            })
    }, [config, dispatch]);

    const getOneEvents = id => {
        api.get(API_GET_EVENTS + 'get/' + id, config)
            .then(res => {
                //console.log("la res", res);
                if (res.data.participation === null) {
                    setInvolde(null)
                } else {
                    setInvolde(res.data.participation.involvement)
                }
            })
            .catch(err => {
                // ! Code An-200
                console.error('Get all the companies of the club ERROR: Code An-200', err);
            })
    }

    const convertHexToRGBA = (hexCode, opacity) => {
        //console.log('hexCode', hexCode)
        if (hexCode !== undefined) {
            let hex = hexCode.replace('#', '');

            if (hex.length === 3) {
                hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`;
            }

            const r = parseInt(hex.substring(0, 2), 16);
            const g = parseInt(hex.substring(2, 4), 16);
            const b = parseInt(hex.substring(4, 6), 16);

            return `rgba(${r},${g},${b},${opacity})`;
        }
    };

    //console.log('props.clubState', props.clubState)
    return (
        <>
            <div className="d-flex flex-column flex-sm-row justify-content-between align-items-center">
                <h3 className="text-light m-0">Agenda</h3>
            </div>
            <div className="card mt-3">
                <div className="card-body rounded">
                    <div className="container-main">
                        <FullCalendar
                            ref={ref}
                            locale={esLocale}
                            plugins={[ dayGridPlugin ]}
                            initialView="dayGridMonth"
                            events={calendarEvents}
                            scrollTime={'00:00'}
                            defaultDate={new Date()}
                            eventClick={(info) => {
                                // console.log("event info", info);
                                setSelectedEvent(info.event.id);
                                getOneEvents(info.event._instance.instanceId);
                                setEventsInformations({
                                    ...info.event._def,
                                    ...info.event._instance
                                })
                                setShow(true)
                            }}
                            validRange={{
                                start: moment(new Date()).subtract(12, 'months').format('YYYY-MM-01'),
                                end: moment(new Date()).add(12, 'months').endOf('month').format('YYYY-MM-DD')
                            }}
                            eventColor={props.clubState.club_color}
                            eventTextColor={'white'}
                        />
                    </div>
                </div>
            </div>
            <Modal show={show} onHide={() => setShow(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>{eventsInformations?.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {eventsInformations?.extendedProps.picture !== null &&
                        <Image
                            style={{width: '100%'}}
                            src={`${BASE_URL}/uploads/events/` + eventsInformations?.extendedProps.picture}
                        />
                    }
                    {eventsInformations?.extendedProps.subTitle}
                    <br/>
                    <br/>
                  <div>
                    <LinkItUrl className="blue">
                      {eventsInformations?.extendedProps.content}
                    </LinkItUrl>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        style={{
                            backgroundColor: involde === 0 ? 'green' : 'grey',
                            color: 'white'
                        }}
                        disabled={moment().diff(moment(eventsInformations?.extendedProps.beginDate)) > 0}
                        variant={"light"}
                        onClick={() => {
                            participation(0)
                            setShow(false)
                        }}
                    >
                        Participe pas
                    </Button>
                    <Button
                        style={{
                            backgroundColor: involde === 2 ? 'green' : convertHexToRGBA(props.clubState.club_color, 0.6),
                            color: 'white'
                        }}                        disabled={moment().diff(moment(eventsInformations?.extendedProps.beginDate)) > 0}
                        variant={"light"}
                        onClick={() => {
                            participation(2)
                            setShow(false)
                        }}                    >
                        Peut-être
                    </Button>
                    <Button
                        style={{
                            backgroundColor: involde === 1 ? 'green' : props.clubState.club_color,
                            color: 'white'
                        }}                        disabled={moment().diff(moment(eventsInformations?.extendedProps.beginDate)) > 0}
                        variant={"light"}
                        onClick={() => {
                            participation(1)
                            setShow(false)
                        }}                    >
                        Participe
                    </Button>
                    <Button variant="dark" onClick={() => setShow(false)}>
                        Fermer
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showSuccess} onHide={() => setShowSuccess(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Bravo !</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>Votre choix a été pris en compte !</div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => setShowSuccess(false)}>
                        Fermer
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

const mapStateToProps = state => ({
    user: state.userR,
    clubState: state.clubState,
    company: state.companyR,
    config: state.apiR,
    socket: state.conversationsR.socket,
})

const mapDispatchToProps = dispatch => ({
    loadToken: (payload) => dispatch(getToken(payload)),
    userLoad: (payload) => dispatch(userActions.userLoad(payload)),
    companyLoad: (payload) => dispatch(companyLoad(payload)),
    connectedUsers: (payload) => dispatch(messagesRActions.connectedUsersUpdate(payload)),
    clubLoad: (payload) => dispatch(clubSliceActions.clubLoad(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(CalendarComponents);
