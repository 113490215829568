import React from 'react';
import { Avatar } from '../../../component/user';

const GroupDataSkeleton = () => {
    return (
        <div className="card mb-2">
            <div className="img-timeline" style={{backgroundColor: '#a3a3a3'}}></div>
            <div className="d-flex align-items-center justify-content-center text-center flex-column w-100 mt-n4">
                <Avatar size="md"/>
                <div className="mt-1 mx-auto" style={{backgroundColor: '#00000030', width: 60, height: 15}}></div>
                <div className="mt-1 mx-auto" style={{backgroundColor: '#00000030', width: 80, height: 15}}></div>
                <div className="mt-1 mx-auto" style={{backgroundColor: '#00000030', width: 120, height: 15}}></div>
                <div className="mt-1 mx-auto" style={{backgroundColor: '#00000030', width: 25, height: 15}}></div>
            </div>
            <div className="card-body">
                <div className="mt-1 mx-auto" style={{backgroundColor: '#00000030', width: 100, height: 15}}></div>
                <div className="mt-1 mx-auto" style={{backgroundColor: '#00000030', width: 80, height: 15}}></div>
            </div>
        </div>
    );
};

export default GroupDataSkeleton;
