export const companyData = (payload) => {
    return {
        type: 'companyData',
        payload
    };
}

export const companyBackImg = (payload) => {
    return {
        type: 'backImg',
        payload
    };
}

export const companyImg = (payload) => {
    return {
        type: 'companyImg',
        payload
    };
}

export const companyName = (payload) => {
    return {
        type: 'companyName',
        payload
    };
}

export const companyLoad = (payload) => ({
    type: 'companyLoad',
    payload
})