import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import api from '../../../services/api';
import { API_GET_COMPANIES_OF_SECTOR, API_GET_ALL_COMPANIES } from '../../../services/constants';
import { loadCompaniesAnnuaire, setActiveSelectorAnnuaire } from '../../../actions/annuaireActions';
import {logger} from "../../../services/logger";

const SectorElement = ({ data }) => {
    const config = useSelector(state => state.apiR);
    const activeSector = useSelector(state => state.annuaireR.active_selector);
    const dispatch = useDispatch();
    const textColor = 'text-' + (activeSector.id === undefined ? 'primary' : (activeSector.libelle === data.libelle ? 'primary' : 'muted'));

    const onSelectSector = () => {
        if (data.id === undefined) {
            dispatch(setActiveSelectorAnnuaire(data));
            api.get(API_GET_ALL_COMPANIES, config)
            .then(res => {
                dispatch(loadCompaniesAnnuaire(res.data));
            })
            .catch(err => {
                console.error('Get all the companies of the club ERROR: ', err);
            })

            return;
        }

        if (!data.id) {
            // ! Code CAn-100
            logger.debug('DEBUG -- Une erreur est survenue... Code CAn-100');

            return;
        }

        api.get(API_GET_COMPANIES_OF_SECTOR(data.id), config)
        .then(res => {
            dispatch(loadCompaniesAnnuaire(res.data));
            dispatch(setActiveSelectorAnnuaire(data));
        })
        .catch(err => {
            console.error('Getting companies of ' + data.libelle + ' ERROR: ', err);
        })
    }

    if (!activeSector || !data) {
        // ! Code CE-001
        logger.debug('DEBUG -- Une erreur est survenue... Code CE-001');

        return (
            <div className="d-flex align-items-center row py-1">
                <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" className={"col-1 p-1"} style={{color: '#00000030'}} version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 512 512" space="preserve"><g><g><path fill="currentColor" d="M256,0C114.837,0,0,114.837,0,256s114.837,256,256,256s256-114.837,256-256S397.163,0,256,0z"></path></g></g></svg>
                <div className="col p-0 pr-1" style={{color: '#00000030', width: 60, height: 15}}></div>
            </div>
        );
    }

    return (
        <div className="d-flex align-items-center row py-1" style={{cursor: 'pointer'}} onClick={onSelectSector}>
            {
                activeSector.libelle === data.libelle || activeSector.id === undefined ?
                <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" className={"col-1 p-1 " + textColor} version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 512 512" space="preserve"><g><g><path fill="currentColor" d="M256,0C114.837,0,0,114.837,0,256s114.837,256,256,256s256-114.837,256-256S397.163,0,256,0z"></path></g></g></svg>
                :
                <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" className={"col-1 p-1 " + textColor} version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 438.533 438.533" space="preserve"><g><path fill="currentColor" d="M409.133,109.203c-19.608-33.592-46.205-60.189-79.798-79.796C295.736,9.801,259.058,0,219.273,0   c-39.781,0-76.47,9.801-110.063,29.407c-33.595,19.604-60.192,46.201-79.8,79.796C9.801,142.8,0,179.489,0,219.267   c0,39.78,9.804,76.463,29.407,110.062c19.607,33.592,46.204,60.189,79.799,79.798c33.597,19.605,70.283,29.407,110.063,29.407   s76.47-9.802,110.065-29.407c33.593-19.602,60.189-46.206,79.795-79.798c19.603-33.596,29.403-70.284,29.403-110.062   C438.533,179.485,428.732,142.795,409.133,109.203z M353.742,297.208c-13.894,23.791-32.736,42.633-56.527,56.534   c-23.791,13.894-49.771,20.834-77.945,20.834c-28.167,0-54.149-6.94-77.943-20.834c-23.791-13.901-42.633-32.743-56.527-56.534   c-13.897-23.791-20.843-49.772-20.843-77.941c0-28.171,6.949-54.152,20.843-77.943c13.891-23.791,32.738-42.637,56.527-56.53   c23.791-13.895,49.772-20.84,77.943-20.84c28.173,0,54.154,6.945,77.945,20.84c23.791,13.894,42.634,32.739,56.527,56.53   c13.895,23.791,20.838,49.772,20.838,77.943C374.58,247.436,367.637,273.417,353.742,297.208z"></path></g></svg>
            }
            <span className={"col p-0 pr-1 " + textColor}>{data.libelle}</span>
        </div>
    );
};

export default SectorElement;
