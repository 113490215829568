import React, {useMemo, useState} from 'react'
import {logger} from '../../../services/logger'

const Text = () => <div className="card-img-top" style={{height: 10, backgroundColor: 'white'}} />
const Image = ({onImageError, attachment}: {onImageError: () => void; attachment?: string}) => (
  <img className="card-img-top img-fluid" onError={() => onImageError} src={attachment} alt="Card cap" />
)
const Video = ({attachment}: {attachment?: string}) => {
  if (attachment) {
    return (
      <div className="embed-responsive em embed-responsive-21by9 rounded-top">
        {/* @ts-ignore */}
        <iframe className="embed-responsive-item" title={attachment} src={attachment} origin={window.location.hostname} allowFullScreen />
      </div>
    )
  }
  // ! Code A-001
  logger.debug('DEBUG -- Une erreur est survenue... Code A-001')

  return <div className="card-img-top" style={{height: 10, backgroundColor: 'white'}} />
}

type CardHeadersProps = {
  post_type: 'text' | 'image' | 'video'
  attachment?: string
}

const CardHeaders = ({attachment, post_type}: CardHeadersProps) => {
  const [postType, setPostType] = useState(post_type)

  return useMemo(() => {
    switch (postType) {
      case 'image':
        return (
          <Image
            onImageError={() => {
              // ! Code I-001
              logger.debug('DEBUG - Une erreur est survenue... Code I-001')
              setPostType('text')
            }}
            attachment={attachment}
          />
        )
      case 'video':
        return <Video attachment={attachment} />
      case 'text':
      default:
        return <Text />
    }
  }, [postType, attachment])
}

export default CardHeaders
