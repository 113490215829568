import React from "react";

export default (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xlink="http://www.w3.org/1999/xlink"
        style={props.style}
        className={props.className}
        version="1.1"
        x="0px"
        y="0px"
        viewBox="0 0 34 37">
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="calendar-interface-symbol-tool" fill="currentColor" fillRule="nonzero">
          <path
              d="M29.224,3.948 L28.126,3.948 L28.126,2.75 C28.126,1.233 26.929,0 25.456,0 C23.982,0 22.786,1.233 22.786,2.75 L22.786,3.947 L20.046,3.947 L20.046,2.75 C20.046,1.233 18.849,0 17.376,0 C15.903,0 14.706,1.233 14.706,2.75 L14.706,3.947 L11.966,3.947 L11.966,2.75 C11.966,1.233 10.769,0 9.296,0 C7.823,0 6.626,1.233 6.626,2.75 L6.626,3.947 L5.224,3.947 C2.881,3.947 0.974,5.854 0.974,8.197 L0.974,32.197 C0.974,34.54 2.881,36.447 5.224,36.447 L29.224,36.447 C31.568,36.447 33.474,34.54 33.474,32.197 L33.474,8.197 C33.474,5.855 31.567,3.948 29.224,3.948 Z M24.286,2.75 C24.286,2.061 24.811,1.5 25.456,1.5 C26.102,1.5 26.626,2.061 26.626,2.75 L26.626,7.646 C26.626,8.335 26.102,8.896 25.456,8.896 C24.811,8.896 24.286,8.335 24.286,7.646 L24.286,2.75 Z M16.206,2.75 C16.206,2.061 16.731,1.5 17.376,1.5 C18.021,1.5 18.546,2.061 18.546,2.75 L18.546,7.646 C18.546,8.335 18.021,8.896 17.376,8.896 C16.731,8.896 16.206,8.335 16.206,7.646 L16.206,2.75 L16.206,2.75 Z M8.125,2.75 C8.125,2.061 8.65,1.5 9.295,1.5 C9.94,1.5 10.465,2.061 10.465,2.75 L10.465,7.646 C10.465,8.335 9.94,8.896 9.295,8.896 C8.65,8.896 8.125,8.335 8.125,7.646 L8.125,2.75 Z M30.974,32.198 C30.974,33.163 30.189,33.948 29.224,33.948 L5.224,33.948 C4.259,33.948 3.474,33.163 3.474,32.198 L3.474,10.198 L30.974,10.198 L30.974,32.198 Z"
              id="Shape"></path>
          <rect id="Rectangle" x="5.724" y="14.626" width="4.595" height="4.089"></rect>
          <rect id="Rectangle" x="11.857" y="14.626" width="4.596" height="4.089"></rect>
          <rect id="Rectangle" x="17.995" y="14.626" width="4.595" height="4.089"></rect>
          <rect id="Rectangle" x="24.128" y="14.626" width="4.596" height="4.089"></rect>
          <rect id="Rectangle" x="5.724" y="20.084" width="4.595" height="4.086"></rect>
          <rect id="Rectangle" x="11.857" y="20.084" width="4.596" height="4.086"></rect>
          <rect id="Rectangle" x="17.995" y="20.084" width="4.595" height="4.086"></rect>
          <rect id="Rectangle" x="24.128" y="20.084" width="4.596" height="4.086"></rect>
          <rect id="Rectangle" x="5.724" y="25.54" width="4.595" height="4.086"></rect>
          <rect id="Rectangle" x="11.857" y="25.54" width="4.596" height="4.086"></rect>
          <rect id="Rectangle" x="17.995" y="25.54" width="4.595" height="4.086"></rect>
          <rect id="Rectangle" x="24.128" y="25.54" width="4.596" height="4.086"></rect>
        </g>
      </g>
    </svg>
)
