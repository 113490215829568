import React, {useCallback, useState} from 'react'
import {Mention, MentionsInput, SuggestionDataItem} from 'react-mentions'
import {useAppSelector} from '../../../reducers/hooks'
import api from '../../../services/api'
import {API_USER_SEARCH} from '../../../services/constants'
import {logger} from '../../../services/logger'

type User = {
  firstName: string
  lastName: string
  id: number
}
type MessagePostProps = {
  returnValue: (v: string) => void
  defaultValue: string
}
const MessagePost = ({returnValue, defaultValue}: MessagePostProps) => {
  const config = useAppSelector((state) => state.apiR)

  const [, setUsers] = useState<User[]>([])
  const [pagination] = useState(1)

  const getUser = useCallback(
    async (query: string, callback: (data: SuggestionDataItem[]) => void) => {
      if (!query) return

      try {
        const res: {data: {users: User[]}} = await api.post(API_USER_SEARCH(pagination), {filter: query}, config)
        const {
          data: {users: foundUsers},
        } = res

        await setUsers((prev) => [...prev, ...foundUsers])

        callback(foundUsers.map((user) => ({display: `${user.firstName} ${user.lastName}`, id: user.id})))
      } catch (err) {
        logger.debug('API_USER_MENTION ERROR: ', err)
      }
    },
    [pagination]
  )

  return (
    <div className="form-group w-100">
      <label htmlFor="message">Message à partager :</label>
      <MentionsInput
        id="input"
        value={defaultValue}
        onChange={(e) => returnValue(e.target.value)}
        placeholder="Contenu du message"
        autoFocus
        style={{
          control: {
            backgroundColor: '#fff',
            fontSize: 14,
            fontWeight: 'normal',
            border: 'none',
            borderRadius: 10,
          },
          highlighter: {
            overflow: 'hidden',
          },
          input: {
            margin: 0,
          },
          '&multiLine': {
            control: {
              fontFamily:
                '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
              border: '1px solid silver',
            },
            highlighter: {
              padding: 9,
            },
            input: {
              padding: 9,
              minHeight: 63,
              outline: 0,
              border: 0,
              height: 150,
            },
          },
          suggestions: {
            list: {
              backgroundColor: 'white',
              border: '1px solid rgba(0,0,0,0.15)',
              fontSize: 14,
            },
            item: {
              padding: '5px 15px',
              borderBottom: '1px solid rgba(0,0,0,0.15)',
              '&focused': {
                backgroundColor: '#c5c6c7',
              },
            },
          },
        }}>
        <Mention
          trigger="@"
          data={(query: string, callback: (data: SuggestionDataItem[]) => void) => {
            getUser(query, callback)
          }}
          style={{
            backgroundColor: '#cee4e5',
          }}
        />
      </MentionsInput>
    </div>
  )
}

export default MessagePost
