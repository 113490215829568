import React from 'react'
import {withRouter} from 'react-router-dom'
import MobileFooter from '../../component/footer/MobileFooter'
import {NavBar} from '../../component/header'
import PageContent from './page_content/page_content'

const Home = () => {
  return (
    <div className="bg-welcome w-100 h-100" style={{position: 'absolute'}}>
      <NavBar />
      <div className="page">
        <div className="container-fluid px-md-4">
          <PageContent />
        </div>
      </div>
      <MobileFooter />
    </div>
  )
}

export default withRouter(Home)
