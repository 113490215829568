import React, {useState} from 'react'
import {postDetails} from '../../../actions/postsAction'
import {SearchButton} from '../../../component/form'
import {Groups} from '../../../component/groups'
import {Posts} from '../../../component/posts'
import PostModal from '../../../component/posts/create-post-modal/post-modal'
import {Post} from '../../../component/posts/post'
import {PersonalDescription} from '../../../component/user'
import {useAppDispatch, useAppSelector} from '../../../reducers/hooks'

const PageContent = () => {
  const dispatch = useAppDispatch()
  // @ts-ignore
  const post_details = useAppSelector((state) => state.postsR.post_details)

  const [newPostModalVisible, setNewPostModalVisible] = useState(false)

  return (
    <div id="page-content" className="row">
      <div id="side-message" className="d-none d-md-block col-md-4 d-lg-block col-lg-3 w-100">
        <PersonalDescription />
      </div>
      <div id="timeline" className="col-12 col-md-8 col-lg-6 w-100 mt-3">
        <form className="form-inline position-relative mb-2 w-100">
          <div className="input-group w-100">
            <div className="col-12 mr-auto w-100 p-0">
              <button
                className="btn btn-primary btn-primary-darker text-left w-100"
                type="button"
                onClick={() => {
                  console.log('new post !')
                  setNewPostModalVisible(true)
                }}
                id="button-addon1">
                <svg
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="far"
                  data-icon="edit"
                  className="icon mx-1 position-relative"
                  style={{bottom: 1.5}}
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 576 512">
                  <path
                    fill="white"
                    d="M402.3 344.9l32-32c5-5 13.7-1.5 13.7 5.7V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V112c0-26.5 21.5-48 48-48h273.5c7.1 0 10.7 8.6 5.7 13.7l-32 32c-1.5 1.5-3.5 2.3-5.7 2.3H48v352h352V350.5c0-2.1.8-4.1 2.3-5.6zm156.6-201.8L296.3 405.7l-90.4 10c-26.2 2.9-48.5-19.2-45.6-45.6l10-90.4L432.9 17.1c22.9-22.9 59.9-22.9 82.7 0l43.2 43.2c22.9 22.9 22.9 60 .1 82.8zM460.1 174L402 115.9 216.2 301.8l-7.3 65.3 65.3-7.3L460.1 174zm64.8-79.7l-43.2-43.2c-4.1-4.1-10.8-4.1-14.8 0L436 82l58.1 58.1 30.9-30.9c4-4.2 4-10.8-.1-14.9z"
                  />
                </svg>
                <span> Publier un message</span>
              </button>
            </div>
          </div>
        </form>
        {/* <LoadingScreen */}
        {/*    loading={this.state.loading} */}
        {/*    bgColor="#f8f9fa" */}
        {/*    spinnerColor={this.props.club.club_color} */}
        {/*    logoSrc={this.props.club.club_image} */}
        {/* > */}
        {!post_details ? (
          <Posts />
        ) : (
          <div>
            <div className="d-flex justify-content-end">
              <button type="button" className="btn btn-primary-darker text-white" onClick={() => dispatch(postDetails(null))}>
                Retour
              </button>
            </div>
            <Post post={post_details} showComment />
          </div>
        )}
        {/* </LoadingScreen> */}
      </div>
      <div id="side-search" className="d-none d-lg-block col-md-4 d-lg-block col-lg-3 w-100 my-3">
        <div className="container-fluid position-fixed">
          <div className="row">
            <div className="col-md-4 col-lg-3">
              <div className="w-100 mr-4" style={{position: 'sticky'}}>
                <SearchButton />
                <Groups />
              </div>
            </div>
          </div>
        </div>
      </div>
      <PostModal
        isVisible={newPostModalVisible}
        onDismiss={() => {
          setNewPostModalVisible(false)
        }}
      />
    </div>
  )
}

export default PageContent
