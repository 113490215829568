import {Conv, ConvAccount} from '../api/api.types'
import {baseUrl, pictureURL, urlImageUser} from '../../services/url'

export function extractConversationMetadata(myId: number, conv: Conv, accounts: Record<number, ConvAccount>) {
  let isNewMessage = false
  let isNewConversation = false
  if (conv.lastMessageDate === null) {
    isNewConversation = true
  }
  if (!conv.lastMessageRead) {
    if (conv.lastMessageAccountId !== myId) {
      isNewMessage = true
    }
  }

  if (conv.isGroup) {
    return {
      name: conv.name,
      avatar: conv.picture ? pictureURL + conv.picture : `${baseUrl}/assets/img/people-group.png`,
      haveSeeConv: !isNewMessage,
      isNewConversation,
    }
  }

  const otherAccount = conv.otherAccountId ? accounts[conv.otherAccountId] : undefined
  if (otherAccount && otherAccount.user) {
    return {
      name: `${otherAccount.user.firstName} ${otherAccount.user.lastName}`,
      avatar: otherAccount.user.avatar ? urlImageUser + otherAccount.user.avatar : undefined,
      userId: otherAccount.user.id,
      haveSeeConv: !isNewMessage,
      isNewConversation,
    }
  }

  return {
    name: 'Utilisateur Supprimé',
    haveSeeConv: !isNewMessage,
    isNewConversation,
  }
}
