import React, {memo, useState} from 'react'
import styles from './Avatar.module.css'

type AvatarProps = {
  img?: string
  alt?: string
  size?: 'xs' | 'sm' | 'md' | 'lg'
  color?: string
  initials?: string
  isConnected?: boolean
  className?: string
}
const Avatar = ({alt, size = 'md', color, img, initials, isConnected, className}: AvatarProps) => {
  const [hasImage, setHasImage] = useState(img !== undefined)

  const showConnexionStatus = isConnected !== undefined
  return (
    <div className={`avatar avatar-${size}${color ? ` bg-${color}` : ''} ${className} `} style={{backgroundColor: color ? '' : '#E7EBEF', right: 0}}>
      {hasImage && <img src={img} onError={() => setHasImage(false)} className="avatar-img img-fluid rounded-circle bg-transparent" alt={alt} />}
      {!hasImage &&
        (alt ? (
          <div className="avatar-img d-flex align-items-center justify-content-center">
            {size !== 'xs' ? (
              <h3 className="text-black flex-row mt-1">{initials || alt || ''}</h3>
            ) : (
              <h6 className="text-black flex-row mt-1">{initials || alt || ''}</h6>
            )}
          </div>
        ) : (
          <img className="avatar-img img-fluid rounded-circle bg-transparent"  src="/assets/jpg/profile-placeholder.png" alt="Profil" />
        ))}
      {showConnexionStatus && <div className={`position-absolute ${styles[size]} ${isConnected ? styles['status-online'] : styles['status-offline']}`} />}
    </div>
  )
}

export default memo(Avatar, (p, n) => {
  return p.alt === n.alt && p.img === n.img && p.isConnected === n.isConnected && p.size === n.size
})
