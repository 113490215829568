import {fetchRequestCommon} from './fetch'
import {paramsToQuery} from './utils'
import {HttpError, UserFacingError} from './errors'
import {authedApi} from '../../services/api'
import {baseUrlCommon} from '../../services/url'
import {logger} from '../../services/logger'

export type Folder = {id: number; title: string; date: string | null}
export type PicturesApiResItem = {gallery: Folder; filename: string; title: string; id: number; date: string | null}
export type DocumentResItem = {gallery: Folder; filename: string; title: string; description?: string; filesize: number; id: number; created_at: string | null}
export type PicturesApiRes = PicturesApiResItem[]
export type DocumentApiRes = DocumentResItem[]

export enum GalleryType {
  PICTURE = 'picture',
  DOCUMENT = 'document',
}

export type GalleryApiRes<T> = {
  type: GalleryType
  items: T[]
  count: number
  status: number
  statusText?: string
}

const getPictures = async (tokenUser: string, clubId: number): Promise<PicturesApiRes> => {
  const queryParams = {
    club_id: clubId,
    type: GalleryType.PICTURE,
  }
  const res: Error | GalleryApiRes<PicturesApiResItem> = await fetchRequestCommon(`/gallery?${paramsToQuery(queryParams)}`, 'GET', tokenUser)
  if (res instanceof Error) throw res
  if (res.status && (res.status !== 200 || !res.items)) {
    throw new HttpError(res.status, res.statusText || 'unknown error')
  }
  return res.items || []
}

const getDocuments = async (tokenUser: string, clubId: number): Promise<DocumentApiRes> => {
  const queryParams = {
    club_id: clubId,
    type: GalleryType.DOCUMENT,
  }

  const res: Error | GalleryApiRes<DocumentResItem> = await fetchRequestCommon(`/gallery?${paramsToQuery(queryParams)}`, 'GET', tokenUser)
  if (res instanceof Error) throw res
  if (res.status && (res.status !== 200 || !res.items)) {
    throw new HttpError(res.status, res.statusText || 'unknown error')
  }
  return res.items || []
}

const getGalleries = async (id: number) => {
  try {
    return await authedApi.get(`${baseUrlCommon}/galleries/${id}`)
  } catch (e) {
    logger.error('what', e)
    if (e.response) {
      logger.error('error creating post.', JSON.stringify(e.response, undefined, 2))
      throw new UserFacingError('Oups !', "Nous n'avons pû récupérer les galleries, veuillez réessayer plus tard.")
    }
    if (e.request) {
      logger.warn('could not connect to server.', e)
      throw new UserFacingError('Oups !', 'Connection au serveur impossible, veuillez réessayer plus tard.')
    } else {
      logger.error('unknown error', e)
      throw new UserFacingError('Oups !', 'Une erreur est arrivée, veuillez réessayer plus tard.')
    }
  }
}

export default {
  getPictures,
  getDocuments,
  getGalleries,
}
