import {ThunkDispatch} from 'redux-thunk'
import type {AnyAction} from '@reduxjs/toolkit'
import type {RootState} from './store'
import {AccountAuth} from '../shared/api/loginApi'
import {authedApi} from '../services/api'
import {connexionStatusActions, selectConnectedState, selectConnexionStatus} from '../shared/redux/connexionStatus.slice'

// Backfill missing account in state
export const ensureAccount = () => async (dispatch: ThunkDispatch<RootState, unknown, AnyAction>, getState: () => RootState) => {
  const {club} = selectConnectedState(getState())
  const {account} = selectConnexionStatus(getState())
  if (account) return account

  // Handle backwards compat for already logged in users
  const res: {data: AccountAuth[]} = await authedApi.get(`apicommon/accounts?deviceType=web`)
  const auth = res.data.find((a) => a.club.id === club.id)
  if (!auth) {
    throw new Error('No auth found')
  }
  localStorage.setItem('Account', JSON.stringify(auth.account))

  const connexionState = selectConnexionStatus(getState())
  dispatch(connexionStatusActions.onConnexion({...connexionState, account: auth.account}))
  return selectConnectedState(getState()).account
}
