export const baseUrl = process.env.REACT_APP_BASE_URL
export const socketUrl = process.env.REACT_APP_WS_URL

let host = window.location.hostname
if (host === 'localhost') {
  host = process.env.REACT_APP_HOSTNAME || 'localhost'
}

export const hostname = host

export const baseUrlPublic = `${baseUrl}/publicapi`
export const baseUrlNotAuth = `${baseUrl}/userapi`
export const baseUrlAuth = `${baseUrl}/apimobile`
export const baseUrlCommon = `${baseUrl}/apicommon`

export const pictureURL = `${baseUrl}/uploads/pictures/`

export const urlImageUser = `${baseUrl}/uploads/user/`
